import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import BaseResponse from "../../models/common/BaseResponse";
import PagedListModel from "../../models/common/PagedListModel";
import RelatedProfilesSearchInputModel from "../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesSearchInputModel";
import RelatedProfilesGridModel from "../../models/company/relatedAndShortlistedProfiles/response/RelatedProfilesGridModel";
import RelatedProfilesRequestModel from "../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesRequestModel";
import AppliedProfilesRequestModel from "../../models/company/relatedAndShortlistedProfiles/request/AppliedProfilesRequestModel";
import ShortlistedProfilesGridModel from "../../models/company/relatedAndShortlistedProfiles/response/ShortlistedProfilesGridModel";
import AppliedProfilesGridModel from "../../models/company/relatedAndShortlistedProfiles/response/AppliedProfilesGridModel";
import AppliedProfilesSearchInputModel from "../../models/company/relatedAndShortlistedProfiles/request/AppliedProfilesSearchInputModel";
import ShortlistedProfilesSearchInputModel from "../../models/company/relatedAndShortlistedProfiles/request/ShortlistedProfilesSearchInputModel";
import ShortlistedProfileStatusUpdateRequestModel from "../../models/company/relatedAndShortlistedProfiles/request/ShortlistedProfileStatusUpdateRequestModel";

class RelatedShortlistedProfilesService {
    async getRelatedProfilesGrid(inputParms: RelatedProfilesSearchInputModel) {
        let res: PagedListModel<RelatedProfilesGridModel> = await httpClient.post<PagedListModel<RelatedProfilesGridModel>>(AppConfig.company.relatedShortlistedProfiles.getRelatedProfilesGrid, inputParms);
        return res;
    };

    async saveRelatedProfile(inputModel: RelatedProfilesRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.relatedShortlistedProfiles.saveRelatedProfile, inputModel);
        return res;
    };

    async saveCandidateAccessToViewProfile(inputModel: RelatedProfilesRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.relatedShortlistedProfiles.saveCandidateAccessToViewProfile, inputModel);
        return res;
    };

    async getShortlistedProfilesGrid(inputParms: ShortlistedProfilesSearchInputModel) {
        let res: PagedListModel<ShortlistedProfilesGridModel> = await httpClient.post<PagedListModel<ShortlistedProfilesGridModel>>(AppConfig.company.relatedShortlistedProfiles.getShortlistedProfilesGrid, inputParms);
        return res;
    };

    async getAppliedProfilesGrid(inputParms: AppliedProfilesSearchInputModel) {
        let res: PagedListModel<AppliedProfilesGridModel> = await httpClient.post<PagedListModel<AppliedProfilesGridModel>>(AppConfig.company.relatedShortlistedProfiles.getAppliedProfilesGrid, inputParms);
        return res;
    };

    async saveAppliedProfile(inputModel: AppliedProfilesRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.relatedShortlistedProfiles.saveAppliedProfile, inputModel);
        return res;
    };

    async updateStatus(inputModel: ShortlistedProfileStatusUpdateRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.relatedShortlistedProfiles.updateStatus, inputModel);
        return res;
    };
}
const relatedShortlistedProfilesService = new RelatedShortlistedProfilesService();
export default relatedShortlistedProfilesService;
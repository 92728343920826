import adminClasses from "../../../landing-pages/admin/admin.module.css";
import { useForm } from "react-hook-form";
import SecondarySkillRequestModel from "../../../../models/admin/skill/request/SecondarySkillRequestModel";
import { OBJECT_NAME_RULE, OBJECT_DESCRIPTION_RULE } from "../../../../utils/validation-utils";
import IdNameModel from "../../../../models/common/IdNameModel";

interface SecondarySkillProps {
    objSecondarySkill: SecondarySkillRequestModel;
    primarySkillList: IdNameModel[];
    actionType: string;
    onCloseSecondarySkill: () => void;
    onSecondarySkillSave: (objSecondarySkill: SecondarySkillRequestModel) => void;
}

const SecondarySkillCreateComponent = (props: SecondarySkillProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<SecondarySkillRequestModel>({
        mode: "onChange",
        defaultValues: props.objSecondarySkill
    });

    const onSubmit = (formData: SecondarySkillRequestModel) => {
        props.onSecondarySkillSave(formData);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Primary Skill: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("primarySkillId", { required: true })}>
                                        <option value="0">Select Primary Skil</option>
                                        {props.primarySkillList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.primarySkillId && errors.primarySkillId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Primary Skill Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("secondarySkillName", OBJECT_NAME_RULE)} placeholder="Secondary Skill" autoComplete="off" />
                                    <span className="error">
                                        {errors.secondarySkillName && errors.secondarySkillName.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Description: </label>
                                    <textarea rows={3} className="form-control" {...register("description", OBJECT_DESCRIPTION_RULE)} placeholder="Description" autoComplete="off" />
                                    <span className="error">
                                        {errors.description && errors.description.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseSecondarySkill()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default SecondarySkillCreateComponent;
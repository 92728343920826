import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import RegisteredInterviewersComponent from "../../../components/admin/interviewer/registered-interviewers/RegisteredInterviewersComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import masterDataService from "../../../services/master-data-service";

const RegisteredInterviewers = () => {
    const [primarySkillList, setPrimarySkillList] = useState<IdNameModel[]>([]);
    const [refreshSkills, setRefreshSkills] = useState<number>(2);

    useEffect(() => {
        fillPrimarySkills();
    }, []);

    const fillPrimarySkills = () => {
        setRefreshSkills(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadPrimarySkills().then((res) => {
            modalUtils.closeModal();
            setPrimarySkillList(res.items);
            setRefreshSkills(refreshSkills * 5);
        }).catch((error) => {
            setRefreshSkills(refreshSkills * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Registered Interviewers </h4>
            </div>
            {refreshSkills > 2 &&
                <RegisteredInterviewersComponent primarySkillsList={primarySkillList} />
            }
        </Layout>
    );
};
export default RegisteredInterviewers;
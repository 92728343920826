import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import dateUtils from "../../../utils/date-utils";
import DataGrid from "../../common/table/DataGrid";
import ConfirmScheduleDetailsModel from "../../../models/interviewer/response/ConfirmScheduleDetailsModel";
import InterviewerService from '../../../services/interviewer/interviewer-service';
import alertUtils from "../../../utils/toaster-utils";
import loaderUtils from "../../../utils/loader-utils";
import modalUtils from "../../../utils/modal-utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

const PickUpScheduleComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [pickUpSchedules, setPickUpSchedules] = useState<ConfirmScheduleDetailsModel[]>([]);

    useEffect(() => {
        getPickUpSchedules();
    }, []);

    const getPickUpSchedules = () => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        InterviewerService.getInterviewsToBePicked().then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setPickUpSchedules(res.data);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Schedules");
        });
    };

    const columnsSchedules = [
        { key: "InterviewDate", text: "Interview Date", sort: false, searchable: false },
        { key: "TimeSlotName", text: "Time", sort: false, searchable: false },
        { key: "ExperienceName", text: "Yrs of Experience", sort: false, searchable: false },
        { key: "KeyResponsibilities", text: "Spl Notes", sort: false, searchable: false },
        { key: "CandidateName", text: "Your booked slots for the day", sort: false, searchable: false },
        { key: "Actions", text: "PickUp", sort: false, searchable: false }
    ];

    const savePickUpSchedule = (row: ConfirmScheduleDetailsModel) => {
        if (loggedInUser) {
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            InterviewerService.updateInterviewerForSchedule(row.interviewId, loggedInUser.profileId).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    alertUtils.showSuccess(res.informationMessages.toString());
                    getPickUpSchedules();
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Schedules");
            });
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columnsSchedules.map((column: any) => (
                                            <TableCell key={column.key} className="table-header-cell">
                                                <span>{column.text}</span>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pickUpSchedules.length > 0 && pickUpSchedules.map((row: ConfirmScheduleDetailsModel, index) => (
                                        <TableRow>
                                            <TableCell>{dateUtils.getFormattedDateFromDate(row.interviewDate)}</TableCell>
                                            <TableCell>{row.timeSlotName}</TableCell>
                                            <TableCell>{row.experienceName}</TableCell>
                                            <TableCell>{row.keyResponsibilities}</TableCell>
                                            <TableCell>{row.candidateName}</TableCell>
                                            <TableCell>
                                                <a onClick={() => { savePickUpSchedule(row); }} title="Pick Schedule">
                                                    <i className="fa fa-check grid-icon blue"></i>
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {pickUpSchedules.length < 1 &&
                                        <p className="text-right">No Interviews Available.</p>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PickUpScheduleComponent;
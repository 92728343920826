import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminInterviewsModel from "../../../../models/admin/interviews/response/AdminInterviewsModel";
import UpdateInterviewRequestModel from "../../../../models/admin/interviews/request/UpdateInterviewRequestModel";
import { GuidEnum } from "../../../../models/enums/GuidEnum";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import CanidateStatusRequestModel from "../../../../models/admin/candidate/request/CanidateStatusRequestModel";

interface InterviewsGridProps {
    candidateList: AdminInterviewsModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    nameFilterValue: string;
    companyNameFilterValue: string;
    onUpdateInterviewAction: (objInterview: UpdateInterviewRequestModel, primarySkillId: number) => void;
    onViewRatingAction: (interviewId: string) => void;
    onUpdateFollowupAction: (candidateId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const InterviewsGridComponent = (props: InterviewsGridProps) => {
    const [nameFilter, setNameFilter] = useState<string>(props.nameFilterValue);
    const [companyNameFilter, setCompanyNameFilter] = useState<string>(props.companyNameFilterValue);

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const reScheduleInterview = (row: AdminInterviewsModel) => {
        var objCandidate: UpdateInterviewRequestModel = {
            interviewId: row.interviewId,
            interviewDate: new Date(),
            timeSlotId: 0,
            interviewerId: "",
            userId: GuidEnum.Empty
        };
        props.onUpdateInterviewAction(objCandidate, row.primarySkillId);
    };

    const viewRating = (row: AdminInterviewsModel) => {
        props.onViewRatingAction(row.interviewId);
    };

    const updateComments = (row: AdminInterviewsModel) => {
        props.onUpdateFollowupAction(row.candidateId);
    };

    const columnsCandidates = [
        { Key: "InterviewUniqueId", text: "Interview Id", sort: true, searchable: false },
        { Key: "InterviewDate", text: "Interview Dt", sort: true, searchable: false },
        { Key: "CandidateName", text: "Name", sort: true, searchable: true },
        { Key: "EmailId", text: "Email Id", sort: true, searchable: false },
        { Key: "MobileNumber", text: "Mobile No", sort: true, searchable: false },
        { Key: "InterviewerName", text: "Interviewer", sort: true, searchable: false },
        { Key: "PrimarySkillName", text: "Primary Skill", sort: true, searchable: false },
        { Key: "TotalRating", text: "Rating", sort: false, searchable: false },
        { Key: "CompanyName", text: "Company", sort: true, searchable: true },
        { Key: "RecruiterName", text: "Recruiter", sort: false, searchable: false },
        { Key: "DesignationName", text: "Designation", sort: true, searchable: false },
        { Key: "Status", text: "Status", sort: true, searchable: false },
        { Key: "IsRescheduled", text: "Is Rescheduled", sort: false, searchable: false },
        { Key: "IAIAdminName", text: "Admin Name", sort: false, searchable: false },
        { Key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        if (key === "CandidateName") {
            setNameFilter(value);
        } else {
            setCompanyNameFilter(value);
        }
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsCandidates.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={column.key === "CandidateName" ? nameFilter : companyNameFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.candidateList.map((candidate: AdminInterviewsModel, index) => (
                                <TableRow>
                                    <TableCell>
                                        <span>{candidate.interviewUniqueId}</span><a title="copy" onClick={() => { copyClipboard(candidate.interviewUniqueId); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{candidate.interviewDate}</TableCell>
                                    <TableCell>
                                        {candidate.isRegisteredCandidate &&
                                            <span style={{ color: "green" }}>{candidate.candidateName}</span>
                                        }
                                        {!candidate.isRegisteredCandidate &&
                                            <span>{candidate.candidateName}</span>
                                        }
                                    </TableCell>
                                    <TableCell>{candidate.emailId}</TableCell>
                                    <TableCell>
                                        <span>{candidate.mobileNumber}</span><a title="copy" onClick={() => { copyClipboard(candidate.mobileNumber.toString()); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{candidate.interviewerName}</TableCell>
                                    <TableCell>{candidate.primarySkillName}</TableCell>
                                    <TableCell>{candidate.totalRating}</TableCell>
                                    <TableCell>{candidate.companyName}</TableCell>
                                    <TableCell>{candidate.recruiterName}</TableCell>
                                    <TableCell>{candidate.designationName}</TableCell>
                                    <TableCell>{candidate.status}</TableCell>
                                    <TableCell>{candidate.isRescheduled}</TableCell>
                                    <TableCell>{candidate.iAIAdminName}</TableCell>
                                    <TableCell>
                                        {!candidate.isRated &&
                                            <a onClick={() => { reScheduleInterview(candidate); }} title="Re Schedule">
                                                <i className="fa fa-calendar-plus grid-icon blue"></i>
                                            </a>
                                        }

                                        {candidate.isRated &&
                                            <a onClick={() => { viewRating(candidate); }} title="Rating">
                                                <i className="fa fa-star grid-icon blue"></i>
                                            </a>
                                        }
                                        <a onClick={() => { updateComments(candidate); }} title="Update">
                                            <i className="fa fa-check grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default InterviewsGridComponent;
import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";

const CompanyDashboardComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const history = useHistory();

    return (
        <div className="row">
           
        </div>
    );
};
export default CompanyDashboardComponent;
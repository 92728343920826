import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminUserModel from "../../../models/admin/dashboard/response/AdminUserModel";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import AdminUserPopUp from "./AdminUserCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import AdminUserGridComponent from "./AdminUserGridComponent";
import AdminUserSearchInputModel from "../../../models/admin/dashboard/request/AdminUserSearchInputModel";
import adminDashboardService from "../../../services/admin/admin-dashboard-service";
import AdminUserRequestModel from "../../../models/admin/dashboard/request/AdminUserRequestModel";
import loaderUtils from "../../../utils/loader-utils";
import AdminCompanyMappingPopUp from "./AdminCompanyMappingComponent";
import AdminUserCompanyMappingRequestModel from "../../../models/admin/dashboard/request/AdminUserCompanyMappingRequestModel";
import IdNameModel from "../../../models/common/IdNameModel";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface AdminUserProps {
    companiesList: IdNameModel[];
    roles: IdNameModel[];
};

const AdminUserComponent = (props: AdminUserProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [adminUserPagedList, setAdminUserPagedList] = useState<PagedListModel<AdminUserModel>>();
    const [adminUserList, setAdminUserList] = useState<AdminUserModel[]>([]);
    const [refreshAdminUser, setRefreshAdminUser] = useState<number>(2);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("Name");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getAdminUsers(getSearchFilters(nameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (nameFilterValue: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: AdminUserSearchInputModel = {
            name: nameFilterValue,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getAdminUsers = (inputData: AdminUserSearchInputModel) => {
        setRefreshAdminUser(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminDashboardService.getAdminUsers(inputData).then((res) => {
            modalUtils.closeModal();
            setAdminUserList([]);
            setAdminUserPagedList(res);
            setAdminUserList(res.items);
            setRefreshAdminUser(refreshAdminUser * 5);
        }).catch((error) => {
            setRefreshAdminUser(refreshAdminUser * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onAdminUserActionHandler = (obj: AdminUserRequestModel, actionType: string) => {
        openAdminUserSaveModel(obj, actionType);
    };

    const openAdminUserSaveModel = (adminUserRequestModel: AdminUserRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add User",
            content: (
                <AdminUserPopUp
                    objUser={adminUserRequestModel}
                    actionType={actionType}
                    roles={props.roles}
                    onCloseAdminUser={onCloseAdminUserHandler}
                    onAdminUserSave={onAdminUserSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseAdminUserHandler = () => {
        modalUtils.closeModal();
    };

    const onAdminUserSaveHandler = (objAdminUser: AdminUserRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminDashboardService.saveAdminUser(objAdminUser).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getAdminUsers(getSearchFilters(nameFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onAdminUserDeleteActionHandler = (objAdminUser: AdminUserRequestModel) => {
        const objUser = objAdminUser;
        modalUtils.showConfirmation(
            {
                title: "Confirm Admin User Delete",
                message: "Are you sure you want to delete the User.",
                buttons: ["Yes", "No"],
            },
            deleteUser.bind(this, objUser)
        );
    };

    const deleteUser = (objAdminUser: AdminUserRequestModel, action: string) => {
        if (!objAdminUser) {
            return;
        }
        if (action === "Yes") {
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            adminDashboardService.deleteAdminUser(objAdminUser).then((res) => {
                modalUtils.closeModal();
                alertUtils.showSuccess(res.informationMessages.toString());
                getAdminUsers(getSearchFilters(nameFilter, page, pageSize, sortColumn, sortDirection));
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onMapCompanyActionHandler = (adminId: string) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminDashboardService.getAdminCompanyMapping(adminId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Add User",
                content: (
                    <AdminCompanyMappingPopUp
                        mappingList={res.items}
                        adminId={adminId}
                        companyList={props.companiesList}
                        onCloseMapping={onCloseAdminUserHandler}
                        onMappingSave={onMappingSaveHandler}
                    />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onMappingSaveHandler = (objMapping: AdminUserCompanyMappingRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminDashboardService.updateAdminCompanyMapping(objMapping).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            onMapCompanyActionHandler(objMapping.adminId);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getAdminUsers(getSearchFilters(nameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getAdminUsers(getSearchFilters(nameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getAdminUsers(getSearchFilters(nameFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setNameFilter(filterValue);
        setPage(0);
        getAdminUsers(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    const addAdminUser = () => {
        if (loggedInUser !== null) {
            let adminUserRequestModel: AdminUserRequestModel = {
                adminId: GuidEnum.Empty,
                emailId: "",
                userName: "",
                mobileNumber: null,
                name: "",
                userId: loggedInUser?.userId,
                roleId: 0
            };
            onAdminUserActionHandler(adminUserRequestModel, PageModeEnum.Create);
        }
    };

    return (
        <div className="card card-white">
             <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getAdminUsers(getSearchFilters(nameFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addAdminUser}> + Add User</a>
            </div>
            <div className="card-body">
                {refreshAdminUser > 2 &&
                    <AdminUserGridComponent
                        key={refreshAdminUser}
                        adminUsersList={adminUserList}
                        totalSize={adminUserPagedList?.totalCount ? adminUserPagedList?.totalCount : adminUserList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        nameFilterValue={nameFilter}
                        onAdminUserAction={onAdminUserActionHandler}
                        onAdminUserDeleteAction={onAdminUserDeleteActionHandler}
                        onMapCompanyAction={onMapCompanyActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default AdminUserComponent;
import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import BaseResponse from "../../models/common/BaseResponse";
import PagedListModel from "../../models/common/PagedListModel";
import AdminUserSearchInputModel from "../../models/admin/dashboard/request/AdminUserSearchInputModel";
import AdminUserModel from "../../models/admin/dashboard/response/AdminUserModel";
import AdminUserRequestModel from "../../models/admin/dashboard/request/AdminUserRequestModel";
import InterviewRatingModel from "../../models/interviewer/response/InterviewRatingModel";
import AdminUserCompanyMappingRequestModel from "../../models/admin/dashboard/request/AdminUserCompanyMappingRequestModel";
import BaseResponseList from "../../models/common/BaseResponseList";
import AdminCompanyMappingModel from "../../models/admin/dashboard/response/AdminCompanyMappingModel";

class AdminDashboardService {
    async getAdminUsers(inputParms: AdminUserSearchInputModel) {
        let res: PagedListModel<AdminUserModel> = await httpClient.post<PagedListModel<AdminUserModel>>(AppConfig.admin.dashboard.getAdminUsers, inputParms);
        return res;
    };

    async getAdminUserById(adminUserId: string) {
        let res: BaseResponse<AdminUserModel> = await httpClient.get<BaseResponse<AdminUserModel>>(AppConfig.admin.dashboard.getAdminUserById  + "/" + adminUserId);
        return res;
    };

    async saveAdminUser(user: AdminUserRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.dashboard.saveAdminUser, user);
        return res;
    };

    async deleteAdminUser(user: AdminUserRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.dashboard.deleteAdminUser, user);
        return res;
    };

    async interviewRatingDetails(interviewId: string) {
        let res: BaseResponse<InterviewRatingModel> = await httpClient.get<BaseResponse<InterviewRatingModel>>(AppConfig.admin.dashboard.interviewRatingDetails  + "/" + interviewId);
        return res;
    };

    async getAdminCompanyMapping(adminId: string) {
        let res: BaseResponseList<AdminCompanyMappingModel[]> = await httpClient.get<BaseResponseList<AdminCompanyMappingModel[]>>(AppConfig.admin.dashboard.getAdminCompanyMapping  + "/" + adminId);
        return res;
    };

    async updateAdminCompanyMapping(request: AdminUserCompanyMappingRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.dashboard.updateAdminCompanyMapping, request);
        return res;
    };    
}
const adminDashboardService = new AdminDashboardService();
export default adminDashboardService;
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import VendorRequestModel from "../../../models/company/vendor/request/VendorRequestModel";
import { OBJECT_NAME_RULE, EMAIL_RULE, MOBILE_NUMBER_RULE } from "../../../utils/validation-utils";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateUtils from "../../../utils/date-utils";
import alertUtils from "../../../utils/toaster-utils";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface VendorProps {
    objVendor: VendorRequestModel;
    actionType: string;
    onCloseVendor: () => void;
    onVendorSave: (objVendor: VendorRequestModel) => void;
}

const VendorCreateComponent = (props: VendorProps) => {
    const [startDate, setStartDate] = useState("" as any);
    const [endDate, setEndDate] = useState("" as any);
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<VendorRequestModel>({
        mode: "onChange",
        defaultValues: props.objVendor
    });

    const onSubmit = (formData: VendorRequestModel) => {
        if(startDate && endDate) {
            if(startDate > endDate) {
                alertUtils.showError("End Date should be greater than Start Date are Mandatory");
            } else {
                formData.vendorUserName = formData.vendorEmailId;
                formData.startDate = startDate;
                formData.endDate = endDate;
                formData.userId = GuidEnum.Empty;
                props.onVendorSave(formData);
            }
        } else {
            alertUtils.showError("Start Date & End Date are Mandatory");
        }
    }

    const selectStartDateHanler = (date: any) => {
        setStartDate(date);
    };

    const selectEndDateHanler = (date: any) => {
        setEndDate(date);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Vendor Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("vendorName", OBJECT_NAME_RULE)} placeholder="Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.vendorName && errors.vendorName.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Start Date: <span className="required">*</span> </label>
                                    <DateTimePicker
                                        selectsStart
                                        onChange={selectStartDateHanler}
                                        selected={startDate}
                                        timeInputLabel="Time:"
                                        dateFormat="yyyy-MMM-dd"
                                        className="form-control"
                                        minDate={dateUtils.addDays(new Date(), 1)}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Vendor Email: <span className="required">*</span> </label>
                                    <input type="email" className="form-control" {...register("vendorEmailId", EMAIL_RULE)} placeholder="Email/User Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.vendorEmailId && errors.vendorEmailId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">End Date: <span className="required">*</span> </label>
                                    <DateTimePicker
                                        selectsStart
                                        onChange={selectEndDateHanler}
                                        selected={endDate}
                                        timeInputLabel="Time:"
                                        dateFormat="yyyy-MMM-dd"
                                        className="form-control"
                                        minDate={dateUtils.addDays(new Date(), 1)}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Vendor Phone No: <span className="required">*</span> </label>
                                    <input type="tel" className="form-control" {...register("vendorPhoneNumber", MOBILE_NUMBER_RULE)} placeholder="Mobile Number" autoComplete="off" />
                                    <span className="error">
                                        {errors.vendorPhoneNumber && errors.vendorPhoneNumber.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseVendor()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default VendorCreateComponent;
import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import adminCandidateService from "../../../../services/admin/admin-candidate-service";
import alertUtils from "../../../../utils/toaster-utils";
import RegisteredCandidatesGridComponent from "./RegisteredCandidatesGridComponent";
import RegisteredCandidatesSearchInputModel from "../../../../models/admin/candidate/request/RegisteredCandidatesSearchInputModel";
import RegisteredCandidatesModel from "../../../../models/admin/candidate/response/RegisteredCandidatesModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import IdNameModel from "../../../../models/common/IdNameModel";
import candidateService from "../../../../services/candidate/candidate-service";
import { FileUploadTypeEnum } from "../../../../models/enums/FileUploadTypeEnum";
import fileUtils from "../../../../utils/file-utils";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";
import ScheduleInterviewPopUp from "../../../candidate/interview/ScheduleInterviewComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import modalUtils from "../../../../utils/modal-utils";
import PagedListModel from "../../../../models/common/PagedListModel";
import loaderUtils from "../../../../utils/loader-utils";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";

interface RegisteredCandidatesProps {
    primarySkillsList: IdNameModel[];
    timeSlotsList: IdNameModel[];
};

const RegisteredCandidatesComponent = (props: RegisteredCandidatesProps) => {
    const [candidatePagedList, setCandidatePagedList] = useState<PagedListModel<RegisteredCandidatesModel>>();
    const [candidateList, setCandidateList] = useState<RegisteredCandidatesModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(new Date() as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [emailIdFilter, setEmailIdFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        clearSearch();
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (nameFilter: string, emailIdFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: RegisteredCandidatesSearchInputModel = {
            startDate: dateTimeUtils.getFormattedDate(startDateSearch),
            endDate: dateTimeUtils.getFormattedDate(endDateSearch),
            primarySkillId: primarySKillSearch,
            name: nameFilter,
            emailId: emailIdFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getCandidates = (inputData: RegisteredCandidatesSearchInputModel) => {
        setRefreshCandidate(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCandidateService.getRegisteredCandidates(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidatePagedList(res);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);
        }).catch((error) => {
            setRefreshCandidate(refreshCandidate * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const clearSearch = () => {
        setCandidateList([]);
        setStartDateSearch("");
        setEndDateSearch("");
        setPrimarySkillSearch(0);
        setRefreshCandidate(2);
    };

    const onDownloadResumeActionHandler = (candidateId: string, candidateName: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        candidateService.downloadCandidateResume(candidateId, FileUploadTypeEnum.CandidateResume.toString()).then((res) => {
            modalUtils.closeModal();
            fileUtils.downloadFile(res, candidateName);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onScheduleActionHandler = (candidateId: string) => {
        const config: GeneralPoupConfig = {
            title: "Add User",
            content: (
                <ScheduleInterviewPopUp
                    primarySkillsList={props.primarySkillsList}
                    timeSlotsList={props.timeSlotsList}
                    candidateId={candidateId}
                />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onVerifyActionHandler = (candidateId: string) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminCandidateService.verifyCandidate(candidateId).then((res) => {
            modalUtils.closeModal();
            if (res.data) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getCandidates(getSearchFilters(nameFilter, emailIdFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onSearchClickHandler = () => {
        setPage(0);
        setNameFilter("");
        setEmailIdFilter("");
        setSortColumn("CandidateName");
        setSortDirection(SortDirection.Asc);
        getCandidates(getSearchFilters("", "", 0, pageSize, "CandidateName", SortDirection.Asc))
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getCandidates(getSearchFilters(nameFilter, emailIdFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getCandidates(getSearchFilters(nameFilter, emailIdFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getCandidates(getSearchFilters(nameFilter, emailIdFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        if (filterColumn === "Name") {
            setNameFilter(filterValue);
            getCandidates(getSearchFilters(filterValue, emailIdFilter, 0, pageSize, sortColumn, sortDirection));
        } else {
            setEmailIdFilter(filterValue);
            getCandidates(getSearchFilters(nameFilter, filterValue, 0, pageSize, sortColumn, sortDirection));
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Start Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>End Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillsList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => onSearchClickHandler()}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {candidateList.length > 0 && refreshCandidate > 2 &&
                    <RegisteredCandidatesGridComponent
                        key={refreshCandidate}
                        candidateList={candidateList}
                        totalSize={candidatePagedList?.totalCount ? candidatePagedList?.totalCount : candidateList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        nameFilterValue={nameFilter}
                        emailIdFilterValue={emailIdFilter}
                        onDownloadResumeAction={onDownloadResumeActionHandler}
                        onScheduleAction={onScheduleActionHandler}
                        onVerifyAction={onVerifyActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default RegisteredCandidatesComponent;
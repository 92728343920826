import logo from "../../assets/img/logos/logo-white.png";
import { useHistory } from "react-router";
import { MouseEvent } from "react";

const FooterOuter = () => {
    const history = useHistory();
    const register = (event: MouseEvent, registerType: string) => {
        event.preventDefault();
        history.push("/register/" + registerType);
    }
    const homeRedirect = (event: MouseEvent) => {
        event.preventDefault();
        history.push("/index");
    }
    return (
        <footer>
            <div className="container-body">
                <div className="row mt-n1-9">
                    <div className="col-lg-4 col-md-6 mt-1-9">
                        <img alt="footer-logo" src={logo} style={{ width: '200px' }} onClick={(e) => { homeRedirect(e); }}></img>
                        <p className="mt-4 text-light-gray">IamInterviewed is Technical Interview platform. It helps Companies to choose right talent with minimum efforts there by reducing the Time and Cost.
                            IamInterviewed also helps candidate's to standout with Technical Skill rating to catch the attention of the companies and Recruiters.</p>
                        <ul className="footer-list ps-0">
                            <li>Email: <a href="mailto:info@iaminterviewed.com">info@iaminterviewed.com</a></li>
                            <li>LinkedIn: <a href="https://www.linkedin.com/in/iaminterviewed/">https://www.linkedin.com/in/iaminterviewed/</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-1-9">
                        <h3 className="text-white">Know Us</h3>
                        <ul className="footer-list ps-0">
                            <li><a href="#!">Testimonials</a></li>
                            <li><a href="#!">About Us</a></li>
                            <li><a href="#!">FAQ's</a></li>
                            <li><a href="#!">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-1-9">
                        <h3 className="text-white">Useful Links</h3>
                        <ul className="footer-list ps-0">
                            <li><a onClick={(e) => { register(e, "Candidate"); }}>Candidate</a></li>
                            <li><a onClick={(e) => { register(e, "Interviewer"); }}>Interviewer</a></li>
                            <li><a onClick={(e) => { register(e, "Company"); }}>Employer</a></li>
                            <li><a onClick={(e) => { homeRedirect(e); }}>IamInterviewed</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 mt-1-9">
                        <h3 className="text-white">Contact Us</h3>
                        <p className="mt-4 text-light-gray">#10 M 226,
                            100 Ft Road, HRBR Layout,
                            Block 1, Kalyan Nagar,
                            Bangalore - 560 043. India.</p>
                    </div>
                </div>
            </div>
            <div className="footer-bar">
                <div className="container-body">
                    <p className="mb-0">&copy; <span className="current-year"></span> Iam Interviewed is Powered by <a href="#!" className="text-light-gray">Anterntech</a></p>
                </div>
            </div>
        </footer>
    );
};
export default FooterOuter; 
import { useState, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import RegisteredCompaniesRequestModel from "../../../../models/admin/company/request/RegisteredCompaniesRequestModel";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";

interface CompanyConfigurationProps {
    objCompany: RegisteredCompaniesRequestModel;
    onCloseCompanyConfiguration: () => void;
    onCompanyConfigurationSave: (objCompany: RegisteredCompaniesRequestModel) => void;
};

const CompanyConfigurationComponent = (props: CompanyConfigurationProps) => {
    const [startDate, setStartDate] = useState(new Date(props.objCompany.startDate) as any);
    const [endDate, setEndDate] = useState(new Date(props.objCompany.endDate) as any);
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<RegisteredCompaniesRequestModel>({
        mode: "onChange",
        defaultValues: props.objCompany
    });

    const startDateHanler = (date: any) => {
        setStartDate(date);
    };

    const endDateHanler = (date: any) => {
        setEndDate(date);
    };

    const onSubmit = (formData: RegisteredCompaniesRequestModel) => {
        formData.startDate = startDate;
        formData.endDate = endDate;
        props.onCompanyConfigurationSave(formData);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">No Of Interviews Allowed: <span className="required">*</span> </label>
                                    <input type="number" className="form-control" {...register("noOfInterviewsAllowed")} autoComplete="off" />
                                    <span className="error">
                                        {errors.noOfInterviewsAllowed && errors.noOfInterviewsAllowed.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <input type="checkbox" {...register("isAuthorize")} />&nbsp;&nbsp;
                                    <label> Authorize </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Start Date</label>
                            <DateTimePicker
                                selectsStart
                                onChange={startDateHanler}
                                selected={startDate}
                                timeInputLabel="Time:"
                                dateFormat="yyyy-MMM-dd"
                                className="form-control"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <label>End Date</label>
                            <DateTimePicker
                                selectsStart
                                onChange={endDateHanler}
                                selected={endDate}
                                timeInputLabel="Time:"
                                dateFormat="yyyy-MMM-dd"
                                className="form-control"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseCompanyConfiguration()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default CompanyConfigurationComponent;
import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import BaseResponse from "../../models/common/BaseResponse";
import BaseResponseList from "../../models/common/BaseResponseList";
import PagedListModel from "../../models/common/PagedListModel";
import RequirementEscalationsSearchInputModel from "../../models/company/escalation/request/RequirementEscalationsSearchInputModel";
import RequirementEscalationsGridModel from "../../models/company/escalation/response/RequirementEscalationsGridModel";
import RequirementEscalationsRequestModel from "../../models/company/escalation/request/RequirementEscalationsRequestModel";

class ClientEscalationService {

    async getEscalations(inputParms: RequirementEscalationsSearchInputModel) {
        let res: PagedListModel<RequirementEscalationsGridModel> = await httpClient.post<PagedListModel<RequirementEscalationsGridModel>>(AppConfig.company.clientEscalations.getEscalations, inputParms);
        return res;
    };

    async getEscalationsForRequirement(requirementId: string) {
        let res: BaseResponseList<RequirementEscalationsGridModel[]> = await httpClient.get<BaseResponseList<RequirementEscalationsGridModel[]>>(AppConfig.company.clientEscalations.getEscalationsForRequirement  + "/" + requirementId);
        return res;
    };

    async saveEscalation(inputModel: RequirementEscalationsRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.company.clientEscalations.saveEscalation, inputModel);
        return res;
    };

}
const clientEscalationService = new ClientEscalationService();
export default clientEscalationService;
import { useRef, MouseEvent, useState, useEffect } from "react";
import '../../../assets/InnerCSS/css/rating.css';
import { IAIEnum } from "../../../models/enums/IAIEnum";
import InterviewRatingModel from "../../../models/interviewer/response/InterviewRatingModel";
import modalUtils from "../../../utils/modal-utils";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { toPng } from 'html-to-image';
import logo from "../../../assets/img/logos/logo.png";
import badgeURL from "../../../assets/InnerCSS/img/badge.png";

interface RatingDetailsProps {
    objRatingDetails: InterviewRatingModel;
}
const DownloadBadgeComponent = (props: RatingDetailsProps) => {
    const secondarySkillList: string = props.objRatingDetails.secondarySkills.map(x => x.secondarySkillName).toString();

    const downloadPdf = () => {
        const input = document.getElementById('badge-div') as HTMLElement;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape'
            });
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            console.log(pdfWidth + ", " + pdfHeight);
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(props.objRatingDetails.candidateName + ".pdf");
        });
    }

    const downloadImage = () => {
        const source = document.getElementById('badge-div') as HTMLElement;
        toPng(source, { cacheBust: false }).then((dataUrl) => {
            const link = document.createElement("a");
            link.download = props.objRatingDetails.candidateName + ".png";
            link.href = dataUrl;
            link.click();
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <a className="model-header-buttons" title="Download Rating as Pdf" id="btnGeneratePdf" onClick={() => downloadPdf()}><i className="fa fa-file-pdf" aria-hidden="true"></i></a>
                        <a className="model-header-buttons" title="Load Preview" id="btnImageDownload" onClick={() => downloadImage()}><i className="fa fa-image" aria-hidden="true"></i></a>
                    </div>
                </div>
                <div className="container-fluid badgeWrapper" id="badge-div">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 badgeRating">
                            <h1>{props.objRatingDetails.totalRating}/{IAIEnum.MaxRating}</h1>
                        </div>
                        <div className="col-lg-7 col-md-7 badgeBody">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 badgeRatingDetails">
                                    <h4>{props.objRatingDetails.candidateName}</h4>
                                    <p>ID: <span>{props.objRatingDetails.interviewUniqueId}</span></p>
                                    <p>Primary Skill: <span>{props.objRatingDetails.primarySkillName}</span></p>
                                </div>
                                <div className="col-lg-6 col-md-6 badgeRatingDetails badgeSecSkill">
                                    <p>Secondary Skill</p>
                                    <p>
                                        {props.objRatingDetails.technicalSkillRating.map((field, index) => (
                                            <span>
                                                {field.secondarySkillName} : <span className="badgeSecSkillRating">{field.rating}</span>
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 badgeLogo">
                                    <p className="badgeVeryFy">
                                        Verify at www.iaminterviewed.com
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 badgeTail">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DownloadBadgeComponent;
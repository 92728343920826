class LoaderUtils {
    getLoadDataText() {        
        return "Loading Data PLease wait...";
    };

    getSaveDataText() {        
        return "Saving Data PLease wait...";
    };
}
const loaderUtils = new LoaderUtils();
export default loaderUtils;
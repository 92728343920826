import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminCompanyService from "../../../../services/admin/admin-company-service";
import alertUtils from "../../../../utils/toaster-utils";
import AddProfilesComponent from "../../../../components/company/add-profiles/AddProfilesComponent";
import jobPostingService from "../../../../services/company/job-posting-service";

interface AdminAddProfilesProps {
    companiesList: IdNameModel[];
    vendorList: IdNameModel[];
    statusList: IdNameModel[];    
};

const AdminAddProfilesComponent = (props: AdminAddProfilesProps) => {
    const [recruiterList, setRecruiterList] = useState<IdNameModel[]>([]);
    const [jobcodeList, setJobcodeList] = useState<IdNameModel[]>([]);
    const [companySearch, setCompanySearch] = useState<string>("");
    const [recruiterSearch, setRecruiterSearch] = useState<string>("");
    const [jobcodeSearch, setJobcodeSearch] = useState<string>("");
    const [refreshRequirements, setRefreshRequirements] = useState<number>(2);
    const [canScheduleInterviews, setCanScheduleInterviews] = useState<boolean>(false);
    const [refreshCanScheduleInterviews, setRefreshCanScheduleInterviews] = useState<number>(2);

    useEffect(() => {
        clearSearch();
    }, []);

    const clearSearch = () => {
        setCompanySearch("")
        setRecruiterSearch("");
        setJobcodeSearch("");
        setRefreshRequirements(2);
    };

    const loadRecruiters = (companyId: string) => {
        setRefreshRequirements(2);
        setCompanySearch(companyId);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getRecruiterByCompany(companyId).then((res) => {
            modalUtils.closeModal();
            setRecruiterSearch("");
            setRecruiterList(res.items);
        }).catch((error) => {
            setRecruiterSearch("");
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const loadJobcodes = (recruiterId: string) => {
        setRefreshRequirements(2);
        setRecruiterSearch(recruiterId);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getJobCodesForRecruiter(recruiterId).then((res) => {
            modalUtils.closeModal();
            setJobcodeSearch("");
            setJobcodeList(res.items);
        }).catch((error) => {
            setRecruiterSearch("");
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const setJobcode = (requirementId: string) => {
        setRefreshRequirements(2);
        setJobcodeSearch(requirementId);
        getJobPosting(requirementId);
    };

    const getRequirements = () => {
        if (companySearch === "" || recruiterSearch === "") {
            setRefreshRequirements(2);
        } else {
            setRefreshRequirements(refreshRequirements * 5);
        }
    };

    const getJobPosting = (requirementId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        setRefreshCanScheduleInterviews(2);
        jobPostingService.getJobPostingById(requirementId).then((res) => {
            modalUtils.closeModal();
            if(res.data.isCalibrationCallCompleted && res.data.isClientApproved && res.data.panelsAvailable){
                setCanScheduleInterviews(true);                
            }
            setRefreshCanScheduleInterviews(refreshCanScheduleInterviews * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            setRefreshCanScheduleInterviews(refreshCanScheduleInterviews * 5);
            alertUtils.showError(error);
        });
    };

    return (
        <div>
            <div className="card card-white">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label>Company</label>
                            <select className="mb-3 form-control form-select" onChange={(e) => loadRecruiters(e.target.value)}>
                                <option value="">Select Company</option>
                                {props.companiesList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.guId}> {item.name} </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label>Recruiter</label>
                            <select className="mb-3 form-control form-select" onChange={(e) => loadJobcodes(e.target.value)}>
                                <option value="">Select Recruiter</option>
                                {recruiterList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.guId}> {item.name} </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label>JobCode</label>
                            <select className="mb-3 form-control form-select" onChange={(e) => setJobcode(e.target.value)}>
                                <option value="">Select JobCode</option>
                                {jobcodeList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.guId}> {item.name} </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-success" type="submit" onClick={() => getRequirements()} disabled={companySearch === "" || recruiterSearch === ""}>
                                <i className="icon-search">Search</i>
                            </button>
                            <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                                <i className="icon-search">Clear</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {refreshRequirements > 2 && refreshCanScheduleInterviews > 2 &&
                <AddProfilesComponent
                    vendorList={props.vendorList}
                    statusList={props.statusList}                    
                    requirementId={jobcodeSearch}
                    profileId={companySearch}
                    userId={recruiterSearch}
                    canScheduleInterviews={canScheduleInterviews} />
            }
        </div>
    );
};
export default AdminAddProfilesComponent;
import React from "react";
import SecondarySkillTopicModel from "../../../../models/admin/skill/response/SecondarySkillTopicModel";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

interface SecondarySkillMappingGridProps {
    mappingList: SecondarySkillTopicModel[];
    onTopicDelteAction: (topic: SecondarySkillTopicModel) => void
};

const SecondarySkillMappingGridComponent = (props: SecondarySkillMappingGridProps) => {
    const deleteTopic = (topic: SecondarySkillTopicModel) => {
        props.onTopicDelteAction(topic)
    };

    const columnsMapping = [
        { key: "TopicName", text: "Topic Name", sort: false, searchable: false },
        { key: "Description", text: "Description", sort: false, searchable: false },
        { key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsMapping.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.mappingList.map((topic: SecondarySkillTopicModel, index) => (
                                <TableRow>
                                    <TableCell>{topic.topicName}</TableCell>
                                    <TableCell>{topic.description}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { deleteTopic(topic); }} title="Delete">
                                            <i className="fa fa-trash grid-icon red"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default SecondarySkillMappingGridComponent;

import { Fragment, MouseEvent, useState } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../context-store";
import menuContext from "../../context-store/menu-context";
import styles from "./SideBar.module.css";
import MenuProps from "./MenuProps";
import { Collapse } from 'react-bootstrap';
import SubMenuModel from "./model/SubMenuModel";

const MenuItem = (props: MenuProps) => {
  const dispatch = useAppDispatch();
  const [sideBarExpanded, mainMenuSelected] = useAppSelector((state) => [
    state.menuState.sideBarExpanded,
    state.menuState.selectedMenu,
  ]);

  const history = useHistory();
  let isMenuOpened: boolean = props.menuItem.opened;
  let dNone = sideBarExpanded ? "" : "d-none";
  let activeLinkClass =
    mainMenuSelected!.id === props.menuItem.id ? styles.activeMainLink : "";
  let activeAClass = window.location.pathname === props.menuItem.path ? "nav-link active" : "nav-link"
  let mainMenuClass = `${styles.mainMenu} list-group-item list-group-item-action flex-column align-items-start`;
  let menuTitle = `menu-collapsed ${dNone}`;
  let menuIconClass = `${styles.menuIcon} ${dNone} ml-auto`;
  const menuClickHandler = (event: MouseEvent) => {
    event.preventDefault();
    if (props.menuItem.path) {
      history.push(props.menuItem.path);
    }
    dispatch(menuContext.actions.selectMenu(props.menuItem));
  };

  const subMenuClickHandler = (e: MouseEvent, subMenu: SubMenuModel) => {
    e.preventDefault();
    if (subMenu.path) {
      history.push(subMenu.path);
    }
    dispatch(menuContext.actions.selectSubMenu(subMenu));
  };
  const [state, setState] = useState<any>({});

  const toggleMenuState = (menuState: any) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach(i => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  }

  return (
    <Fragment>
      <div>
        {!props.menuItem.hasChildren ? (
          <li className="nav-item iai-nav-item">
            <NavLink className={activeAClass} to="" onClick={(e) => { menuClickHandler(e); }} title={props.menuItem.title}>
              <span className="menu-title iai-menu-title-text"><i className={props.menuItem.icon}></i>{props.menuItem.title}</span>
            </NavLink>
          </li>
        ) : (
          <li className="nav-item iai-nav-item">
            <div className={state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
              <i className={state.basicUiMenuOpen ? 'menu-group-icon fa fa-chevron-down' : 'menu-group-icon fa fa-chevron-left'}></i><span className="menu-title">{props.menuItem.title}</span>
            </div>
            <Collapse in={state.basicUiMenuOpen}>
              <ul className="nav flex-column">
                {props.menuItem.subMenuItems.map((subMenu, index) => {
                  return (
                    <NavLink className="nav-link" to="" onClick={(e) => { subMenuClickHandler(e, subMenu); }} title={subMenu.title}>
                      <span className="menu-title iai-menu-title-text"><i className={subMenu.icon}></i>{subMenu.title}</span>
                    </NavLink>
                  );
                })}
              </ul>
            </Collapse>
          </li>
        )}
      </div>
    </Fragment>
  );
};
export default MenuItem; 
import { useState, MouseEvent, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import RegisteredInterviewerModel from "../../../../models/admin/interviewer/response/RegisteredInterviewerModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import RegisteredInterviewerSearchInputModel from "../../../../models/admin/interviewer/request/RegisteredInterviewerSearchInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminInterviewerService from "../../../../services/admin/admin-interviewer-service";
import alertUtils from "../../../../utils/toaster-utils";
import RegisteredInterviewersGridComponent from "./RegisteredInterviewersGridComponent";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";

interface RegisteredInterviewersProps {
    primarySkillsList: IdNameModel[];
};

const RegisteredInterviewersComponent = (props: RegisteredInterviewersProps) => {
    const [interviewerPagedList, setInterviewerPagedList] = useState<PagedListModel<RegisteredInterviewerModel>>();
    const [interviewerList, setInterviewerList] = useState<RegisteredInterviewerModel[]>([]);
    const [refreshInterviewer, setRefreshInterviewer] = useState<number>(2);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [emailIdFilter, setEmailIdFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("Name");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (nameFilter: string, emailIdFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: RegisteredInterviewerSearchInputModel = {
            primarySkillId: primarySKillSearch,
            name: nameFilter,
            emailId: emailIdFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getRegisteredInterviewers = (inputData: RegisteredInterviewerSearchInputModel) => {
        if (primarySKillSearch == 0) {
            alertUtils.showWarning("Please select Primary Skill.");
            return;
        }
        setRefreshInterviewer(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewerService.getRegisteredInterviewers(inputData).then((res) => {
            modalUtils.closeModal();
            setInterviewerList([]);
            setInterviewerPagedList(res);
            setInterviewerList(res.items);
            setRefreshInterviewer(refreshInterviewer * 5);
        }).catch((error) => {
            setRefreshInterviewer(refreshInterviewer * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onApproveActionHandler = (interviewerId: string) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminInterviewerService.approveInterviewer(interviewerId).then((res) => {
            modalUtils.closeModal();
            if (res.data) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getRegisteredInterviewers(getSearchFilters(nameFilter, emailIdFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getRegisteredInterviewers(getSearchFilters(nameFilter, emailIdFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getRegisteredInterviewers(getSearchFilters(nameFilter, emailIdFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getRegisteredInterviewers(getSearchFilters(nameFilter, emailIdFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        if (filterColumn === "Name") {
            setNameFilter(filterValue);
            getRegisteredInterviewers(getSearchFilters(filterValue, emailIdFilter, 0, pageSize, sortColumn, sortDirection));
        } else {
            setEmailIdFilter(filterValue);
            getRegisteredInterviewers(getSearchFilters(nameFilter, filterValue, 0, pageSize, sortColumn, sortDirection));
        }
    };


    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillsList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getRegisteredInterviewers(getSearchFilters(nameFilter, emailIdFilter, page, pageSize, sortColumn, sortDirection))}>
                            <i className="icon-search">Search</i>
                        </button>
                    </div>
                </div>
                {interviewerList.length > 0 && refreshInterviewer > 2 &&
                    <RegisteredInterviewersGridComponent
                        key={refreshInterviewer}
                        interviewersList={interviewerList}
                        totalSize={interviewerPagedList?.totalCount ? interviewerPagedList?.totalCount : interviewerList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        nameFilterValue={nameFilter}
                        emailIdFilterValue={emailIdFilter}
                        onApproveAction={onApproveActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default RegisteredInterviewersComponent;
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import alertUtils from "../../../../utils/toaster-utils";
import SecondarySkillPopUp from "./SecondarySkillCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import SecondarySkillGridComponent from "./SecondarySkillGridComponent";
import SecondarySkillModel from "../../../../models/admin/skill/response/SecondarySkillModel";
import SecondarySkillSearchInputModel from "../../../../models/admin/skill/request/SecondarySkillSearchInputModel";
import adminSkillService from "../../../../services/admin/admin-skill-service";
import SecondarySkillRequestModel from "../../../../models/admin/skill/request/SecondarySkillRequestModel";
import loaderUtils from "../../../../utils/loader-utils";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";
import { PageModeEnum } from "../../../../models/enums/PageModeEnum";
import SecondarySkillMappingPopup from "./SecondarySkillMappingComponent";

interface SecondarySkillsProps {
    primarySkillsList: IdNameModel[];
};

const SecondarySkillComponent = (props: SecondarySkillsProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [secondarySkillPagedList, setSecondarySkillPagedList] = useState<PagedListModel<SecondarySkillModel>>();
    const [secondarySkillList, setSecondarySkillList] = useState<SecondarySkillModel[]>([]);
    const [refreshSecondarySkill, setRefreshSecondarySKill] = useState<number>(2);
    const [primarySkillFilter, setPrimarySkillFilter] = useState<string>("");
    const [secondarySkillFilter, setSecondarySkillFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("SecondarySkillName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getSecondarySkills(getSearchFilters(primarySkillFilter, secondarySkillFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (primarySkillFilter: string, secondarySkillFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: SecondarySkillSearchInputModel = {
            secondarySkillName: secondarySkillFilter,
            primarySkillName: primarySkillFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getSecondarySkills = (inputData: SecondarySkillSearchInputModel) => {
        setRefreshSecondarySKill(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminSkillService.getSecondarySkills(inputData).then((res) => {
            modalUtils.closeModal();
            setSecondarySkillList([]);
            setSecondarySkillPagedList(res);
            setSecondarySkillList(res.items);
            setRefreshSecondarySKill(refreshSecondarySkill * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onSecondarySkillActionHandler = (obj: SecondarySkillRequestModel, actionType: string) => {
        openSecondarySkillSaveModel(obj, actionType);
    };

    const openSecondarySkillSaveModel = (secondarySkillRequestModel: SecondarySkillRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Secondary Skill",
            content: (
                <SecondarySkillPopUp
                    objSecondarySkill={secondarySkillRequestModel}
                    primarySkillList={props.primarySkillsList}
                    actionType={actionType}
                    onCloseSecondarySkill={onCloseSecondarySkillHandler}
                    onSecondarySkillSave={onSecondarySkillSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseSecondarySkillHandler = () => {
        modalUtils.closeModal();
    };

    const onSecondarySkillSaveHandler = (objSecondarySkill: SecondarySkillRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminSkillService.saveSecondarySkill(objSecondarySkill).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getSecondarySkills(getSearchFilters(primarySkillFilter, secondarySkillFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getSecondarySkills(getSearchFilters(primarySkillFilter, secondarySkillFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getSecondarySkills(getSearchFilters(primarySkillFilter, secondarySkillFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getSecondarySkills(getSearchFilters(primarySkillFilter, secondarySkillFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        if (filterColumn === "PrimarySkillName") {
            setPrimarySkillFilter(filterValue);
            getSecondarySkills(getSearchFilters(filterValue, secondarySkillFilter, 0, pageSize, sortColumn, sortDirection));
        } else {
            setSecondarySkillFilter(filterValue);
            getSecondarySkills(getSearchFilters(primarySkillFilter, filterValue, 0, pageSize, sortColumn, sortDirection));
        }
    };

    const addSecondarySkill = () => {
        if (loggedInUser !== null) {
            let secondarySkillRequestModel: SecondarySkillRequestModel = {
                secondarySkillId: 0,
                primarySkillId: 0,
                secondarySkillName: "",
                description: "",
                userId: loggedInUser?.userId
            }
            onSecondarySkillActionHandler(secondarySkillRequestModel, PageModeEnum.Create);
        }
    };

    const onMapTopicActionHandler = (secondarySkillId: number) => {
        const config: GeneralPoupConfig = {
            title: "Add Topic",
            content: (
                <SecondarySkillMappingPopup secondarySkillId={secondarySkillId} />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getSecondarySkills(getSearchFilters(primarySkillFilter, secondarySkillFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addSecondarySkill}> + Add Secondary Skill</a>
            </div>
            <div className="card-body">
                {secondarySkillList.length > 0 && refreshSecondarySkill > 2 &&
                    <SecondarySkillGridComponent
                        key={refreshSecondarySkill}
                        secondarySkillList={secondarySkillList}
                        totalSize={secondarySkillPagedList?.totalCount ? secondarySkillPagedList?.totalCount : secondarySkillList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        primarySkillFilterValue={primarySkillFilter}
                        secondarySkillFilterValue={secondarySkillFilter}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler}
                        onSecondarySkillAction={onSecondarySkillActionHandler}
                        onMapTopicAction={onMapTopicActionHandler} />
                }
            </div>
        </div>
    );
};
export default SecondarySkillComponent;
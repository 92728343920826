import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import EscalationsComponent from "../../../components/admin/company/escalations/EscalationsComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import { ScreenEnum } from "../../../models/enums/StatusEnum";

const Escalations = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [statusList, setStatusList] = useState<IdNameModel[]>([]);
    const [refreshStatus, setRefreshStatus] = useState<number>(2);

    useEffect(() => {
        getStatusList();
    }, []);

    const getStatusList = () => {
        setRefreshStatus(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadStatusByScreen(ScreenEnum.EscalationsStatus).then((res) => {
            modalUtils.closeModal();
            setStatusList(res.items);
            setRefreshStatus(refreshStatus * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            setRefreshStatus(refreshStatus * 5);
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Add Profiles </h4>
            </div>
            {refreshStatus > 2 &&
                <EscalationsComponent statusList={statusList} />
            }
        </Layout>
    );
};
export default Escalations;
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyDashboardModel from "../../../models/company/dashboard/response/CompanyDashboardModel";
import IdNameModel from "../../../models/common/IdNameModel";
import companyClasses from "../../../landing-pages/company/company.module.css";
import JobPostingStatusUpdateModel from "../../../models/company/jobPosting/request/JobPostingStatusUpdateModel";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import alertUtils from "../../../utils/toaster-utils";
import TablePagination from "@mui/material/TablePagination";

interface JobPostingDashboardGridProps {
    jobPostingList: CompanyDashboardModel[];
    status: IdNameModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    onJobStatusChangeAction: (jobPosting: JobPostingStatusUpdateModel) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
};

const JobPostingDashboardGridComponent = (props: JobPostingDashboardGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [roleId, setRoleId] = useState<number>(loggedInUser !== null ? parseInt(loggedInUser.roleId) : 0);
    const history = useHistory();

    const updateStatus = (requirementId: string, statusId: number) => {
        if (roleId === parseInt(RoleEnum.ClientRecruiter.toString())) {
            if (loggedInUser !== null) {
                let updateModel: JobPostingStatusUpdateModel = {
                    requirementId: requirementId,
                    statusId: statusId,
                    comments: "",
                    userId: loggedInUser?.userId
                }
                props.onJobStatusChangeAction(updateModel);
            }
        } else {
            alertUtils.showWarning("You Don't have permission to update.");
        }
    };

    const getStatusNameFromList = (statusId: number) => {
        let objStatus = props.status.find(x => x.id === statusId);
        if (objStatus !== null) {
            return objStatus?.name
        } else {
            return "";
        }
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    return (
        <div className="row">
            {props.jobPostingList.map((posting: CompanyDashboardModel) => {
                return (
                    <div className="card card-white">
                        <div className="card-heading clearfix ">
                            <h4 className="card-title">{posting.jobCode}</h4>
                        </div>
                        <div className="card-body" style={{ padding: '0' }}>
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Exp </b>: {posting.experience}</p>
                                    <p><b>Posted on</b>: {posting.postedDate}</p>
                                    <p><b>Designation</b>: {posting.designationName}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><b>Posted by</b>: {posting.postedBy}</p>
                                    <p><b>Skills</b>: {posting.skills}</p>
                                    <p><b>Status</b>: <a className={companyClasses.jobPostinglinks} onClick={() => updateStatus(posting.requirementId, posting.statusId)}>{getStatusNameFromList(posting.statusId)}</a>
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <div className="input-group-btn input-group-prepend last">
                                        {roleId === parseInt(RoleEnum.ClientRecruiter.toString()) &&
                                            <ul className="list-inline ">
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/add-profiles/" + posting.requirementId)}> Add Profile</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/related-profiles/" + posting.requirementId)}> Related Profiles</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/applied-candidates/" + posting.requirementId)}> Applied Candidates</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/shortlisted-profiles/" + posting.requirementId)}> Shortlisted Profiles</a></h6></li>
                                            </ul>
                                        }
                                        {roleId === parseInt(RoleEnum.VendorRecruiter.toString()) &&
                                            <ul className="list-inline ">
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/add-profiles/" + posting.requirementId)}> Add Profile</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} > Related Profiles</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} > Applied Candidates</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} > Shortlisted Profiles</a></h6></li>
                                            </ul>
                                        }
                                        {roleId === parseInt(RoleEnum.ClientAdmin.toString()) || roleId === parseInt(RoleEnum.ClientLead.toString()) &&
                                            <ul className="list-inline ">
                                                {/* <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/add-profiles/" + posting.requirementId)}> Add Profile</a></h6></li> */}
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/related-profiles/" + posting.requirementId)}> Related Profiles</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/applied-candidates/" + posting.requirementId)}> Applied Candidates</a></h6></li>
                                                <li><h6><i className="fas fa-angle-right"></i><a className={companyClasses.jobPostinglinks} onClick={() => history.push("/shortlisted-profiles/" + posting.requirementId)}> Shortlisted Profiles</a></h6></li>
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <TablePagination
                rowsPerPageOptions={props.pageSizesList}
                component="div"
                count={props.totalSize}
                rowsPerPage={props.pagesize}
                page={props.currentPage - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};
export default JobPostingDashboardGridComponent;
import { useState, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminUserRequestModel from "../../../models/admin/dashboard/request/AdminUserRequestModel";
import { OBJECT_NAME_RULE, EMAIL_RULE, MOBILE_NUMBER_RULE } from "../../../utils/validation-utils";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import IdNameModel from "../../../models/common/IdNameModel";

interface AdminUserProps {
    objUser: AdminUserRequestModel;
    actionType: string;
    roles: IdNameModel[];
    onCloseAdminUser: () => void;
    onAdminUserSave: (objUser: AdminUserRequestModel) => void;
}

const AdminUserCreateComponent = (props: AdminUserProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<AdminUserRequestModel>({
        mode: "onChange",
        defaultValues: props.objUser
    });

    const onSubmit = (formData: AdminUserRequestModel) => {
        formData.userName = formData.emailId;
        props.onAdminUserSave(formData);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("name", OBJECT_NAME_RULE)} placeholder="Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.name && errors.name.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Email: <span className="required">*</span> </label>
                                    <input type="email" className="form-control" {...register("emailId", EMAIL_RULE)} placeholder="Email/User Name" autoComplete="off" disabled={props.actionType === PageModeEnum.Edit} />
                                    <span className="error">
                                        {errors.emailId && errors.emailId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <input type="tel" className="form-control" {...register("mobileNumber", MOBILE_NUMBER_RULE)} placeholder="Mobile Number" autoComplete="off" />
                                    <span className="error">
                                        {errors.mobileNumber && errors.mobileNumber.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Role: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("roleId", { required: true })} disabled={props.actionType === PageModeEnum.Edit}>
                                        <option value="0">Select Role</option>
                                        {props.roles.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.roleId && errors.roleId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseAdminUser()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AdminUserCreateComponent;
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import CandidateAccessToViewProfileGridModel from "../../../models/candidate/response/CandidateAccessToViewProfileGridModel";
import CandidateAccessToViewProfileRequestModel from "../../../models/candidate/request/CandidateAccessToViewProfileRequestModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface CandidateAccessGridProps {
    accessList: CandidateAccessToViewProfileGridModel[];
    onProvideAccess: (row: CandidateAccessToViewProfileRequestModel) => void;
}

const CandidateAccessGridComponent = (props: CandidateAccessGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const ActionFormatter = (
        cell: any,
        row: CandidateAccessToViewProfileGridModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { provideAccess(row); }}>Provide Access</a>
            </div>
        );
    };

    const provideAccess = (row: CandidateAccessToViewProfileGridModel) => {
        if (loggedInUser?.profileId) {
            var objAccess: CandidateAccessToViewProfileRequestModel = {
                accessId: row.accessId,
                candidateId: loggedInUser.profileId,
                hasAccess: true,
                userId: GuidEnum.Empty
            }
            props.onProvideAccess(objAccess);
        }
    };

    const columnsAccess = [
        { key: "CompanyName", text: "Company", sort: false, searchable: false },
        { key: "DesignationName", text: "Designation", sort: false, searchable: false },
        { key: "JobCode", text: "Job Code", sort: false, searchable: false },
        { key: "HasAccess", text: "Has Access", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false, formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
            <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsAccess.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.accessList.map((row: CandidateAccessToViewProfileGridModel, index) => (
                                <TableRow>
                                    <TableCell>{row.companyName}</TableCell>
                                    <TableCell>{row.designationName}</TableCell>
                                    <TableCell>{row.jobCode}</TableCell>
                                    <TableCell>{row.hasAccess}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { provideAccess(row); }} title="Provide Access">
                                            <i className="fa fa-check-circle grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default CandidateAccessGridComponent;
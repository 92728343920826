import React from "react";
import {  useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import JobPostingInterviewProcessRequestModel from "../../../models/company/jobPosting/request/JobPostingInterviewProcessRequestModel";
import InterviewProcessModel from "../../../models/company/jobPosting/response/InterviewProcessModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface JobPostingInterviewProcessGridProps {
    jobPostingInterviewProcessList: InterviewProcessModel[];
    onJobPostingInterviewProcessAction: (row: JobPostingInterviewProcessRequestModel, actionType: string) => void;
}

const JobPostingInterviewProcessGridComponent = (props: JobPostingInterviewProcessGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const history = useHistory();

    const ActionFormatter = (
        cell: any,
        row: InterviewProcessModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">                
                <a style={{display: 'block'}} onClick={() => { editJobPostingInterviewProcess(row); }}>Edit</a>
            </div>
        );
    };

    const editJobPostingInterviewProcess = (row: InterviewProcessModel) => {
        if (loggedInUser !== null) {
            let jobPostingInterviewProcessRequestModel: JobPostingInterviewProcessRequestModel = {
                requirementInterviewProcessId: row.requirementInterviewProcessId,
                requirementId: row.requirementId,
                interviewRoundId: row.interviewRoundId,
                recruiterId: loggedInUser.profileId,
                userId: loggedInUser.userId,
                jobPostingSkillRequestModel: []
            }
            props.onJobPostingInterviewProcessAction(jobPostingInterviewProcessRequestModel, PageModeEnum.Edit);
        }
    };

    const columnsJobPostingInterviewProcess = [
        { key: "InterviewRoundName", text: "Interview Step", sort: false, searchable: false },
        { key: "InterviewRoundNumber", text: "Round", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false, formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
            <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsJobPostingInterviewProcess.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.jobPostingInterviewProcessList.map((row: InterviewProcessModel, index) => (
                                <TableRow>
                                    <TableCell>{row.interviewRoundName}</TableCell>
                                    <TableCell>{row.interviewRoundNumber}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { editJobPostingInterviewProcess(row); }} title="Edit">
                                            <i className="fa fa-edit grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default JobPostingInterviewProcessGridComponent;
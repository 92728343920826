import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminUserModel from "../../../models/admin/dashboard/response/AdminUserModel";
import AdminUserRequestModel from "../../../models/admin/dashboard/request/AdminUserRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface AdminUserGridProps {
    adminUsersList: AdminUserModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    nameFilterValue: string;
    onAdminUserAction: (row: AdminUserRequestModel, actionType: string) => void;
    onAdminUserDeleteAction: (row: AdminUserRequestModel) => void;
    onMapCompanyAction: (adminId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const AdminUserGridComponent = (props: AdminUserGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [nameFilter, setNameFilter] = useState<string>(props.nameFilterValue);
    const ActionFormatter = (
        cell: any,
        row: AdminUserModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <a onClick={() => { editAdminUser(row); }}>Edit</a>
                <a onClick={() => { deleteAdminUser(row); }}>Delete</a>
                <a onClick={() => { mapCompany(row); }}>Map Company</a>
            </div>
        );
    };

    const editAdminUser = (row: AdminUserModel) => {
        if (loggedInUser !== null) {
            let adminUserRequestModel: AdminUserRequestModel = {
                adminId: row.adminId,
                emailId: row.emailId,
                userName: row.emailId,
                mobileNumber: row.mobileNumber,
                name: row.name,
                userId: loggedInUser?.userId,
                roleId: row.roleId
            }
            props.onAdminUserAction(adminUserRequestModel, PageModeEnum.Edit);
        }
    };

    const deleteAdminUser = (row: AdminUserModel) => {
        if (loggedInUser !== null) {
            let adminUserRequestModel: AdminUserRequestModel = {
                adminId: row.adminId,
                emailId: row.emailId,
                userName: row.emailId,
                mobileNumber: row.mobileNumber,
                name: row.name,
                userId: loggedInUser?.userId,
                roleId: row.roleId
            }
            props.onAdminUserDeleteAction(adminUserRequestModel);
        }
    };

    const mapCompany = (row: AdminUserModel) => {
        props.onMapCompanyAction(row.adminId);
    };

    const columnsAdminUser = [
        { key: "Name", text: "Name", sort: true, searchable: true },
        { key: "EmailId", text: "Email Id", sort: true, searchable: false },
        { key: "MobileNumber", text: "Mobile No", sort: false, searchable: false },
        { key: "RoleName", text: "Role", sort: true, searchable: false },
        { key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setNameFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
            <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsAdminUser.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={nameFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.adminUsersList.map((skill: AdminUserModel, index) => (
                                <TableRow>
                                    <TableCell>{skill.name}</TableCell>
                                    <TableCell>{skill.emailId}</TableCell>
                                    <TableCell>{skill.mobileNumber}</TableCell>
                                    <TableCell>{skill.roleName}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { editAdminUser(skill); }} title="Edit">
                                            <i className="far fa-edit grid-icon blue"></i>
                                        </a>
                                        <a onClick={() => { deleteAdminUser(skill); }} title="Delete">
                                            <i className="fa fa-trash grid-icon red"></i>
                                        </a>
                                        <a onClick={() => { mapCompany(skill); }} title="Map Company">
                                            <i className="fa fa-check grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default AdminUserGridComponent;
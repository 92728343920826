import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import subUserService from "../../../services/company/sub-user-service";
import SubUserSearchInputModel from "../../../models/company/subUser/request/SubUserSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import SubUserModel from "../../../models/company/subUser/response/SubUserModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import SubUserPopUp from "./SubUserCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import SubUserRequestModel from "../../../models/company/subUser/request/SubUserRequestModel";
import SubUserGridComponent from "./SubUserGridComponent";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import IdNameModel from "../../../models/common/IdNameModel";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface SubUserProps {
    roles: IdNameModel[];
    departments: IdNameModel[];
    cities: IdNameModel[];
    clientLeads: IdNameModel[];
}

const SubUserComponent = (props: SubUserProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [subUserPagedList, setSubUserPagedList] = useState<PagedListModel<SubUserModel>>();
    const [subUserList, setSubUserList] = useState<SubUserModel[]>([]);
    const [refreshSubUser, setRefreshSubUser] = useState<number>(2);
    const [subUserNameFilter, setSubUserNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("Name");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getSubUsers(getSearchFilters(subUserNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (subUserFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: SubUserSearchInputModel = {
            companyId: "",
            subUserName: subUserFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getSubUsers = (inputData: SubUserSearchInputModel) => {
        if (loggedInUser?.profileId) {
            inputData.companyId = loggedInUser?.profileId;
            setRefreshSubUser(2);
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            subUserService.getSubUsers(inputData).then((res) => {
                modalUtils.closeModal();
                setSubUserList([]);
                setSubUserPagedList(res);
                setSubUserList(res.items);
                setRefreshSubUser(refreshSubUser * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const openSubUserSaveModel = (subUserRequestModel: SubUserRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add SubUser",
            content: (
                <SubUserPopUp
                    objSubUser={subUserRequestModel}
                    actionType={actionType}
                    roles={props.roles}
                    departments={props.departments}
                    cities={props.cities}
                    clientLeads={props.clientLeads}
                    onCloseSubUser={onCloseSubUserHandler}
                    onSubUserSave={onSubUserSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseSubUserHandler = () => {
        modalUtils.closeModal();
    };

    const onSubUserSaveHandler = (objSubUser: SubUserRequestModel) => {
        setRefreshSubUser(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        subUserService.saveSubUser(objSubUser).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getSubUsers(getSearchFilters(subUserNameFilter, page, pageSize, sortColumn, sortDirection));
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onSubUserActionHandler = (obj: SubUserRequestModel, actionType: string) => {
        if (actionType === PageModeEnum.Edit) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            subUserService.getSubUserById(obj.subUserId).then((res) => {
                modalUtils.closeModal();
                openSubUserSaveModel(res.data, actionType);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            openSubUserSaveModel(obj, actionType);
        }
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getSubUsers(getSearchFilters(subUserNameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getSubUsers(getSearchFilters(subUserNameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getSubUsers(getSearchFilters(subUserNameFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setSubUserNameFilter(filterValue);
        setPage(0);
        getSubUsers(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    const addSubUser = () => {
        if (loggedInUser !== null) {
            let subUserRequestModel: SubUserRequestModel = {
                subUserId: GuidEnum.Empty,
                name: "",
                userName: "",
                emailId: "",
                mobileNumber: 0,
                departmentId: "",
                cityId: 0,
                roleId: 0,
                reportingToId: null,
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            onSubUserActionHandler(subUserRequestModel, PageModeEnum.Create);
        }
    }

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getSubUsers(getSearchFilters(subUserNameFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addSubUser}> + Add SubUser</a>
            </div>
            <div className="card-body">
                {refreshSubUser > 2 &&
                    <SubUserGridComponent
                        key={refreshSubUser}
                        subUserList={subUserList}
                        totalSize={subUserPagedList?.totalCount ? subUserPagedList?.totalCount : subUserList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        subUserFilterValue={subUserNameFilter}
                        onSubUserAction={onSubUserActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default SubUserComponent;
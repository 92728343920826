import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import AdminAllProfilesComponent from "../../../components/admin/company/admin-all-profiles/AdminAllProfilesComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import adminCompanyService from "../../../services/admin/admin-company-service";

const AdminAllProfiles = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [companyList, setCompaniesList] = useState<IdNameModel[]>([]);
    const [refreshCompany, setRefreshCompany] = useState<number>(2);

    useEffect(() => {
        fillCompanies();
    }, []);

    const fillCompanies = () => {
        setRefreshCompany(2);
        if (loggedInUser && loggedInUser.roleId === RoleEnum.Administrator.toString()) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            masterDataService.loadCompanies().then((res) => {
                modalUtils.closeModal();
                setCompaniesList(res.items);
                setRefreshCompany(refreshCompany * 5);
            }).catch((error) => {
                setRefreshCompany(refreshCompany * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            adminCompanyService.getCompaniesForIAIRecruiter(loggedInUser ? loggedInUser.profileId : "").then((res) => {
                modalUtils.closeModal();
                setCompaniesList(res.items);
                setRefreshCompany(refreshCompany * 5);
            }).catch((error) => {
                setRefreshCompany(refreshCompany * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> All Profiles </h4>
            </div>
            {refreshCompany > 2 &&
                <AdminAllProfilesComponent companiesList={companyList} />
            }
        </Layout>
    );
};
export default AdminAllProfiles;
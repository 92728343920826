import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import UpdateInterviewRequestModel from "../../../../models/admin/interviews/request/UpdateInterviewRequestModel";
import IdNameModel from "../../../../models/common/IdNameModel";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import alertUtils from "../../../../utils/toaster-utils";
import dateTimeUtils from "../../../../utils/date-utils";

interface UpdateInterviewProps {
    objInterview: UpdateInterviewRequestModel;
    interviewerList: IdNameModel[];
    timeSlotList: IdNameModel[];
    onRescheduleInterviewAction: (objInterview: UpdateInterviewRequestModel) => void;
    onCloseUpdateInterview: () => void;
};

const UpdateInterviewComponent = (props: UpdateInterviewProps) => {
    const [interviewDate, setInterviewDate] = useState("" as any);

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<UpdateInterviewRequestModel>({
        mode: "onChange",
        defaultValues: props.objInterview
    });

    const selectEndDateHanler = (date: any) => {
        setInterviewDate(date);
    };

    const onSubmit = (formData: UpdateInterviewRequestModel) => {
        if (!interviewDate) {
            alertUtils.showError("Interview date is mandatory.");
        } else {
            formData.interviewDate = dateTimeUtils.addDays(interviewDate, 1);
            props.onRescheduleInterviewAction(formData);
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label">Interview Date: <span className="required">*</span></label>
                            <DateTimePicker
                                selectsStart
                                onChange={selectEndDateHanler}
                                selected={interviewDate}
                                timeInputLabel="Time:"
                                dateFormat="yyyy-MMM-dd"
                                className="form-control"
                                minDate={dateTimeUtils.addDays(new Date(), 1)}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Time Slot: </label>
                                    <select className="form-control form-select" {...register("timeSlotId", { required: true })}>
                                        <option value="">Select Timeslot</option>
                                        {props.timeSlotList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.timeSlotId && errors.timeSlotId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Interviewer: </label>
                                    <select className="form-control form-select" {...register("interviewerId", { required: true })}>
                                        <option value="">Select Interviewer</option>
                                        {props.interviewerList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.guId}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.interviewerId && errors.interviewerId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Update</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseUpdateInterview()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default UpdateInterviewComponent;
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import jobPostingService from "../../../services/company/job-posting-service";
import JobPostingSearchInputModel from "../../../models/company/jobPosting/request/JobPostingSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import JobPostingPopUp from "./JobPostingCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import JobPostingRequestModel from "../../../models/company/jobPosting/request/JobPostingRequestModel";
import JobPostingGridComponent from "./JobPostingGridComponent";
import JobPostingModel from "../../../models/company/jobPosting/response/JobPostingModel";
import IdNameModel from "../../../models/common/IdNameModel";
import CompanyConfigurationModel from "../../../models/company/CompanyConfigurationModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import JobPostingVendorPopUp from "./JobPostingVendorsComponent";
import JobPostingVendorRequestModel from "../../../models/company/jobPosting/request/JobPostingVendorRequestModel";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import { InterviewTypeEnum } from "../../../models/enums/InterviewTypeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import JobPostingSkillRequestModel from "../../../models/company/jobPosting/request/JobPostingSkillRequestModel";
import ViewEscalationComponent from "../add-profiles/ViewEscalationComponent";

interface JobPostingProps {
    companyConfiguration: CompanyConfigurationModel;
    jobTypes: IdNameModel[];
    assessmentTypes: IdNameModel[];
    domains: IdNameModel[];
    primarySkills: IdNameModel[];
    experiences: IdNameModel[];
    cities: IdNameModel[];
    designations: IdNameModel[];
    salaries: IdNameModel[];
    escalationStatusList: IdNameModel[];
    profileId: string;
    userId: string;
}

const JobPostingComponent = (props: JobPostingProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [jobPostingPagedList, setJobPostingPagedList] = useState<PagedListModel<JobPostingModel>>();
    const [companyConfiguration, setCompanyConfiguration] = useState<CompanyConfigurationModel>(props.companyConfiguration);
    const [jobPostingList, setJobPostingList] = useState<JobPostingModel[]>([]);
    const [jobTypes, setJobTypes] = useState<IdNameModel[]>(props.jobTypes);
    const [assessmentTypes, setAssessmentTypes] = useState<IdNameModel[]>(props.assessmentTypes);
    const [domains, setDomains] = useState<IdNameModel[]>(props.domains);
    const [primarySkills, setPrimarySkills] = useState<IdNameModel[]>(props.primarySkills);
    const [experiences, setExperiences] = useState<IdNameModel[]>(props.experiences);
    const [cities, setCities] = useState<IdNameModel[]>(props.cities);
    const [designations, setDesignations] = useState<IdNameModel[]>(props.designations);
    const [salaries, setSalaries] = useState<IdNameModel[]>(props.salaries);
    const [refreshJobPosting, setRefreshJobPosting] = useState<number>(2);
    const [jobCodeFilter, setJobCodeFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("JobCode");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getJobPostings(getSearchFilters(jobCodeFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (jobCodeFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: JobPostingSearchInputModel = {
            userId: props.profileId,
            companyId: props.profileId,
            jobCode: jobCodeFilter,
            statusId: null,
            primarySkillId: null,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getJobPostings = (inputData: JobPostingSearchInputModel) => {
        setRefreshJobPosting(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        jobPostingService.getJobPostings(inputData).then((res) => {
            modalUtils.closeModal();
            setJobPostingList([]);
            setJobPostingPagedList(res);
            setJobPostingList(res.items);
            setRefreshJobPosting(refreshJobPosting * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const openJobPostingSaveModel = (jobPostingRequestModel: JobPostingRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add JobPosting",
            content: (
                <JobPostingPopUp
                    objJobPosting={jobPostingRequestModel}
                    actionType={actionType}
                    jobTypes={jobTypes}
                    assessmentTypes={assessmentTypes}
                    domains={domains}
                    primarySkills={primarySkills}
                    experiences={experiences}
                    cities={cities}
                    designations={designations}
                    salaries={salaries}
                    onCloseJobPosting={onCloseJobPostingHandler}
                    onJobPostingSave={onJobPostingSaveHandler} />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseJobPostingHandler = () => {
        modalUtils.closeModal();
    };

    const onJobPostingSaveHandler = (objJobPosting: JobPostingRequestModel) => {
        setRefreshJobPosting(2);
        objJobPosting.companyId = companyConfiguration.companyId;
        objJobPosting.recruiterId = props.profileId;
        objJobPosting.userId = props.userId;
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        jobPostingService.saveJobPosting(objJobPosting).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getJobPostings(getSearchFilters(jobCodeFilter, page, pageSize, sortColumn, sortDirection));
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onJobPostingActionHandler = (obj: JobPostingRequestModel, actionType: string) => {
        if (actionType === PageModeEnum.Edit) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            jobPostingService.getJobPostingById(obj.requirementId).then((res) => {
                modalUtils.closeModal();
                openJobPostingSaveModel(res.data, actionType);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            openJobPostingSaveModel(obj, actionType);
        }
    };

    const onVendorSaveHandler = (objVendor: JobPostingVendorRequestModel) => {
        modalUtils.closeModal();
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        jobPostingService.saveJobPostingVendor(objVendor).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                onJobPostingVendorActionHandler(objVendor.requirementId);
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onCloseVendorHandler = () => {
        modalUtils.closeModal();
    };

    const onJobPostingVendorActionHandler = (requirementId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        jobPostingService.getRequirementVendors(requirementId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Add Vendors",
                content: (
                    <JobPostingVendorPopUp
                        requirementVendors={res.data.requirementVendors}
                        vendorForRequiremrntList={res.data.vendorsForRequirement}
                        requirementId={requirementId}
                        onVendorSave={onVendorSaveHandler}
                        onCloseVendor={onCloseVendorHandler}
                    />
                ),
                size: ModalSize.XL,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onViewEscalationsHandler = (requirementId: string) => {
        const config: GeneralPoupConfig = {
            title: "Escalations",
            content: (
                <ViewEscalationComponent
                    requirementId={requirementId}
                    statusList={props.escalationStatusList} />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getJobPostings(getSearchFilters(jobCodeFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getJobPostings(getSearchFilters(jobCodeFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getJobPostings(getSearchFilters(jobCodeFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setJobCodeFilter(filterValue);
        setPage(0);
        getJobPostings(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    const addJobPosting = () => {
        if (loggedInUser !== null) {
            let baseSkillDetails: JobPostingSkillRequestModel[] = [];
            let minSkills: number = 3;
            for (let i = 1; i <= minSkills; i++) {
                let skill: JobPostingSkillRequestModel = {
                    requirementSkillId: GuidEnum.Empty,
                    requirementId: GuidEnum.Empty,
                    secondarySkillId: null,
                    secondarySkillNumber: i,
                    isCommentsRequired: true,
                    requirementInterviewProcessId: GuidEnum.Empty,
                    recruiterId: GuidEnum.Empty,
                    userId: GuidEnum.Empty
                };
                baseSkillDetails.push(skill);
            }
            let jobPostingRequestModel: JobPostingRequestModel = {
                requirementId: GuidEnum.Empty,
                companyId: loggedInUser.profileId,
                recruiterId: loggedInUser.profileId,
                jobTypeId: null,
                jobCode: "",
                jobDescription: "",
                keyResponsibilities: "",
                domainId: null,
                additionalSkills: "",
                primarySkillId: null,
                minExperienceId: null,
                maxExperienceId: null,
                startDate: new Date(),
                endDate: new Date,
                cityId: null,
                designationId: "",
                highestPay: "",
                interviewTypeId: parseInt(InterviewTypeEnum.Video.toString()),
                assessmentName: "",
                scheduleZoomInterview: true,
                addInterviewProcess: false,
                statusId: null,
                sendMailToRecruiter: true,
                captureScreenShot: true,
                minRatingForSelection: 0,
                isCalibrationCallCompleted: false,
                isClientApproved: false,
                panelsAvailable: false,
                jobPostingSkillRequestModel: baseSkillDetails,
                userId: loggedInUser.userId
            }
            onJobPostingActionHandler(jobPostingRequestModel, PageModeEnum.Create);
        }
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getJobPostings(getSearchFilters(jobCodeFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addJobPosting}> + Add JobPosting</a>
            </div>
            <div className="card-body">
                {refreshJobPosting > 2 &&
                    <JobPostingGridComponent
                        key={refreshJobPosting}
                        jobPostingList={jobPostingList}
                        totalSize={jobPostingPagedList?.totalCount ? jobPostingPagedList?.totalCount : jobPostingList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        jobCodeFilterValue={jobCodeFilter}
                        onJobPostingAction={onJobPostingActionHandler}
                        onJobPostingVendorAction={onJobPostingVendorActionHandler}
                        onViewEscalations={onViewEscalationsHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default JobPostingComponent;
import React, { useState, useEffect } from "react";
import AddProfilesGridModel from "../../../models/company/addProfiles/response/AddProfilesGridModel";
import AddProfilesInterviewRoundsModel from "../../../models/company/addProfiles/response/AddProfilesInterviewRoundsModel";
import DataGrid from "../../common/table/DataGrid";

interface ScheduleInterviewRoundsProps {
    objAddProfile: AddProfilesGridModel;
    interviewRoundsList: AddProfilesInterviewRoundsModel[];
    onCloseInterviewRounds: () => void;
    onInterviewRoundSelect: (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => void;
    onInterviewRatingDetails: (objInterviewRound: AddProfilesInterviewRoundsModel, objAddProfile: AddProfilesGridModel) => void;
}

const ScheduleInterviewRoundsComponent = (props: ScheduleInterviewRoundsProps) => {

    const ActionFormatter = (
        cell: any,
        row: AddProfilesInterviewRoundsModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">
                <button className="btn btn-primary btn-form" onClick={() => { scheduleInterview(row); }} disabled={!row.isEnabled}>Schedule</button>
                {row.isActive &&
                    <button className="btn btn-primary btn-form" onClick={() => { reScheduleInterview(row); }}>Re Schedule</button>
                }
                {row.isRated &&
                    <button className="btn btn-primary btn-form" onClick={() => { ratingDetails(row); }}>Rating</button>
                }
            </div>
        );
    };

    const scheduleInterview = (row: AddProfilesInterviewRoundsModel) => {
        props.onInterviewRoundSelect(row, props.objAddProfile);
    };

    const reScheduleInterview = (row: AddProfilesInterviewRoundsModel) => {
        props.onInterviewRoundSelect(row, props.objAddProfile);
    };

    const ratingDetails = (row: AddProfilesInterviewRoundsModel) => {
        props.onInterviewRatingDetails(row, props.objAddProfile);
    };

    const columnsInterviewRound = [
        { dataField: "roundName", text: "Interview Rounds" },
        { dataField: "interviewDate", text: "Interview Date" },
        { dataField: "totalRating", text: "Rating" },
        { dataField: "actions", text: "", formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <DataGrid
                    uniqueField="emailId"
                    data={props.interviewRoundsList}
                    columns={columnsInterviewRound}
                    disablePagination={true}
                    filterRequired={false}
                />
            </div>
        </div>
    );
};
export default ScheduleInterviewRoundsComponent;
import React, { useState, useEffect } from "react";
import Layout from "../../../components/layout/Layout";
import TodayFollowupComponent from "../../../components/admin/candidate/today-followups/TodayFollowupComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";

const TodaysFollowups = () => {
    const [status, setStatus] = useState<IdNameModel[]>([]);
    const [refreshStatus, setRefreshStatus] = useState<number>(2);

    useEffect(() => {
        getStatus();
    }, []);

    const getStatus = () => {
        setRefreshStatus(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadStatus().then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setStatus(res.items);
            } else {
                alertUtils.showError("Unable to load Status");
            }
            setRefreshStatus(refreshStatus * 5);
        }).catch((error) => {
            setRefreshStatus(refreshStatus * 5);
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Status");
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header">Today's Follow-ups</h4>
            </div>
            {refreshStatus > 2 &&
                <TodayFollowupComponent statusList={status} />
            }
        </Layout>
    );
};
export default TodaysFollowups;
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import ReleaseCandidatesModel from "../../../../models/admin/company/response/ReleaseCandidatesModel";
import alertUtils from "../../../../utils/toaster-utils";
import ReleaseCandidatesSeachInputModel from "../../../../models/admin/company/request/ReleaseCandidatesSeachInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminCompanyService from "../../../../services/admin/admin-company-service";
import PagedListModel from "../../../../models/common/PagedListModel";
import ReleaseCandidateGridComponent from "./ReleaseCandidateGridComponent";
import ReleaseCandidatesRequestModel from "../../../../models/admin/company/request/ReleaseCandidatesRequestModel";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";

interface ReleaseCandidatesProps {
    companiesList: IdNameModel[];
    primarySkillList: IdNameModel[];
};

const ReleaseCandidatesComponent = (props: ReleaseCandidatesProps) => {
    const [candidatesPagedList, setCandidatesPagedList] = useState<PagedListModel<ReleaseCandidatesModel>>();
    const [candidatesList, setCandidatesList] = useState<ReleaseCandidatesModel[]>([]);
    const [refreshCandidates, setRefreshCandidates] = useState<number>(2);
    const [companySearch, setCompanySearch] = useState<string>("");
    const [primarySkillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [emailIdFilter, setEmailIdFilter] = useState<string>("");
    const [jobCodeFilter, setJobCodeFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        clearSearch();
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const clearSearch = () => {
        setCandidatesList([]);
        setPrimarySkillSearch(0);
        setCompanySearch("");
        setRefreshCandidates(2);
    };

    const getSearchFilters = (nameFilter: string, emailIdFilter: string, jobCode: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: ReleaseCandidatesSeachInputModel = {
            primarySkillId: primarySkillSearch,
            companyId: companySearch,
            candidateName: nameFilter,
            emailId: emailIdFilter,
            jobCode: jobCode,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getReleaseCandidates = (inputData: ReleaseCandidatesSeachInputModel) => {
        if (companySearch === "" || primarySkillSearch === 0) {
            alertUtils.showWarning("Please select Company, Primary Skill");
            return;
        }
        setRefreshCandidates(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getCandidatesToBeReleased(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidatesList([]);
            setCandidatesPagedList(res);
            setCandidatesList(res.items);
            setRefreshCandidates(refreshCandidates * 5);
        }).catch((error) => {
            setRefreshCandidates(refreshCandidates * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onReleaseCandidateActionHandler = (objReleaseCandidate: ReleaseCandidatesRequestModel) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.releaseCandidate(objReleaseCandidate).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getReleaseCandidates(getSearchFilters(nameFilter, emailIdFilter, jobCodeFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getReleaseCandidates(getSearchFilters(nameFilter, emailIdFilter, jobCodeFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getReleaseCandidates(getSearchFilters(nameFilter, emailIdFilter, jobCodeFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getReleaseCandidates(getSearchFilters(nameFilter, emailIdFilter, jobCodeFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        if (filterColumn === "CandidateName") {
            setNameFilter(filterValue);
            getReleaseCandidates(getSearchFilters(filterValue, emailIdFilter, jobCodeFilter, 0, pageSize, sortColumn, sortDirection));
        } else if (filterColumn === "EmailId") {
            setEmailIdFilter(filterValue);
            getReleaseCandidates(getSearchFilters(nameFilter, filterValue, jobCodeFilter, 0, pageSize, sortColumn, sortDirection));
        } else {
            setJobCodeFilter(filterValue);
            getReleaseCandidates(getSearchFilters(nameFilter, emailIdFilter, filterValue, 0, pageSize, sortColumn, sortDirection));
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Company</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setCompanySearch(e.target.value)}>
                            <option value="">Select Company</option>
                            {props.companiesList.map((item, index) => {
                                return (
                                    <option key={index} value={item.guId}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="">Select Company</option>
                            {props.primarySkillList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getReleaseCandidates(getSearchFilters(nameFilter, emailIdFilter, jobCodeFilter, page, pageSize, sortColumn, sortDirection))}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {candidatesList.length > 0 && refreshCandidates > 2 &&
                    <ReleaseCandidateGridComponent
                        key={refreshCandidates}
                        candidateList={candidatesList}
                        totalSize={candidatesPagedList?.totalCount ? candidatesPagedList?.totalCount : candidatesList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        nameFilterValue={nameFilter}
                        emailIdFilterValue={emailIdFilter}
                        jobCodeFilterValue={jobCodeFilter}
                        onReleaseCandidateAction={onReleaseCandidateActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default ReleaseCandidatesComponent;
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { OBJECT_NAME_RULE, OBJECT_DESCRIPTION_RULE, EMAIL_RULE, MOBILE_NUMBER_RULE } from "../../../utils/validation-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import AddProfilesRequestModel from "../../../models/company/addProfiles/request/AddProfilesRequestModel";

interface AddCandidateProps {
    objAddProfile: AddProfilesRequestModel;
    vendorList: IdNameModel[];
    onCloseAddProfile: () => void;
    onAddProfileSave: (objAddProfile: AddProfilesRequestModel, resume: any) => void;
}

const AddCandidateComponent = (props: AddCandidateProps) => {
    const [resume, setResume] = useState<any>();
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<AddProfilesRequestModel>({
        mode: "onChange",
        defaultValues: props.objAddProfile
    });

    const onSubmit = (formData: AddProfilesRequestModel) => {
        formData.emailId = formData.userName;
        props.onAddProfileSave(formData, resume);
    };

    const hiddenFileInput: any = React.useRef();
    const browseFileHandler = (event: any) => {
        event.preventDefault();
        hiddenFileInput.current.click();
    };

    const clearFile = () => {
        hiddenFileInput.current.value = "";
    };

    function uploadCandidateResume(e: any): void {
        setResume(e);        
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("name", OBJECT_NAME_RULE)} placeholder="Candidate Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.name && errors.name.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Email Id / UserName: </label>
                                    <input type="email" className="form-control" {...register("userName", EMAIL_RULE)} placeholder="Email/User Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.userName && errors.userName.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Mobile No: <span className="required">*</span> </label>
                                    <input type="tel" className="form-control" {...register("mobileNumber", MOBILE_NUMBER_RULE)} placeholder="Mobile Number" autoComplete="off" />
                                    <span className="error">
                                        {errors.mobileNumber && errors.mobileNumber.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Resume: <span className="required">*</span></label>
                                    <input className="form-control" type="file" ref={hiddenFileInput}
                                        onChange={uploadCandidateResume} accept='.doc,.docx,application/pdf'></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Vendor: </label>
                                    <select className="form-control form-select" {...register("vendorId", { required: true })}>
                                        <option value="">Select Vendor</option>
                                        {props.vendorList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.guId}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.vendorId && errors.vendorId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseAddProfile()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddCandidateComponent;
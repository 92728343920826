import { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import IdNameModel from "../../../models/common/IdNameModel";
import CandidateRelatedJobRequirementsGridModel from "../../../models/candidate/response/CandidateRelatedJobRequirementsGridModel";
import candidateService from "../../../services/candidate/candidate-service";
import CandidateRelatedJobRequirementsSearchInputModel from "../../../models/candidate/request/CandidateRelatedJobRequirementsSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import alertUtils from "../../../utils/toaster-utils";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CandidateNewJobsGridComponent from "./CandidateNewJobsGridComponent";
import CandidateAppliedJobsGridComponent from "./CandidateAppliedJobsGridComponent";
import CandidateRelatedJobRequirementRequestModel from "../../../models/candidate/request/CandidateRelatedJobRequirementRequestModel";
import { Accordion, Card, Button } from "react-bootstrap";
import masterDataService from "../../../services/master-data-service";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import PagedListModel from "../../../models/common/PagedListModel";

const CandidateJobsComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [newJobsList, setNewJobsList] = useState<CandidateRelatedJobRequirementsGridModel[]>([]);
    const [newJobsPagedList, setNewJobsPagedList] = useState<PagedListModel<CandidateRelatedJobRequirementsGridModel>>();
    const [refreshNewJobs, setRefreshNewJobs] = useState<number>(2);
    const [appliedJobsList, setAppliedJobsList] = useState<CandidateRelatedJobRequirementsGridModel[]>([]);
    const [appliedJobsPagedList, setAppliedJobsPagedList] = useState<PagedListModel<CandidateRelatedJobRequirementsGridModel>>();
    const [refreshAppliedJobs, setRefreshAppliedJobs] = useState<number>(2);
    const [primarySkills, setPrimarySkills] = useState<IdNameModel[]>([]);
    const [companies, setCompanies] = useState<IdNameModel[]>([]);
    const [designations, setDesignations] = useState<IdNameModel[]>([]);
    const [primarySkillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [companySearch, setCompanySearch] = useState<string>("");
    const [designationSearch, setDesignationSearch] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("JobCode");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);
    const [pageApplied, setPageApplied] = useState<number>(PagingEnum.page);
    const [pageSizeApplied, setPageSizeApplied] = useState<number>(PagingEnum.pageSize);

    useEffect(() => {
        loadPageSizes();
        loadCompanies();
        loadPrimarySkills();
        getNewJobs(getSearchFilters(page, pageSize, sortColumn, sortDirection));
        getAppliedJobs(getSearchFiltersApplied(pageApplied, pageSizeApplied, sortColumn, sortDirection));
    }, []);

    const loadPrimarySkills = () => {
        masterDataService.loadPrimarySkills().then((res) => {
            if (res.isSuccess) {
                setPrimarySkills(res.items);
            } else {
                alertUtils.showError("Unable to load Primary skills");
            }
        }).catch((error) => {
            alertUtils.showError("Erorr fetching Primary skills");
        });
    };

    const loadCompanies = () => {
        masterDataService.loadCompanies().then((res) => {
            if (res.isSuccess) {
                setCompanies(res.items);
            } else {
                alertUtils.showError("Unable to load Primary skills");
            }
        }).catch((error) => {
            alertUtils.showError("Erorr fetching Primary skills");
        });
    };

    const loadDesignations = (companyId: string) => {
        setCompanySearch(companyId);
        if (companyId !== "") {
            masterDataService.loadDesignation(companyId).then((res) => {
                if (res.isSuccess) {
                    setDesignations(res.items);
                } else {
                    alertUtils.showError("Unable to load Primary skills");
                }
            }).catch((error) => {
                alertUtils.showError("Erorr fetching Primary skills");
            });
        } else {
            setDesignations([]);
        }
    };

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: CandidateRelatedJobRequirementsSearchInputModel = {
            candidateId: "",
            designationId: designationSearch === "" ? null : designationSearch,
            companyId: companySearch === "" ? null : companySearch,
            primaySkillId: primarySkillSearch === 0 ? null : primarySkillSearch,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getSearchFiltersApplied = (page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: CandidateRelatedJobRequirementsSearchInputModel = {
            candidateId: "",
            designationId: null,
            companyId: null,
            primaySkillId: null,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getNewJobs = (inputData: CandidateRelatedJobRequirementsSearchInputModel) => {
        if (loggedInUser?.profileId) {
            setRefreshNewJobs(2);
            inputData.candidateId = loggedInUser?.profileId;
            candidateService.getCandidateRelatedJobRequirement(inputData).then((res) => {
                setNewJobsList([]);
                setNewJobsList(res.items);
                setNewJobsPagedList(res);
                setRefreshNewJobs(refreshNewJobs * 5);
            }).catch((error) => {
                alertUtils.showError(error);
            });
        } else {
            setRefreshNewJobs(refreshNewJobs * 5);
        }
    };

    const getAppliedJobs = (inputData: CandidateRelatedJobRequirementsSearchInputModel) => {
        if (loggedInUser?.profileId) {
            setRefreshAppliedJobs(2);
            inputData.candidateId = loggedInUser?.profileId;
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            candidateService.getCandidateAppliedJobRequirements(inputData).then((res) => {
                modalUtils.closeModal();
                setAppliedJobsList([]);
                setAppliedJobsPagedList(res);
                setAppliedJobsList(res.items);
                setRefreshAppliedJobs(refreshNewJobs * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            setRefreshAppliedJobs(refreshNewJobs * 5);
        }
    };

    const onApplyActionHandler = (row: CandidateRelatedJobRequirementRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        candidateService.saveCandidateJobApplication(row).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
                getNewJobs(getSearchFilters(page, pageSize, sortColumn, sortDirection));
                getAppliedJobs(getSearchFiltersApplied(pageApplied, pageSizeApplied, sortColumn, sortDirection));
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getNewJobs(getSearchFilters(newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getNewJobs(getSearchFilters(PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onAppliedPageChangeActionHandler = (newPage: number) => {
        setPageApplied(newPage);
        getAppliedJobs(getSearchFiltersApplied(newPage, pageSizeApplied, sortColumn, sortDirection));
    };

    const onAppliedPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSizeApplied(newPageSize);
        setPageApplied(PagingEnum.page);
        getAppliedJobs(getSearchFiltersApplied(PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <Tabs defaultActiveKey="NewJobsTab" transition={false} id="noanim-tab-example" className="nav nav-tabs tab-index-page">
                    <Tab eventKey="NewJobsTab" title="NEW JOBS" className="tab-content-index-page">
                        <div className="row">
                            <div className="col-md-12">
                                <Accordion className="filter-accordian">
                                    <Card className="card-border-bottom filter-card">
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                Apply Filter
                                                <i className="fa fa-angle-down"></i>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>Primary Skill</label>
                                                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                                                            <option value="0">All</option>
                                                            {primarySkills.map((status, index) => {
                                                                return (
                                                                    <option key={index} value={status.id}> {status.name} </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>Company</label>
                                                        <select className="mb-3 form-control form-select" onChange={(e) => loadDesignations(e.target.value)}>
                                                            <option value="">All</option>
                                                            {companies.map((status, index) => {
                                                                return (
                                                                    <option key={index} value={status.guId}> {status.name} </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>Role</label>
                                                        <select className="mb-3 form-control form-select" onChange={(e) => setDesignationSearch(e.target.value)}>
                                                            <option value="">All</option>
                                                            {designations.map((status, index) => {
                                                                return (
                                                                    <option key={index} value={status.guId}> {status.name} </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-warning" type="submit" onClick={() => getNewJobs(getSearchFilters(page, pageSize, sortColumn, sortDirection))}>
                                                            <i className="icon-search">Search</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="col-md-12">
                                {refreshNewJobs > 2 &&
                                    <CandidateNewJobsGridComponent
                                        key={refreshNewJobs}
                                        requirementList={newJobsList}
                                        totalSize={newJobsPagedList?.totalCount ? newJobsPagedList?.totalCount : newJobsList.length}
                                        currentPage={page + 1}
                                        pagesize={pageSize}
                                        sortColumn={sortColumn}
                                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                                        pageSizesList={pageSizesList}
                                        onApply={onApplyActionHandler}
                                        onPageChangeAction={onPageChangeActionHandler}
                                        onPageSizeChangeAction={onPageSizeChangeActionHandler} />
                                }
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="AppliedJobsTab" title="APPLIED JOBS" className="tab-content-index-page">
                        {refreshAppliedJobs > 2 &&
                            <CandidateAppliedJobsGridComponent
                                key={refreshAppliedJobs}
                                requirementList={newJobsList}
                                totalSize={appliedJobsPagedList?.totalCount ? appliedJobsPagedList?.totalCount : appliedJobsList.length}
                                currentPage={pageApplied + 1}
                                pagesize={pageSizeApplied}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                                pageSizesList={pageSizesList}
                                onPageChangeAction={onAppliedPageChangeActionHandler}
                                onPageSizeChangeAction={onAppliedPageSizeChangeActionHandler} />
                        }
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};
export default CandidateJobsComponent;
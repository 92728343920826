import { useState, MouseEvent } from "react";
import ZoomRecordingsModel from "../../../models/company/allProfiles/response/ZoomRecordingsModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface ZoomRecordingsProps {
    recordingsList: ZoomRecordingsModel[];
};

const ZoomRecordingsComponent = (props: ZoomRecordingsProps) => {
    const columnsRecordings = [
        { Key: "RecordingURL", text: "Recording URL", sort: false, searchable: false },
        { Key: "Passcode", text: "Passcode", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsRecordings.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.recordingsList.map((recording: ZoomRecordingsModel, index) => (
                                <TableRow>                                                                       
                                    <TableCell>
                                        {<a href={recording.recordingURL} target="_blank" title={recording.recordingURL}>Join Meeting</a>}
                                    </TableCell>
                                    <TableCell>{recording.passcode}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default ZoomRecordingsComponent;
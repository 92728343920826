import React, { useState, useEffect } from "react";
import InterviewerProfileModel from "../../../models/interviewer/request/InterviewerProfileModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface InterviewerProfileGridProps {
    profilesList: InterviewerProfileModel[];
    onProfileAction: (row: InterviewerProfileModel, actionType: string) => void;
}

const InterviewerProfileGridComponent = (props: InterviewerProfileGridProps) => {

    const editProfile = (row: InterviewerProfileModel) => {
        props.onProfileAction(row, "Edit");
    };

    const columnsInterviewerProfile = [
        { key: "ProfileName", text: "ProfileName", sort: false, searchable: false },
        { key: "PrimarySkillName", text: "Primary Skill", sort: false, searchable: false },
        { key: "AdditionalSkills", text: "SecondarySkills", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsInterviewerProfile.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.profilesList.map((row: InterviewerProfileModel, index) => (
                                <TableRow>
                                    <TableCell>{row.profileName}</TableCell>
                                    <TableCell>{row.primarySkillName}</TableCell>
                                    <TableCell>
                                        <div title={row.interviewerSkills.map(x => x.secondarySkillName).toString()}>
                                            {row.interviewerSkills.map(x => x.secondarySkillName).toString()}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <a onClick={() => { editProfile(row); }} title="Edit">
                                            <i className="fa fa-edit grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default InterviewerProfileGridComponent;
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination } from "@mui/material";
import React, { useState } from "react";
import CandidateStatusModel from "../../../../models/admin/candidate/response/CandidateStatusModel";
import CanidateStatusRequestModel from "../../../../models/admin/candidate/request/CanidateStatusRequestModel";
import { GuidEnum } from "../../../../models/enums/GuidEnum";

interface FollowupGridProps {
    followupList: CandidateStatusModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    candidateNameFilterValue: string;
    onUpdateFollowupAction: (objCandidate: CanidateStatusRequestModel) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
    onCandidateViewHistory: (candidateId: string) => void;
}

const TodayFollowupGridComponent = (props: FollowupGridProps) => {
    const [candidateNameFilter, setcandidateNameFilter] = useState<string>(props.candidateNameFilterValue);

    const updateFollowup = (candidate: CandidateStatusModel) => {
        let objCandidate: CanidateStatusRequestModel = {
            candidateId: candidate.candidateId,
            statusId: candidate.statusId,
            followUpDate: candidate.followUpDate,
            comments: candidate.comments,
            profileId: null,
            userId: GuidEnum.Empty
        };
        props.onUpdateFollowupAction(objCandidate);
    };

    const viewHistory = (candidateId: string) => {
        props.onCandidateViewHistory(candidateId);
    };

    const columnsFollowups = [
        { key: "CandidateName", text: "Candidate Name", sort: true, searchable: true },
        { key: "EmailId", text: "Email Id", sort: true, searchable: false },
        { key: "MobileNo", text: "Mobile No", sort: true, searchable: false },
        { key: "Status", text: "Status", sort: true, searchable: false },
        { key: "FollowUpDate", text: "Follow Up Dt", sort: true, searchable: false },
        { key: "CompanyName", text: "Company Name", sort: false, searchable: false },
        { key: "RecruiterName", text: "Jobcode", sort: false, searchable: false },
        { key: "CreatedDate", text: "Updated On", sort: false, searchable: false },
        { key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setcandidateNameFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsFollowups.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={candidateNameFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.followupList.map((candidate: CandidateStatusModel, index) => (
                                <TableRow key={index}>
                                    <TableCell>{candidate.candidateName}</TableCell>
                                    <TableCell>{candidate.emailId}</TableCell>
                                    <TableCell>{candidate.mobileNumber}</TableCell>
                                    <TableCell>{candidate.statusName}</TableCell>
                                    <TableCell>{candidate.followUpDateString}</TableCell>
                                    <TableCell>{candidate.companyName}</TableCell>
                                    <TableCell>{candidate.recruiterName}</TableCell>
                                    <TableCell>{candidate.createdDate}</TableCell>
                                    <TableCell>
                                        <TableCell>
                                            <a onClick={() => { updateFollowup(candidate); }} title="Edit">
                                                <i className="fa fa-check grid-icon blue"></i>
                                            </a>
                                            <a onClick={() => { viewHistory(candidate.candidateId); }} title="View History">
                                                <i className="fa fa-history grid-icon blue"></i>
                                            </a>
                                        </TableCell>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default TodayFollowupGridComponent;
import { useState, MouseEvent } from "react";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import SubUserModel from "../../../models/company/subUser/response/SubUserModel";
import SubUserRequestModel from "../../../models/company/subUser/request/SubUserRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface SubUserGridProps {
    subUserList: SubUserModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    subUserFilterValue: string;
    onSubUserAction: (row: SubUserRequestModel, actionType: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const SubUserGridComponent = (props: SubUserGridProps) => {
    const [subUserFilter, setSubUserFilter] = useState<string>(props.subUserFilterValue);
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);    

    const editSubUser = (row: SubUserModel) => {
        if (loggedInUser !== null) {
            let subUserRequestModel: SubUserRequestModel = {
                subUserId: row.subUserId,
                name: row.name,
                userName: row.emailId,
                emailId: row.emailId,
                mobileNumber: row.mobileNumber,
                departmentId: "",
                cityId: 0,
                roleId: 0,
                reportingToId: row.reportingTo,
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onSubUserAction(subUserRequestModel, PageModeEnum.Edit);
        }
    }

    const columnsSubUsers = [
        { key: "Name", text: "Name", sort: true, searchable: true },
        { key: "EmailId", text: "Email", sort: true, searchable: false },
        { key: "RoleName", text: "Role", sort: true, searchable: false },
        { key: "DepartmentName", text: "Department", sort: false, searchable: false },
        { key: "CityName", text: "Location", sort: false, searchable: false },
        { key: "ReportingTo", text: "Reporting To", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setSubUserFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
            <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsSubUsers.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={subUserFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.subUserList.map((subUser: SubUserModel, index) => (
                                <TableRow>
                                    <TableCell>{subUser.name}</TableCell>
                                    <TableCell>{subUser.emailId}</TableCell>
                                    <TableCell>{subUser.roleName}</TableCell>
                                    <TableCell>{subUser.departmentName}</TableCell>
                                    <TableCell>{subUser.cityName}</TableCell>
                                    <TableCell>{subUser.reportingTo}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { editSubUser(subUser); }} title="Edit">
                                            <i className="far fa-edit grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default SubUserGridComponent;
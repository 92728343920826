import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../../models/common/PagedListModel";
import RegisteredCompaniesModel from "../../../../models/admin/company/response/RegisteredCompaniesModel";
import RegisteredCompaniesSeachInputModel from "../../../../models/admin/company/request/RegisteredCompaniesSeachInputModel";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminCompanyService from "../../../../services/admin/admin-company-service";
import alertUtils from "../../../../utils/toaster-utils";
import CompanyOnBoardGridComponent from "./CompanyOnBoardGridComponent";
import CompanyConfigurationPopUp from "./CompanyConfigurationComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import RegisteredCompaniesRequestModel from "../../../../models/admin/company/request/RegisteredCompaniesRequestModel";
import masterDataService from "../../../../services/master-data-service";
import { PagingEnum } from "../../../../models/enums/IAIEnum";

const CompanyOnBoardComponent = () => {
    const [companyPagedList, setCompanyPagedList] = useState<PagedListModel<RegisteredCompaniesModel>>();
    const [companyList, setCompanyList] = useState<RegisteredCompaniesModel[]>([]);
    const [refreshCompany, setRefreshCompany] = useState<number>(2);
    const [companyNameFilter, setCompanyNameFilter] = useState<string>("");
    const [emailIdFilter, setEmailIdFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CompanyName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getRegisteredCompanies(getSearchFilters(companyNameFilter, emailIdFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (companyNameFilter: string, emailIdFilter: string, page: number, pageSize: number, sort: string, sortDirection: number) => {
        var inputData: RegisteredCompaniesSeachInputModel = {
            companyName: companyNameFilter,
            emailId: emailIdFilter,
            page: page,
            pageSize: pageSize,
            sort: sort,
            sortDir: sortDirection
        }
        return inputData;
    };

    const getRegisteredCompanies = (inputData: RegisteredCompaniesSeachInputModel) => {
        setRefreshCompany(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getRegisteredCompanies(inputData).then((res) => {
            modalUtils.closeModal();
            setCompanyList([]);
            setCompanyPagedList(res);
            setCompanyList(res.items);
            setRefreshCompany(refreshCompany * 5);
        }).catch((error) => {
            setRefreshCompany(refreshCompany * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onEditConfigurationActionHandler = (companyId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getCompanyConfigurationById(companyId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Configuration",
                content: (
                    <CompanyConfigurationPopUp
                        objCompany={res.data}
                        onCloseCompanyConfiguration={onCloseCompanyConfigurationHandler}
                        onCompanyConfigurationSave={onCompanyConfigurationSaveHandler}
                    />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onCloseCompanyConfigurationHandler = () => {
        modalUtils.closeModal();
    };

    const onCompanyConfigurationSaveHandler = (objCompany: RegisteredCompaniesRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminCompanyService.updateCompanyCofiguration(objCompany).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getRegisteredCompanies(getSearchFilters(companyNameFilter, emailIdFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getRegisteredCompanies(getSearchFilters(companyNameFilter, emailIdFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getRegisteredCompanies(getSearchFilters(companyNameFilter, emailIdFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getRegisteredCompanies(getSearchFilters(companyNameFilter, emailIdFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        if (filterColumn === "CompanyName") {
            setCompanyNameFilter(filterValue);
            getRegisteredCompanies(getSearchFilters(filterValue, emailIdFilter, 0, pageSize, sortColumn, sortDirection));
        } else {
            setEmailIdFilter(filterValue);
            getRegisteredCompanies(getSearchFilters(companyNameFilter, filterValue, 0, pageSize, sortColumn, sortDirection));
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {companyList.length > 0 && refreshCompany > 2 &&
                    <CompanyOnBoardGridComponent
                        key={refreshCompany}
                        companyList={companyList}
                        totalSize={companyPagedList?.totalCount ? companyPagedList?.totalCount : companyList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        companyNameFilterValue={companyNameFilter}
                        emailIdFilterValue={emailIdFilter}
                        onEditConfigurationAction={onEditConfigurationActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default CompanyOnBoardComponent;
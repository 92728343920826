import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import SubUserRequestModel from "../../../models/company/subUser/request/SubUserRequestModel";
import { OBJECT_NAME_RULE, EMAIL_RULE } from "../../../utils/validation-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import alertUtils from "../../../utils/toaster-utils";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface SubUserProps {
    objSubUser: SubUserRequestModel;
    actionType: string;
    roles: IdNameModel[];
    departments: IdNameModel[];
    cities: IdNameModel[];
    clientLeads: IdNameModel[];
    onCloseSubUser: () => void;
    onSubUserSave: (objSubUser: SubUserRequestModel) => void;
}

const SubUserCreateComponent = (props: SubUserProps) => {
    const {
        register,
        setValue,
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<SubUserRequestModel>({
        mode: "onChange",
        defaultValues: props.objSubUser
    });

    const roleSelected = useWatch({
        name: "roleId",
        control: control,
        defaultValue: props.objSubUser ? props.objSubUser.roleId : RoleEnum.ClientLead,
    });

    useEffect(() => {
        setValue("roleId", props.objSubUser.roleId);
    }, []);

    const onSubmit = (formData: SubUserRequestModel) => {
        if (formData.roleId == RoleEnum.ClientRecruiter && formData.reportingToId === null) {
            alertUtils.showError("Please Select Reporting To");
            return;
        }
        formData.userName = formData.emailId;
        props.onSubUserSave(formData);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("name", OBJECT_NAME_RULE)} placeholder="Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.name && errors.name.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Email: <span className="required">*</span> </label>
                                    <input type="email" className="form-control" {...register("emailId", EMAIL_RULE)} placeholder="Email/User Name" autoComplete="off" disabled={props.objSubUser.subUserId !== GuidEnum.Empty} />
                                    <span className="error">
                                        {errors.emailId && errors.emailId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">User Type: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("roleId", { required: true })} disabled={props.objSubUser.subUserId !== GuidEnum.Empty}>
                                        <option value="0">Select Role</option>
                                        {props.roles.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.roleId && errors.roleId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {parseInt(roleSelected.toString()) === parseInt(RoleEnum.ClientRecruiter.toString()) &&
                                <div className="quform-element form-group">
                                    <div className="quform-input">
                                        <label className="form-label">Reporting To: <span className="required">*</span> </label>
                                        <select className="form-control form-select" {...register("reportingToId")}>
                                            <option value="">Select Reporting</option>
                                            {props.clientLeads.map((type, index) => {
                                                return (
                                                    <option key={index} value={type.guId}> {type.name} </option>
                                                );
                                            })}
                                        </select>
                                        <span className="error">
                                            {errors.reportingToId && errors.reportingToId.message}
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Department: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("departmentId", { required: true })}>
                                        <option value="">Select Department</option>
                                        {props.departments.map((type, index) => {
                                            return (
                                                <option key={index} value={type.guId}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.departmentId && errors.departmentId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Location: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("cityId", { required: true })}>
                                        <option value="0">Select Location</option>
                                        {props.cities.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.cityId && errors.cityId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseSubUser()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default SubUserCreateComponent;
import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import CandidateAdminModel from "../../../../models/admin/candidate/response/CandidateAdminModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface ByPassPaymentGatewayGridProps {
    candidateList: CandidateAdminModel[];
    onByPassPaymentGatewayAction: (candidateId: string) => void;
}
const ByPassPaymentGatewayGridComponent = (props: ByPassPaymentGatewayGridProps) => {
    
    const byPassAction = (candidateId: string) => {
        props.onByPassPaymentGatewayAction(candidateId);
    };

    const columnsAdminUser = [
        { key: "UniqueId", text: "UniqueId", sort: false, searchable: false },
        { key: "CandidateName", text: "Name", sort: false, searchable: false },
        { key: "EmailId", text: "Email Id", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsAdminUser.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.candidateList.map((row: CandidateAdminModel, index) => (
                                <TableRow>
                                    <TableCell>{row.uniqueId}</TableCell>
                                    <TableCell>{row.candidateName}</TableCell>
                                    <TableCell>{row.emailId}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { byPassAction(row.candidateId); }} title="By Pass Payment Gateway">
                                            <i className="far fa-paypal grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default ByPassPaymentGatewayGridComponent;
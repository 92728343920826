import { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import IdNameModel from "../../../models/common/IdNameModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import JobPostingSkillRequestModel from "../../../models/company/jobPosting/request/JobPostingSkillRequestModel";
import JobPostingInterviewProcessRequestModel from "../../../models/company/jobPosting/request/JobPostingInterviewProcessRequestModel";

interface JobPostingInterviewProcessProps {
    objJobPostingInterviewProcess: JobPostingInterviewProcessRequestModel;
    actionType: string;
    secondarySkillList: IdNameModel[];
    interviewRoundList: IdNameModel[];
    onCloseJobPosting: () => void;
    onJobPostingInterviewProcessSave: (objJobPosting: JobPostingInterviewProcessRequestModel) => void;
};

const JobPostingInterviewProcessCreateComponent = (props: JobPostingInterviewProcessProps) => {
    const [formData, setFormData] = useState<JobPostingInterviewProcessRequestModel>(props.objJobPostingInterviewProcess);
    const [secondarySkillList, setSecondarySkillList] = useState<JobPostingSkillRequestModel[]>(props.objJobPostingInterviewProcess.jobPostingSkillRequestModel);

    useEffect(() => {
        setFormData(props.objJobPostingInterviewProcess);
    }, []);

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        trigger,
        formState: { errors, isValid },
    } = useForm<JobPostingInterviewProcessRequestModel>({
        mode: "onChange",
        defaultValues: formData
    });

    const onSubmit = (formData: JobPostingInterviewProcessRequestModel) => {
        formData.jobPostingSkillRequestModel.forEach(function (item, index) {
            item.requirementSkillId = GuidEnum.Empty;
            item.requirementId = props.objJobPostingInterviewProcess.requirementId;
            item.secondarySkillId = Number(item.secondarySkillId);
            item.secondarySkillNumber = index + 1;
            item.requirementInterviewProcessId = GuidEnum.Empty;
            item.recruiterId = GuidEnum.Empty;
            item.userId = GuidEnum.Empty;
        });
        formData.userId = GuidEnum.Empty;
        props.onJobPostingInterviewProcessSave(formData);
    }

    const addNewSkill = () => {
        let skill: JobPostingSkillRequestModel = {
            requirementSkillId: GuidEnum.Empty,
            requirementId: props.objJobPostingInterviewProcess.requirementId,
            secondarySkillId: null,
            secondarySkillNumber: secondarySkillList.length + 1,
            isCommentsRequired: false,
            requirementInterviewProcessId: GuidEnum.Empty,
            recruiterId: GuidEnum.Empty,
            userId: GuidEnum.Empty
        };
        const updatedList = [...secondarySkillList]; // simply create a new array and spread the previous one
        updatedList.push(skill);
        setSecondarySkillList(updatedList);
        const updatedFormData = {...formData};
        updatedFormData.jobPostingSkillRequestModel = updatedList;
        setFormData(updatedFormData);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Interview Round: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("interviewRoundId", { required: true })}>
                                        <option value="">Select Round</option>
                                        {props.interviewRoundList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.interviewRoundId && errors.interviewRoundId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formData.jobPostingSkillRequestModel.map((skill, index) => (
                        <div className="row">
                            <div className="col-md-6">
                                <div className="quform-element form-group">
                                    <div className="quform-input">
                                        <label className="form-label">Secondary Skill {index + 1}: <span className="required">*</span> </label>
                                        <select className="form-control form-select" {...register(`jobPostingSkillRequestModel.${index}.secondarySkillId`, { required: true })}>
                                            <option value="">Select Secondary Skill</option>
                                            {props.secondarySkillList.map((type, index) => {
                                                return (
                                                    <option key={index} value={type.id}> {type.name} </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="quform-element form-group">
                                    <div className="quform-input">
                                        <input type="checkbox" className="checkbox-job-posting-skills" {...register(`jobPostingSkillRequestModel.${index}.isCommentsRequired`)}
                                            disabled={(index + 1) < 4} />&nbsp;&nbsp;
                                        <label> Need Comments</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-default" onClick={addNewSkill} type="button"> + Add New Skill</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseJobPosting()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default JobPostingInterviewProcessCreateComponent;
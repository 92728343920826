import { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import relatedShortlistedProfilesService from "../../../services/company/related-shortlisted-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import ShortlistedProfilesGridComponent from "./ShortlistedProfilesGridComponent";
import ShortlistedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/ShortlistedProfilesGridModel";
import ShortlistedProfilesSearchInputModel from "../../../models/company/relatedAndShortlistedProfiles/request/ShortlistedProfilesSearchInputModel";
import RelatedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesRequestModel";
import addProfilesService from "../../../services/company/add-profiles-service";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import modalUtils from "../../../utils/modal-utils";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import ShortlistedProfileStatusUpdateRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/ShortlistedProfileStatusUpdateRequestModel";
import { Accordion, Card, Button } from "react-bootstrap";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";

interface ShortlistedProfilesProps {
    secondarySkillList: IdNameModel[];
    ratingList: IdNameModel[];
    requirementId: string;
};

const ShortlistedProfilesComponent = (props: ShortlistedProfilesProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [shortlistedProfilesPagedList, setShortlistedProfilesPagedList] = useState<PagedListModel<ShortlistedProfilesGridModel>>();
    const [shortlistedProfilesList, setShortlistedProfilesList] = useState<ShortlistedProfilesGridModel[]>([]);
    const [refreshShortlistedProfiles, setRefreshShortlistedProfiles] = useState<number>(2);
    const [totalRatingSearch, setTotalRatingSearch] = useState<number>(0);
    const [secondarySkillSearch, setSecondarySkillSearch] = useState<number>(0);
    const [candidateNameFilter, setCandidateNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getShortlistedProfiles(getSearchFilters(candidateNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (candidateNameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: ShortlistedProfilesSearchInputModel = {
            requirementId: props.requirementId,
            totalRating: totalRatingSearch === 0 ? null : totalRatingSearch,
            secondarySkillId: secondarySkillSearch === 0 ? null : secondarySkillSearch,
            candidateName: candidateNameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getShortlistedProfiles = (inputData: ShortlistedProfilesSearchInputModel) => {
        setRefreshShortlistedProfiles(2);
        if (loggedInUser?.profileId) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            relatedShortlistedProfilesService.getShortlistedProfilesGrid(inputData).then((res) => {
                modalUtils.closeModal();
                setShortlistedProfilesList([]);
                setShortlistedProfilesPagedList(res);
                setShortlistedProfilesList(res.items);
                setRefreshShortlistedProfiles(refreshShortlistedProfiles * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onStatusUpdateHandler = (objShortlistProfile: ShortlistedProfileStatusUpdateRequestModel) => {
        setRefreshShortlistedProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        relatedShortlistedProfilesService.updateStatus(objShortlistProfile).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getShortlistedProfiles(getSearchFilters(candidateNameFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            setRefreshShortlistedProfiles(refreshShortlistedProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onRatingDetailsHandler = (objShortlistProfile: RelatedProfilesRequestModel, hasAccess: boolean) => {
        if (hasAccess) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            addProfilesService.interviewRatingDetails(objShortlistProfile.interviewId).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    const config: GeneralPoupConfig = {
                        title: "Rating Details",
                        content: (
                            <RatingDetailsPopUp
                                objRatingDetails={res.data}
                            />
                        ),
                        size: ModalSize.XL,
                        className: "model-rating-details-badge"
                    };
                    modalUtils.showPopup(config);
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Schedules");
            });
        } else {
            setRefreshShortlistedProfiles(2);
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            relatedShortlistedProfilesService.saveCandidateAccessToViewProfile(objShortlistProfile).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    alertUtils.showSuccess(res.informationMessages.toString());
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
                getShortlistedProfiles(getSearchFilters(candidateNameFilter, page, pageSize, sortColumn, sortDirection));
            }).catch((error) => {
                setRefreshShortlistedProfiles(refreshShortlistedProfiles * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getShortlistedProfiles(getSearchFilters(candidateNameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getShortlistedProfiles(getSearchFilters(candidateNameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getShortlistedProfiles(getSearchFilters(candidateNameFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        setCandidateNameFilter(filterValue);
        getShortlistedProfiles(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <Accordion className="filter-accordian">
                    <Card className="card-border-bottom filter-card">
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Apply Filter
                                <i className="fa fa-angle-down"></i>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Total Rating</label>
                                        <select className="mb-3 form-control form-select" onChange={(e) => setTotalRatingSearch(parseInt(e.target.value))}>
                                            <option value="0">All</option>
                                            {props.ratingList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}> {item.name} </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Secondary Skill</label>
                                        <select className="mb-3 form-control form-select" onChange={(e) => setSecondarySkillSearch(parseInt(e.target.value))}>
                                            <option value="0">All</option>
                                            {props.secondarySkillList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}> {item.name} </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-warning" type="submit" onClick={() => getShortlistedProfiles(getSearchFilters(candidateNameFilter, page, pageSize, sortColumn, sortDirection))}>
                                            <i className="icon-search">Search</i>
                                        </button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                {refreshShortlistedProfiles > 2 &&
                    <ShortlistedProfilesGridComponent
                        key={refreshShortlistedProfiles}
                        shortlistedProfilesList={shortlistedProfilesList}
                        requirementId={props.requirementId}
                        totalSize={shortlistedProfilesPagedList?.totalCount ? shortlistedProfilesPagedList?.totalCount : shortlistedProfilesList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        candidateNameFilterValue={candidateNameFilter}
                        onStatusUpdate={onStatusUpdateHandler}
                        onRatingDetails={onRatingDetailsHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default ShortlistedProfilesComponent;
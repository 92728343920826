import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import AllProfilesComponent from "../../components/company/all-profiles/AllProfilesComponent";
import masterDataService from "../../services/master-data-service";
import IdNameModel from "../../models/common/IdNameModel";
import alertUtils from "../../utils/toaster-utils";
import modalUtils from "../../utils/modal-utils";
import loaderUtils from "../../utils/loader-utils";
import { RoleEnum } from "../../models/enums/RoleEnum";

const AllProfiles = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [roleId, setRoleId] = useState<number>(loggedInUser !== null ? parseInt(loggedInUser.roleId) : 0);
    const [primarySkillList, setPrimarySkillList] = useState<IdNameModel[]>([]);
    const [refreshPrimarySkills, setRefreshPrimarySkills] = useState<number>(2);

    useEffect(() => {
        loadPrimarySkills();
    }, []);

    const loadPrimarySkills = () => {
        setRefreshPrimarySkills(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadPrimarySkills().then((res) => {
            modalUtils.closeModal();
            setPrimarySkillList(res.items);
            setRefreshPrimarySkills(refreshPrimarySkills * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
            <h4 className="breadcrumb-header"> All Profiles </h4>
            </div>
            {refreshPrimarySkills > 2 && loggedInUser &&
                <AllProfilesComponent
                    primarySkillList={primarySkillList}
                    profileId={loggedInUser.profileId}
                    userId={roleId === parseInt(RoleEnum.ClientRecruiter.toString()) ? loggedInUser.userId : null} />
            }
        </Layout>
    );
};
export default AllProfiles;
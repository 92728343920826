import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import TagsInputModel from "../../../../models/common/TagsInputModel";
import InterviewerSchedulesModel from "../../../../models/interviewer/response/InterviewerSchedulesModel";
import interviewerService from "../../../../services/interviewer/interviewer-service";
import dateTimeUtils from "../../../../utils/date-utils";
import alertUtils from "../../../../utils/toaster-utils";
import { WithContext as ReactTags } from 'react-tag-input';
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InterviewerScheduleGridComponent from "./InterviewerScheduleGridComponent";
import adminInterviewsService from "../../../../services/admin/admin-interviews-service";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import InterviewerSchedulesRequestModel from "../../../../models/interviewer/request/InterviewerSchedulesRequestModel";


interface InterviewerSchedulesProps {
    primarySkillsList: IdNameModel[];
    timeSlotsList: IdNameModel[];
};

const InterviewerSchedulesComponent = (props: InterviewerSchedulesProps) => {
    let tagsinputList: TagsInputModel[] = props.timeSlotsList.map((item) => {
        return { id: item.id.toString(), text: item.name }
    });
    const [scheduleDate, setScheduleDate] = useState("" as any);
    const [timeslotsTags, setTimeslotsTags] = useState<TagsInputModel[]>(tagsinputList);
    const [timeslotsSelected, setTimeslotsSelected] = useState<TagsInputModel[]>([]);
    const [interviewerSchedules, setInterviewerSchedules] = useState<InterviewerSchedulesModel>();
    const [refreshInterviewerScheduleGrid, setRefreshInterviewerScheduleGrid] = useState<number>(2);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [interviewerSearch, setInterviewerSearch] = useState<string>("");
    const [interviewerList, setInterviewerList] = useState<IdNameModel[]>([]);

    const selectDateHanler = (date: any) => {
        //console.log(dateUtils.getDayFromDate(new Date(date)));
        setScheduleDate(date);
        getInterviewerSchedule(interviewerSearch, date);
    };

    const handleDelete = (i: number) => {
        setTimeslotsSelected(timeslotsSelected.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag: TagsInputModel) => {
        setTimeslotsSelected([...timeslotsSelected, tag]);
    };

    const handleDrag = (tag: TagsInputModel, currPos: any, newPos: any) => {
        const newTags = timeslotsSelected.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTimeslotsSelected(newTags);
    };

    const handleTagClick = (index: any) => {
        //console.log('The tag at index ' + index + ' was clicked');
    };

    const clearSchedule = () => {
        setPrimarySkillSearch(0);
        setInterviewerSearch("");
        setScheduleDate("");
        setTimeslotsSelected([]);
        setRefreshInterviewerScheduleGrid(2);
    };

    const getInterviewerSchedule = (interviewerId: string, date: string) => {
        setRefreshInterviewerScheduleGrid(2);
        interviewerService.getInterviewerSchedules(interviewerId, dateTimeUtils.getFormattedDateFromDate(date)).then((res) => {
            setInterviewerSchedules(res.data);
            setRefreshInterviewerScheduleGrid(refreshInterviewerScheduleGrid * 5);
        }).catch((error) => {
            alertUtils.showError(error);
            setRefreshInterviewerScheduleGrid(refreshInterviewerScheduleGrid * 5);
        });
    };

    const getInterviewerListByPrimarySkill = (primarySkillId: number) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviewersByPrimarySkill(primarySkillId).then((res) => {
            modalUtils.closeModal();
            setInterviewerList(res.items);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const saveSchedule = () => {
        if (scheduleDate === "" || timeslotsSelected.length < 1 || interviewerSearch === "") {
            alertUtils.showWarning("Please Select Interviewer Date and TimeSlots.");
        } else {
            var timslotsSaveList: InterviewerSchedulesRequestModel[] = [];
            var objTimeSlot: InterviewerSchedulesRequestModel = {
                interviewerId: interviewerSearch,
                startDate: dateTimeUtils.getFormattedDateFromDate(scheduleDate),
                endDate: dateTimeUtils.getFormattedDateFromDate(scheduleDate),
                timeSlotIds: timeslotsSelected.map(x => Number(x.id)),
                blockSchedule: false
            }
            timslotsSaveList.push(objTimeSlot);
            if (timslotsSaveList.length > 0) {
                interviewerService.saveInterviewerSchedule(timslotsSaveList).then((res) => {
                    alertUtils.showSuccess(res.informationMessages.toString());
                    getInterviewerSchedule(interviewerSearch, scheduleDate);
                }).catch((error) => {
                    alertUtils.showError(error);
                });
            }
        }
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => getInterviewerListByPrimarySkill(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillsList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-8">
                        <label>Interviewer</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setInterviewerSearch(e.target.value)}>
                            <option value="0">All</option>
                            {interviewerList.map((item, index) => {
                                return (
                                    <option key={index} value={item.guId}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">Date: <span className="required">*</span></label>
                        <DateTimePicker
                            selectsStart
                            onChange={selectDateHanler}
                            selected={scheduleDate}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            minDate={dateTimeUtils.addDays(new Date(), 1)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-8">
                        <label className="form-label">Time Slots: <span className="required">*</span></label>
                        <ReactTags
                            tags={timeslotsSelected}
                            suggestions={timeslotsTags}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                            handleTagClick={handleTagClick}
                            inputFieldPosition="bottom"
                            minQueryLength={0}
                            placeholder="Click here to Add a Schedule"
                            autofocus={false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <button disabled={scheduleDate === "" || timeslotsSelected.length < 1} className="btn btn-success btn-form" type="submit" onClick={() => saveSchedule()}><span>Save</span></button>
                        <button className="btn btn-danger btn-form" type="button" onClick={() => clearSchedule()}><span>Clear</span></button>
                    </div>
                </div>
                <div className="row">
                    {interviewerSchedules && refreshInterviewerScheduleGrid != 2 &&
                        <InterviewerScheduleGridComponent
                            key={refreshInterviewerScheduleGrid}
                            interviewerSchedule={interviewerSchedules} />
                    }
                </div>
            </div>
        </div>
    );
};
export default InterviewerSchedulesComponent;
import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import { GuidEnum } from "../../../../models/enums/GuidEnum";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import RequirementEscalationsGridModel from "../../../../models/company/escalation/response/RequirementEscalationsGridModel";
import RequirementEscalationsRequestModel from "../../../../models/company/escalation/request/RequirementEscalationsRequestModel";

interface EscalationsGridProps {
    escalationsList: RequirementEscalationsGridModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    jobcodeFilterValue: string;
    disablePagingAndSorting: boolean;
    onEscalationAddAction: (objInterview: RequirementEscalationsRequestModel) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
};

const EscalationsGridComponent = (props: EscalationsGridProps) => {
    const [jobcodeFilter, setJobCodeFilter] = useState<string>(props.jobcodeFilterValue);

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const columnsCandidates = [
        { Key: "JobCode", text: "JobCode", sort: props.disablePagingAndSorting ? false : true, searchable: props.disablePagingAndSorting ? false : true },
        { Key: "CompanyName", text: "Company", sort: props.disablePagingAndSorting ? false : true, searchable: false },
        { Key: "EscalationDate", text: "Escalation Dt", sort: props.disablePagingAndSorting ? false : true, searchable: false },
        { Key: "StatusName", text: "Status", sort: props.disablePagingAndSorting ? false : true, searchable: false },
        { Key: "Comments", text: "Comments", sort: false, searchable: false },
        { Key: "EscalatedBy", text: "Escalated By", sort: false, searchable: false },
        { Key: "CreatedOn", text: "Created Dt", sort: props.disablePagingAndSorting ? false : true, searchable: false },
        { Key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setJobCodeFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    const updateEscalation = (escalation: RequirementEscalationsGridModel) => {
        let objEscalation: RequirementEscalationsRequestModel = {
            escalationId: escalation.escalationId,
            requirementId: escalation.requirementId,
            escalationDate: escalation.escalationDate,
            comments: escalation.comments,
            statusId: escalation.statusId,
            userId: GuidEnum.Empty
        };
        props.onEscalationAddAction(objEscalation);
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsCandidates.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={jobcodeFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.escalationsList.map((escalation: RequirementEscalationsGridModel, index) => (
                                <TableRow>
                                    <TableCell>{escalation.jobCode}</TableCell>
                                    <TableCell>{escalation.companyName}</TableCell>
                                    <TableCell>{escalation.escalationDateString}</TableCell>
                                    <TableCell>{escalation.statusName}</TableCell>
                                    <TableCell>{escalation.comments}</TableCell>
                                    <TableCell>{escalation.escalatedBy}</TableCell>
                                    <TableCell>{escalation.createdOn}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { updateEscalation(escalation); }} title="Update">
                                            <i className="fa fa-pencil grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!props.disablePagingAndSorting &&
                    <TablePagination
                        rowsPerPageOptions={props.pageSizesList}
                        component="div"
                        count={props.totalSize}
                        rowsPerPage={props.pagesize}
                        page={props.currentPage - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </div>
        </div>
    );
};
export default EscalationsGridComponent;
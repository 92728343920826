import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import CandidatePasswordComponent from "../../../components/admin/candidate/candidate-password/CandidatePasswordComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";

const CandidatePassword = () => {
    const [roleList, setRoleList] = useState<IdNameModel[]>([]);
    const [refreshRoles, setRefreshRoles] = useState<number>(2);

    useEffect(() => {
        fillRoles();
    }, []);

    const fillRoles = () => {
        setRefreshRoles(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadRoles().then((res) => {
            modalUtils.closeModal();
            setRoleList(res.items);
            setRefreshRoles(refreshRoles * 5);
        }).catch((error) => {
            setRefreshRoles(refreshRoles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Candidate Password Search </h4>
            </div>
            {refreshRoles > 2 &&
                <CandidatePasswordComponent roleList={roleList} />
            }
        </Layout>
    );
};
export default CandidatePassword;
import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import CandidateAdminModel from "../../../../models/admin/candidate/response/CandidateAdminModel";
import adminCandidateService from "../../../../services/admin/admin-candidate-service";
import alertUtils from "../../../../utils/toaster-utils";
import ByPassPaymentGatewayGridComponent from "./ByPassPaymentGatewayGridComponent";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";

const ByPassPaymentGatewayComponent = () => {
    const [candidateList, setCandidateList] = useState<CandidateAdminModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [uniqueIdSearch, setUniqueIdSearch] = useState<string>("");

    useEffect(() => {
        clearSearch();
    }, []);

    const getCandidates = (uniqueId: string) => {
        setRefreshCandidate(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCandidateService.getCandidateByUniqueId(uniqueId).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);            
        }).catch((error) => {
            modalUtils.closeModal();
            setRefreshCandidate(refreshCandidate * 5);
            alertUtils.showError(error);                        
        });
    };

    const clearSearch = () => {
        setCandidateList([]);
        setUniqueIdSearch("");
        setRefreshCandidate(2);
    };

    const onByPassPaymentGatewayActionHandler = (candidateId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCandidateService.byPassPaymentGateway(candidateId).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <label>Candidate Unique Id</label>
                        <input type="text" className="form-control" onChange={(e) => setUniqueIdSearch(e.target.value)} placeholder="Unique Id" autoComplete="off" />
                    </div>
                    <div className="col-md-4">
                        <button className="btn btn-success" type="submit" onClick={() => getCandidates(uniqueIdSearch)}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {candidateList.length > 0 && refreshCandidate > 2 &&
                    <ByPassPaymentGatewayGridComponent
                        key={refreshCandidate}
                        candidateList={candidateList}
                        onByPassPaymentGatewayAction={onByPassPaymentGatewayActionHandler} />
                }
            </div>
        </div>
    );

};
export default ByPassPaymentGatewayComponent;
import React from "react";
import VendorSubUserRequestModel from "../../../models/company/vendorUser/request/VendorSubUserRequestModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface JobPostingVendorsGridProps {
    vendorList: VendorSubUserRequestModel[];
}

const JobPostingVendorsGridComponent = (props: JobPostingVendorsGridProps) => {
    const columnsVendors = [
        { key: "VendorName", text: "Vendor Name", sort: false, searchable: false },
        { key: "VendorEmailId", text: "Email Id", sort: false, searchable: false },
        { key: "VendorPhoneNumber", text: "Phone Number", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsVendors.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.vendorList.map((row: VendorSubUserRequestModel, index) => (
                                <TableRow>
                                    <TableCell>{row.vendorName}</TableCell>
                                    <TableCell>{row.vendorEmailId}</TableCell>
                                    <TableCell>{row.vendorPhoneNumber}</TableCell>                                    
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default JobPostingVendorsGridComponent;
import { SortDirection } from "./SortDirectionEnum";

export enum IAIEnum {
    MaxRating = 5
}

export enum PagingEnum {
    pageSize = 10,
    page = 0,
    sortDirection = SortDirection.Asc
}
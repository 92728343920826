import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import EditRatingModel from "../../../../models/admin/interviews/response/EditRatingModel";
import EditRatingSearchInputModel from "../../../../models/admin/interviews/request/EditRatingSearchInputModel";
import adminInterviewsService from "../../../../services/admin/admin-interviews-service";
import alertUtils from "../../../../utils/toaster-utils";
import EditRatingGridComponent from "./EditRatingGridComponent";
import EditRatingPopUp from "./EditRatingUpdateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import modalUtils from "../../../../utils/modal-utils";
import InterviewRatingModel from "../../../../models/interviewer/response/InterviewRatingModel";
import loaderUtils from "../../../../utils/loader-utils";

const EditRatingComponent = () => {
    const history = useHistory();
    const [interviewsList, setInterviewsList] = useState<EditRatingModel[]>([]);
    const [refreshInterviews, setRefreshInterviews] = useState<number>(2);
    const [candidateNameSearch, setCandidateNameSearch] = useState<string>("");
    const [interviewUniqueIdSearch, setInterviewUniqueIdSearch] = useState<string>("");

    useEffect(() => {
        clearSearch();
    }, []);

    const clearSearch = () => {
        setInterviewsList([]);
        setCandidateNameSearch("");
        setInterviewUniqueIdSearch("");
        setRefreshInterviews(2);
    };

    const getInterviews = () => {
        setRefreshInterviews(2);
        var inputData: EditRatingSearchInputModel = {
            candidateName: candidateNameSearch,
            interviewUniqueId: interviewUniqueIdSearch
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviewsForRatingEdit(inputData).then((res) => {
            modalUtils.closeModal();
            setInterviewsList([]);
            setInterviewsList(res.items);
            setRefreshInterviews(refreshInterviews * 5);            
        }).catch((error) => {
            setRefreshInterviews(refreshInterviews * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const onRatingEditActionHandler = (interviewId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviewRatingDetails(interviewId).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                const config: GeneralPoupConfig = {
                    title: "Add User",
                    content: (
                        <EditRatingPopUp
                            objRateInterview={res.data}
                            onRatingUpdateAction={onRatingUpdateActionHandler}
                            onRatingClearAction={onRatingClearActionHandler}
                        />
                    ),
                    size: ModalSize.XL,
                    className: "model-min-height-500"
                };
                modalUtils.showPopup(config);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Schedules");            
        });
    };

    const onRatingClearActionHandler = () => {
        modalUtils.closeModal();
    };

    const onRatingUpdateActionHandler = (objRateInterview: InterviewRatingModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminInterviewsService.updateInterviewRating(objRateInterview).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
                getInterviews();
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Candidate Name</label>
                        <input type="text" className="form-control" onChange={(e) => setCandidateNameSearch(e.target.value)} placeholder="Name Like" autoComplete="off" />
                    </div>
                    <div className="col-md-3">
                        <label>Interview Id</label>
                        <input type="text" className="form-control" onChange={(e) => setInterviewUniqueIdSearch(e.target.value)} placeholder="Interview Id" autoComplete="off" />
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getInterviews()}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {interviewsList.length > 0 && refreshInterviews > 2 &&
                    <EditRatingGridComponent
                        key={refreshInterviews}
                        interviewList={interviewsList}
                        onRatingEditAction={onRatingEditActionHandler} />
                }
            </div>
        </div>
    );
};
export default EditRatingComponent;
import { useAppSelector } from "../../context-store";
import MenuModel from "./model/MenuModel";
import styles from "./SideBar.module.css";
import Logo from "../../assets/InnerCSS/img/logos/logo.png";
import { useHistory } from "react-router";
import React, { Fragment, useState, useEffect, MouseEvent } from "react";
import RoleScreenModel from "./model/RoleScreenModel";
import SubMenuModel from "./model/SubMenuModel";
import MenuItem from "./MenuItem";

const SideBar = () => {
  let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
  const history = useHistory();
  //const menuGroups: MenuModel[] = [];

  const processRoutes = () => {
    let routes: MenuModel[] = [];
    if (loggedInUser != null) {
      let mainRoutes: RoleScreenModel[] = loggedInUser.routes.filter(x => x.menuLevel === 0);
      mainRoutes.forEach(route => {
        let subRoutes = loggedInUser?.routes.filter(x => x.parentId === route.screenId).map(subRoute => {
          return {
            id: subRoute.screenName,
            title: subRoute.displayName,
            icon: subRoute.icon,
            path: subRoute.routePath
          };
        }) as SubMenuModel[];
        let objRoute: MenuModel = {
          id: route.screenName,
          title: route.displayName,
          opened: false,
          icon: route.icon,
          hasChildren: subRoutes.length > 0 ? true : false,
          path: route.routePath,
          subMenuItems: subRoutes
        };
        routes.push(objRoute);
      });
    }
    return routes;
  };

  const menuGroups: MenuModel[] = processRoutes();
  const sideBarExpanded = useAppSelector(state => state.menuState.sideBarExpanded);
  let expandCollapseClass = sideBarExpanded ? styles.sidebarExpanded : styles.sidebarCollapsed;
  let sidebarClass = `d-none d-md-block ${styles.sidebarContainer} ${expandCollapseClass}`;
  const homeRedirect = (event: MouseEvent) => {
    event.preventDefault();
    history.push("/");
  }

  return (
    <div className="page-sidebar">
      <a className="logo-box" onClick={(e) => { homeRedirect(e); }}>
        <span><img src={Logo} alt=""></img></span>
      </a>
      <div className="page-sidebar-inner">
        <div className="page-sidebar-menu">
          <ul className="accordion-menu">
            {menuGroups.map((menuGroup) => (
              <MenuItem key={menuGroup.id} menuItem={menuGroup} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
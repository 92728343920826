import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import DepartmentService from '../../../services/company/department-service';
import DepartmentSearchInputModel from "../../../models/company/department/request/DepartmentSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import DepartmentModel from "../../../models/company/department/response/DepartmentModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import DepartmentPopUp from "./DepartmentCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import DepartmentRequestModel from "../../../models/company/department/request/DepartmentRequestModel";
import DepartmentGridComponent from "./DepartmentGridComponent";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";

const DepartmentComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [departmentPagedList, setDepartmentPagedList] = useState<PagedListModel<DepartmentModel>>();
    const [departmentList, setDepartmentList] = useState<DepartmentModel[]>([]);
    const [refreshDepartment, setRefreshDepartment] = useState<number>(2);
    const [departmentNameFilter, setDepartmentNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("DepartmentName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getDepartments(getSearchFilters(departmentNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (departmentNameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: DepartmentSearchInputModel = {
            companyId: "",
            departmentName: departmentNameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getDepartments = (inputData: DepartmentSearchInputModel) => {
        if (loggedInUser?.profileId) {
            inputData.companyId = loggedInUser?.profileId;
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            DepartmentService.getDepartments(inputData).then((res) => {
                modalUtils.closeModal();
                setDepartmentList([]);
                setDepartmentPagedList(res);
                setDepartmentList(res.items);
                setRefreshDepartment(refreshDepartment * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const openDepartmentSaveModel = (departmentRequestModel: DepartmentRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Department",
            content: (
                <DepartmentPopUp
                    objDepartment={departmentRequestModel}
                    actionType={actionType}
                    onCloseDepartment={onCloseDepartmentHandler}
                    onDepartmentSave={onDepartmentSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseDepartmentHandler = () => {
        modalUtils.closeModal();
    };

    const onDepartmentSaveHandler = (objDepartment: DepartmentRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        DepartmentService.saveDepartment(objDepartment).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getDepartments(getSearchFilters(departmentNameFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onDepartmentActionHandler = (obj: DepartmentRequestModel, actionType: string) => {
        openDepartmentSaveModel(obj, actionType);
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getDepartments(getSearchFilters(departmentNameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getDepartments(getSearchFilters(departmentNameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        const newSortDirection = sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc;
        setSortDirection(newSortDirection)
        getDepartments(getSearchFilters(departmentNameFilter, page, pageSize, newSortColumn, newSortDirection));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        setDepartmentNameFilter(filterValue);
        getDepartments(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    const addDepartment = () => {
        if (loggedInUser !== null) {
            let departmentRequestModel: DepartmentRequestModel = {
                departmentId: GuidEnum.Empty,
                departmentName: "",
                description: "",
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            onDepartmentActionHandler(departmentRequestModel, PageModeEnum.Create);
        }
    }

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getDepartments(getSearchFilters(departmentNameFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addDepartment}> + Add Department</a>
            </div>
            <div className="card-body">
                {departmentList.length > 0 && refreshDepartment > 2 &&
                    <DepartmentGridComponent
                        key={refreshDepartment}
                        departmentList={departmentList}
                        totalSize={departmentPagedList?.totalCount ? departmentPagedList?.totalCount : departmentList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        departmentNameFilterValue={departmentNameFilter}
                        onDepartmentAction={onDepartmentActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default DepartmentComponent;
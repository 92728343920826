import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";
import TodayFollowupGridComponent from "./TodayFollowupGridComponent";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../../utils/modal-utils";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import TodayFollowupPopup from "../../candidate/today-followups/TodayFollowupUpdateComponent"
import IdNameModel from "../../../../models/common/IdNameModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import CandidateStatusModel from "../../../../models/admin/candidate/response/CandidateStatusModel";
import masterDataService from "../../../../services/master-data-service";
import alertUtils from "../../../../utils/toaster-utils";
import CandidateStatusSearchInputModel from "../../../../models/admin/candidate/request/CandidateStatusSearchInputModel";
import loaderUtils from "../../../../utils/loader-utils";
import adminCandidateService from "../../../../services/admin/admin-candidate-service";
import CanidateStatusRequestModel from "../../../../models/admin/candidate/request/CanidateStatusRequestModel";
import CandidateHistoryPopup from "../candidate-history/CandidateHistoryComponent";

interface TodayFollowupProps {
    statusList: IdNameModel[];
};

const TodayFollowupComponent = (props: TodayFollowupProps) => {
    const [candidatePagedList, setCandidatePagedList] = useState<PagedListModel<CandidateStatusModel>>();
    const [candidateList, setCandidateList] = useState<CandidateStatusModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(new Date() as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [CandidateNameFilter, setCandidateNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getCandidates(getSearchFilters(CandidateNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (nameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: CandidateStatusSearchInputModel = {
            startDate: dateTimeUtils.getFormattedDate(startDateSearch),
            endDate: dateTimeUtils.getFormattedDate(endDateSearch),
            candidateName: nameFilter,
            statusId: 0,
            candidateId: null,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getCandidates = (inputData: CandidateStatusSearchInputModel) => {
        setRefreshCandidate(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCandidateService.getFollowUps(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidatePagedList(res);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);
        }).catch((error) => {
            setRefreshCandidate(refreshCandidate * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onUpdateFollowupActionHandler = (objCandidate: CanidateStatusRequestModel) => {
        const config: GeneralPoupConfig = {
            title: "update Status",
            content: (
                <TodayFollowupPopup
                    objCandidate={objCandidate}
                    statusList={props.statusList}
                    onFollowupClose={onFollowupCloseHandler}
                    onFollowupUpdate={onFollowUpUpdateHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onFollowupCloseHandler = () => {
        modalUtils.closeModal();
    };

    const onFollowUpUpdateHandler = (objCandidate: CanidateStatusRequestModel) => {
        setRefreshCandidate(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminCandidateService.saveCandidateStatus(objCandidate).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getCandidates(getSearchFilters(CandidateNameFilter, page, pageSize, sortColumn, sortDirection));
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onCandidateViewHistoryHandler = (candidateId: string) => {
        const config: GeneralPoupConfig = {
            title: "Candidate History",
            content: (
                <CandidateHistoryPopup
                    candidateId={candidateId} />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        setCandidateNameFilter(filterValue);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-2">
                        <label>Start Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <label>End Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-2 mt-4">
                        <button className="btn btn-success" type="submit" onClick={() => getCandidates(getSearchFilters(CandidateNameFilter, page, pageSize, sortColumn, sortDirection))}>
                            <i className="icon-search">Search</i>
                        </button>
                    </div>
                </div>
                <div className="mt-4">
                    <TodayFollowupGridComponent
                        followupList={candidateList}
                        totalSize={candidatePagedList?.totalCount ? candidatePagedList?.totalCount : candidateList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        candidateNameFilterValue={CandidateNameFilter}
                        onUpdateFollowupAction={onUpdateFollowupActionHandler}
                        onCandidateViewHistory={onCandidateViewHistoryHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                </div>
            </div>
        </div>
    );
}

export default TodayFollowupComponent;

import React, { useState, useEffect } from "react";
import IdNameModel from "../../../models/common/IdNameModel";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import alertUtils from "../../../utils/toaster-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import RequirementEscalationsGridModel from "../../../models/company/escalation/response/RequirementEscalationsGridModel";
import clientEscalationService from "../../../services/company/client-escalation-service";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import RequirementEscalationsRequestModel from "../../../models/company/escalation/request/RequirementEscalationsRequestModel";
import EscalationsGridComponent from "../../admin/company/escalations/EscalationsGridComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import EscalationsUpdateComponent from "../../admin/company/escalations/EscalationsUpdateComponent";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface EscalationsProps {
    requirementId: string;
    statusList: IdNameModel[];
};

var objEscalationDefault: RequirementEscalationsRequestModel = {
    escalationId: "",
    requirementId: "",
    escalationDate: new Date(),
    comments: "",
    statusId: 0,
    userId: GuidEnum.Empty
};

const ViewEscalationComponent = (props: EscalationsProps) => {
    const [escalationsList, setEscalationsList] = useState<RequirementEscalationsGridModel[]>([]);
    const [refreshEscalations, setRefreshEscalations] = useState<number>(2);
    const [jobcodeFilter, setJobcodeFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("EscalationDate");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);
    const [showEscalationsUpdate, setShowEscalationsUpdate] = useState<boolean>(false);
    const [objEscalation, setObjEscalation] = useState<RequirementEscalationsRequestModel>(objEscalationDefault);

    useEffect(() => {
        loadPageSizes();
        getEscalations(props.requirementId);
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getEscalations = (requirementId: string) => {
        setRefreshEscalations(2);
        //modalUtils.showLoader(loaderUtils.getLoadDataText());
        clientEscalationService.getEscalationsForRequirement(requirementId).then((res) => {
            //modalUtils.closeModal();
            setEscalationsList([]);
            setEscalationsList(res.items);
            setRefreshEscalations(refreshEscalations * 5);
        }).catch((error) => {
            setRefreshEscalations(refreshEscalations * 5);
            //modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onEscalationAddActionHandler = (objEscalation: RequirementEscalationsRequestModel) => {
        setObjEscalation(objEscalation);
        setShowEscalationsUpdate(true);
    };

    const onEscalationCloseActionHandler = () => {
        modalUtils.closeModal();
    };

    const onEscalationSaveActionHandler = (objEscalation: RequirementEscalationsRequestModel) => {
        //modalUtils.showLoader(loaderUtils.getLoadDataText());
        clientEscalationService.saveEscalation(objEscalation).then((res) => {
            //modalUtils.closeModal();
            setShowEscalationsUpdate(false);
            getEscalations(props.requirementId);
        }).catch((error) => {
            //modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const addEscalation = () => {
        let objEscalation: RequirementEscalationsRequestModel = {
            escalationId: GuidEnum.Empty,
            requirementId: props.requirementId,
            escalationDate: new Date(),
            comments: "",
            statusId: 0,
            userId: GuidEnum.Empty
        };
        setObjEscalation(objEscalation);
        setShowEscalationsUpdate(true);
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getEscalations(props.requirementId);
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getEscalations(props.requirementId);
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getEscalations(props.requirementId);
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        setJobcodeFilter(filterValue);
        getEscalations(props.requirementId);
    };

    return (
        <div>
            <div className="card card-white">
                <div className="card-head card-head-text-right">
                    <a className="btn-grid-add-action" title="Reload Data" onClick={() => getEscalations(props.requirementId)}>
                        <i className="fa fa-refresh"></i>
                    </a>
                    <a className="btn-grid-add-action" onClick={addEscalation}> + Add Escalation</a>
                </div>
                <div className="card-body">
                    {showEscalationsUpdate &&
                        <EscalationsUpdateComponent
                            objEscalation={objEscalation}
                            statusList={props.statusList}
                            onEscalationSaveAction={onEscalationSaveActionHandler}
                            onEscalationCloseAction={onEscalationCloseActionHandler} />
                    }
                    {refreshEscalations > 2 &&
                        <EscalationsGridComponent
                            key={refreshEscalations}
                            escalationsList={escalationsList}
                            totalSize={escalationsList.length}
                            currentPage={page + 1}
                            pagesize={pageSize}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                            pageSizesList={pageSizesList}
                            jobcodeFilterValue={jobcodeFilter}
                            disablePagingAndSorting={true}
                            onEscalationAddAction={onEscalationAddActionHandler}
                            onPageChangeAction={onPageChangeActionHandler}
                            onPageSizeChangeAction={onPageSizeChangeActionHandler}
                            onSortAction={onSortActionHandler}
                            onFilterAction={onFilterActionHandler} />
                    }
                </div>
            </div>
        </div>
    );
};
export default ViewEscalationComponent;
import React, { useState } from "react";
import { useAppSelector } from "../../../context-store";
import DataGrid from "../../common/table/DataGrid";
import DesignationModel from "../../../models/company/designation/response/DesignationModel";
import DesignationRequestModel from "../../../models/company/designation/request/DesignationRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface DesignationGridProps {
    designationList: DesignationModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    designationNameFilterValue: string;
    onDesignationAction: (row: DesignationRequestModel, actionType: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const DesignationGridComponent = (props: DesignationGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [designationNameFilter, setDesignationNameFilter] = useState<string>(props.designationNameFilterValue);

    const editDesignation = (row: DesignationModel) => {
        if (loggedInUser !== null) {
            let designationRequestModel: DesignationRequestModel = {
                designationId: row.designationId,
                designationName: row.designationName,
                description: row.description,
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            props.onDesignationAction(designationRequestModel, PageModeEnum.Edit);
        }
    };

    const columnsDesignations = [
        { Key: "DesignationName", text: "Designation Name", sort: true, searchable: true },
        { Key: "Description", text: "Description", sort: false, searchable: false },
        { Key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setDesignationNameFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsDesignations.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={designationNameFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.designationList.map((designation: DesignationModel, index) => (
                                <TableRow>
                                    <TableCell>{designation.designationName}</TableCell>
                                    <TableCell>{designation.description}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { editDesignation(designation); }} title="Edit Designation">
                                            <i className="fa fa-edit grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
            </div>
        </div>
    );
};
export default DesignationGridComponent;
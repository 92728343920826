import React from "react";
import { useForm } from "react-hook-form";
import SecondarySkillTopicRequestModel from "../../../../models/admin/skill/request/SecondarySkillTopicRequestModel";
import { GuidEnum } from "../../../../models/enums/GuidEnum";
import { OBJECT_NAME_RULE, OBJECT_DESCRIPTION_RULE } from "../../../../utils/validation-utils";

interface SecondarySkillTopicProps {
    secondarySkillId: number;
    onSecondarySkillTopicSave: (objMapping: SecondarySkillTopicRequestModel) => void;
};

const SecondarySkillMappingCreateComponent = (props: SecondarySkillTopicProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<SecondarySkillTopicRequestModel>({
        mode: "onChange"
    });

    const onSubmit = (formData: SecondarySkillTopicRequestModel) => {
        formData.topicId = 0;
        formData.secondarySkillId = props.secondarySkillId;
        formData.userId = GuidEnum.Empty;
        props.onSecondarySkillTopicSave(formData);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Topic Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("topicName", OBJECT_NAME_RULE)} placeholder="Enter topic name" autoComplete="off" />
                                    <span className="error">
                                        {errors.topicName && errors.topicName.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Description: </label>
                                    <textarea rows={3} className="form-control" {...register("description", OBJECT_DESCRIPTION_RULE)} placeholder="Enter description" autoComplete="off" />
                                    <span className="error">
                                        {errors.description && errors.description.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default SecondarySkillMappingCreateComponent;
import { useState, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import ScheduleInterviewCompanyRequestModel from "../../../models/company/addProfiles/request/ScheduleInterviewCompanyRequestModel";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IdNameModel from "../../../models/common/IdNameModel";
import alertUtils from "../../../utils/toaster-utils";
import dateTimeUtils from "../../../utils/date-utils";

interface ScheduleInterviewProps {
    objScheduleInterview: ScheduleInterviewCompanyRequestModel;
    timeSlotsList: IdNameModel[];
    onCloseScheduleInterview: () => void;
    onScheduleInterviewSave: (objScheduleInterview: ScheduleInterviewCompanyRequestModel) => void;
}

const ScheduleInterviewComponent = (props: ScheduleInterviewProps) => {
    const [interviewDate, setInterviewDate] = useState("" as any);
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<ScheduleInterviewCompanyRequestModel>({
        mode: "onChange",
        defaultValues: props.objScheduleInterview
    });

    const selectEndDateHanler = (date: any) => {
        setInterviewDate(date);
    };

    const onSubmit = (formData: ScheduleInterviewCompanyRequestModel) => {
        if (!interviewDate ) {
            alertUtils.showError("Interview date is mandatory.");
        } else {
            formData.ScheduleInterviewRequestModel.candidateId = props.objScheduleInterview.profileId;
            formData.ScheduleInterviewRequestModel.interviewDate = dateTimeUtils.addDays(interviewDate, 1);;
            formData.ScheduleInterviewRequestModel.interviewTypeId = props.objScheduleInterview.ScheduleInterviewRequestModel.interviewTypeId;
            props.onScheduleInterviewSave(formData);
        }        
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-label">Interview Date: <span className="required">*</span></label>
                            <DateTimePicker
                                selectsStart
                                onChange={selectEndDateHanler}
                                selected={interviewDate}
                                timeInputLabel="Time:"
                                dateFormat="yyyy-MMM-dd"
                                className="form-control"
                                minDate={dateTimeUtils.addDays(new Date(), 1)}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Time Slot: </label>
                                    <select className="form-control form-select" {...register("ScheduleInterviewRequestModel.timeSlotId", { required: true })}>
                                        <option value="0">Select Timeslot</option>
                                        {props.timeSlotsList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.ScheduleInterviewRequestModel?.timeSlotId && errors.ScheduleInterviewRequestModel?.timeSlotId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Schedule Interview</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseScheduleInterview()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default ScheduleInterviewComponent;
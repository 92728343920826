import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";

interface AdminAllProfilesProps {
    companiesList: IdNameModel[];
};

const AdminAllProfilesComponent = (props: AdminAllProfilesProps) => {

    return (
        <div></div>
    );
};
export default AdminAllProfilesComponent;
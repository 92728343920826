import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import ReleaseCandidatesModel from "../../../../models/admin/company/response/ReleaseCandidatesModel";
import ReleaseCandidatesRequestModel from "../../../../models/admin/company/request/ReleaseCandidatesRequestModel";
import { GuidEnum } from "../../../../models/enums/GuidEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface ReleaseCandidateGridProps {
    candidateList: ReleaseCandidatesModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    nameFilterValue: string;
    emailIdFilterValue: string;
    jobCodeFilterValue: string;
    onReleaseCandidateAction: (objReleaseCandidate: ReleaseCandidatesRequestModel) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
};

const ReleaseCandidateGridComponent = (props: ReleaseCandidateGridProps) => {
    const [nameFilter, setNameFilter] = useState<string>(props.nameFilterValue);
    const [emailIdFilter, setEmailIdFilter] = useState<string>(props.emailIdFilterValue);
    const [jobCodeFilter, setJobCodeFilter] = useState<string>(props.jobCodeFilterValue);

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const columnsCandidates = [
        { key: "UniqueId", text: "UniqueId", sort: true, searchable: false },
        { key: "CandidateName", text: "Candidate Name", sort: true, searchable: true },
        { key: "EmailId", text: "Email Id", sort: true, searchable: true },
        { key: "MobileNumber", text: "Mobile Number", sort: true, searchable: false },
        { key: "PrimarySkillName", text: "Primary Skill", sort: true, searchable: false },
        { key: "JobCode", text: "JobCode", sort: true, searchable: false },
        { key: "InterviewDate", text: "Interview Dt", sort: false, searchable: false },
        { key: "TotalRating", text: "Rating", sort: false, searchable: false },
        { key: "IsReleased", text: "", sort: false, searchable: false }
    ];

    const releaseCandidate = (row: ReleaseCandidatesModel) => {
        let objReleaseCandidate: ReleaseCandidatesRequestModel = {
            candidateId: row.candidateId,
            profileId: row.profileId,
            userId: GuidEnum.Empty
        };
        props.onReleaseCandidateAction(objReleaseCandidate);
    };

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        if (key === "CandidateName") {
            setNameFilter(value);
        } else if (key === "EmailId") {
            setEmailIdFilter(value);
        } else {
            setJobCodeFilter(value);
        }
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsCandidates.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={column.key === "CandidateName" ? nameFilter : column.key === "EmailId" ? emailIdFilter : jobCodeFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.candidateList.map((candidate: ReleaseCandidatesModel, index) => (
                                <TableRow>
                                    <TableCell>
                                        <span>{candidate.uniqueId}</span><a title="copy" onClick={() => { copyClipboard(candidate.uniqueId); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{candidate.candidateName}</TableCell>
                                    <TableCell>{candidate.emailId}</TableCell>
                                    <TableCell>
                                        <span>{candidate.mobileNumber}</span><a title="copy" onClick={() => { copyClipboard(candidate.mobileNumber.toString()); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{candidate.primarySkillName}</TableCell>
                                    <TableCell>{candidate.jobCode}</TableCell>
                                    <TableCell>{candidate.interviewDate}</TableCell>
                                    <TableCell>{candidate.totalRating}</TableCell>
                                    <TableCell>
                                        {candidate.totalRating &&
                                            <a onClick={() => { releaseCandidate(candidate); }} title="Release Candidate">
                                                <i className="fa fa-paper-plane grid-icon blue"></i>
                                            </a>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default ReleaseCandidateGridComponent;
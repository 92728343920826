import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import CandidateAdminModel from "../../../../models/admin/candidate/response/CandidateAdminModel";
import EncryptUtil from '../../../../utils/encode-utils';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import copyClickboardUtils from "../../../../utils/copy-clipboard";

interface ByPassPaymentGatewayGridProps {
    candidateList: CandidateAdminModel[];
}
const CandidatePasswordGridComponent = (props: ByPassPaymentGatewayGridProps) => {

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const columnsCandidates = [
        { key: "UniqueId", text: "UniqueId", sort: false, searchable: false },
        { key: "CandidateName", text: "Name", sort: false, searchable: false },
        { key: "EmailId", text: "Email Id", sort: false, searchable: false },
        { key: "CandidatePassword", text: "Password", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsCandidates.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.candidateList.map((row: CandidateAdminModel, index) => (
                                <TableRow>
                                    <TableCell>{row.uniqueId}</TableCell>
                                    <TableCell>{row.candidateName}</TableCell>
                                    <TableCell>{row.emailId}</TableCell>
                                    <TableCell>
                                        <span>{EncryptUtil.getDecoded(row.candidatePassword)}</span><a title="copy" onClick={() => { copyClipboard(EncryptUtil.getDecoded(row.candidatePassword)); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default CandidatePasswordGridComponent;
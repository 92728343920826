import { useState, useEffect } from "react";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import RelatedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/RelatedProfilesRequestModel";
import ShortlistedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/ShortlistedProfilesGridModel";
import companyClasses from "../../../landing-pages/company/company.module.css";
import ShortlistedProfileStatusUpdateRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/ShortlistedProfileStatusUpdateRequestModel";
import TablePagination from "@mui/material/TablePagination";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";

interface ShortlistedProfilesGridProps {
    shortlistedProfilesList: ShortlistedProfilesGridModel[];
    requirementId: string;
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    candidateNameFilterValue: string;
    onStatusUpdate: (row: ShortlistedProfileStatusUpdateRequestModel) => void;
    onRatingDetails: (row: RelatedProfilesRequestModel, hasAccess: boolean) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
};

const ShortlistedProfilesGridComponent = (props: ShortlistedProfilesGridProps) => {
    const [candidateNameFilter, setCandidateNameFilter] = useState<string>(props.candidateNameFilterValue);

    const updateStatus = (objProfile: ShortlistedProfilesGridModel) => {
        let updateModel: ShortlistedProfileStatusUpdateRequestModel = {
            companySelectedProfileId: objProfile.companySelectedProfileId,
            statusId: objProfile.statusId,
            followUpDate: new Date(objProfile.followUpDate),
            comments: objProfile.comments,
            userId: GuidEnum.Empty
        }
        props.onStatusUpdate(updateModel);
    };

    const viewRating = (objProfile: ShortlistedProfilesGridModel) => {
        let updateModel: RelatedProfilesRequestModel = {
            requirementId: objProfile.requirementId,
            candidateId: objProfile.candidateId,
            interviewId: objProfile.interviewId,
            userId: GuidEnum.Empty
        }
        props.onRatingDetails(updateModel, objProfile.restrictEmployerToViewProfile);
    };

    const columnsShortlistedProfile = [
        { key: "CandidateName", text: "Candidate Name", sort: true, searchable: true },
        { key: "Experience", text: "Experience", sort: true, searchable: false },
        { key: "Status", text: "Status", sort: true, searchable: false },
        { key: "InterviewDate", text: "Interview Date", true: false, searchable: false },
        { key: "TotalRating", text: "Rating", sort: true, searchable: false },
        { key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const filterOnChange = (key: string, value: string) => {
        setCandidateNameFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsShortlistedProfile.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input
                                                type="text"
                                                placeholder="search"
                                                className="mat-header-filter-input"
                                                value={candidateNameFilter}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => filterOnChange(column.key, e.target.value)}
                                            />
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.shortlistedProfilesList.map((profile: ShortlistedProfilesGridModel, index) => (
                                <TableRow >
                                    <TableCell>{profile.candidateName}</TableCell>
                                    <TableCell>{profile.experience}</TableCell>
                                    <TableCell>{profile.statusName}</TableCell>
                                    <TableCell>{profile.interviewDate}</TableCell>
                                    <TableCell>{profile.totalRating}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { viewRating(profile); }} className={companyClasses.jobPostinglinks} title="View Rating">
                                            <i className="fa fa-eye grid-icon blue"></i>
                                        </a>
                                        <a onClick={() => { updateStatus(profile); }} title="update status">
                                            <i className="fa fa-sync grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default ShortlistedProfilesGridComponent;
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import jobPostingService from "../../../services/company/job-posting-service";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import JobPostingInterviewProcessPopUp from "./JobPostingInterviewProcessCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import JobPostingRequestModel from "../../../models/company/jobPosting/request/JobPostingRequestModel";
import JobPostingInterviewProcessGridComponent from "./JobPostingInterviewProcessGridComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import CompanyConfigurationModel from "../../../models/company/CompanyConfigurationModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import InterviewProcessModel from "../../../models/company/jobPosting/response/InterviewProcessModel";
import JobPostingInterviewProcessRequestModel from "../../../models/company/jobPosting/request/JobPostingInterviewProcessRequestModel";
import loaderUtils from "../../../utils/loader-utils";
import JobPostingSkillRequestModel from "../../../models/company/jobPosting/request/JobPostingSkillRequestModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";

interface JobPostingProps {
    companyConfiguration: CompanyConfigurationModel;
    jobPostingRequestModel: JobPostingRequestModel;
    secondarySkillList: IdNameModel[];
    interviewRoundList: IdNameModel[];
};

const JobPostingInterviewProcessComponent = (props: JobPostingProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [jobPostingInterviewProcessList, setJobPostingInterviewProcessList] = useState<InterviewProcessModel[]>([]);
    const [refreshJobPostingInterviewProcess, setRefreshJobPostingInterviewProcess] = useState<number>(2);

    useEffect(() => {
        getJobInterviewProcess();
    }, []);

    const getJobInterviewProcess = () => {
        if (props.jobPostingRequestModel !== null) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            jobPostingService.getInterviewProcesses(props.jobPostingRequestModel.requirementId).then((res) => {
                modalUtils.closeModal();
                setJobPostingInterviewProcessList([]);
                setJobPostingInterviewProcessList(res.items);
                setRefreshJobPostingInterviewProcess(refreshJobPostingInterviewProcess * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const openJobPostingInterviewProcessSaveModel = (jobPostingRequestModel: JobPostingInterviewProcessRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add JobPosting",
            content: (
                <JobPostingInterviewProcessPopUp
                    objJobPostingInterviewProcess={jobPostingRequestModel}
                    actionType={actionType}
                    secondarySkillList={props.secondarySkillList}
                    interviewRoundList={props.interviewRoundList}
                    onCloseJobPosting={onCloseJobPostingHandler}
                    onJobPostingInterviewProcessSave={onJobPostingInterviewProcessSaveHandler}
                />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseJobPostingHandler = () => {
        modalUtils.closeModal();
    };

    const onJobPostingInterviewProcessSaveHandler = (objJobPostingInterviewProcess: JobPostingInterviewProcessRequestModel) => {
        setRefreshJobPostingInterviewProcess(2);
        if (loggedInUser) {
            objJobPostingInterviewProcess.recruiterId = loggedInUser.profileId;
            objJobPostingInterviewProcess.userId = loggedInUser.userId;
            objJobPostingInterviewProcess.requirementId = props.jobPostingRequestModel.requirementId;
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            jobPostingService.saveInterviewProcess(objJobPostingInterviewProcess).then((res) => {
                modalUtils.closeModal();
                if (res.data === false) {
                    alertUtils.showError(res.errorMessages.toString());
                } else {
                    alertUtils.showSuccess(res.informationMessages.toString());
                    getJobInterviewProcess();
                }
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onJobPostingInterviewProcessActionHandler = (obj: JobPostingInterviewProcessRequestModel, actionType: string) => {
        if (actionType === PageModeEnum.Edit) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            jobPostingService.getInterviewProcessSkills(obj.requirementInterviewProcessId).then((res) => {
                modalUtils.closeModal();
                obj.jobPostingSkillRequestModel = res.items;
                openJobPostingInterviewProcessSaveModel(obj, actionType);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            obj.recruiterId = props.jobPostingRequestModel.requirementId;
            openJobPostingInterviewProcessSaveModel(obj, actionType);
        }
    };

    const addJobPostingInterviewProcess = () => {
        if (loggedInUser !== null) {
            let baseSkillDetails: JobPostingSkillRequestModel[] = [];
            let minSkills: number = 3;
            for (let i = 1; i <= minSkills; i++) {
                let skill: JobPostingSkillRequestModel = {
                    requirementSkillId: GuidEnum.Empty,
                    requirementId: GuidEnum.Empty,
                    secondarySkillId: null,
                    secondarySkillNumber: i,
                    isCommentsRequired: true,
                    requirementInterviewProcessId: GuidEnum.Empty,
                    recruiterId: GuidEnum.Empty,
                    userId: GuidEnum.Empty
                };
                baseSkillDetails.push(skill);
            }
            let jobPostingInterviewProcessRequestModel: JobPostingInterviewProcessRequestModel = {
                requirementInterviewProcessId: GuidEnum.Empty,
                requirementId: GuidEnum.Empty,
                interviewRoundId: null,
                recruiterId: loggedInUser.profileId,
                userId: loggedInUser.userId,
                jobPostingSkillRequestModel: baseSkillDetails
            }
            onJobPostingInterviewProcessActionHandler(jobPostingInterviewProcessRequestModel, PageModeEnum.Create);
        }
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getJobInterviewProcess()}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addJobPostingInterviewProcess}> + Add Interview Process</a>
            </div>
            <div className="card-body">
                {refreshJobPostingInterviewProcess > 2 &&
                    <JobPostingInterviewProcessGridComponent
                        key={refreshJobPostingInterviewProcess}
                        jobPostingInterviewProcessList={jobPostingInterviewProcessList}
                        onJobPostingInterviewProcessAction={onJobPostingInterviewProcessActionHandler} />
                }
            </div>
        </div>
    );
};
export default JobPostingInterviewProcessComponent;
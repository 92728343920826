import { useState, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import AdminCompanyMappingModel from "../../../models/admin/dashboard/response/AdminCompanyMappingModel";
import AdminUserCompanyMappingRequestModel from "../../../models/admin/dashboard/request/AdminUserCompanyMappingRequestModel";
import DataGrid from "../../common/table/DataGrid";
import IdNameModel from "../../../models/common/IdNameModel";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface AdminUserProps {
    mappingList: AdminCompanyMappingModel[];
    adminId: string;
    companyList: IdNameModel[];
    onCloseMapping: () => void;
    onMappingSave: (objMapping: AdminUserCompanyMappingRequestModel) => void;
}

const AdminCompanyMappingComponent = (props: AdminUserProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<AdminUserCompanyMappingRequestModel>({
        mode: "onChange"
    });

    const onSubmit = (formData: AdminUserCompanyMappingRequestModel) => {
        formData.adminId = props.adminId;
        formData.userId = GuidEnum.Empty;
        props.onMappingSave(formData);
    }

    const columnsMapping = [
        { key: "AdminName", text: "Admin Name", sort: false, searchable: false },
        { key: "CompanyName", text: "Company Name", sort: false, searchable: false }
    ];

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Company: <span className="required">*</span> </label>
                                    <select className="form-control form-select" {...register("companyId", { required: true })}>
                                        <option value="">Select Company</option>
                                        {props.companyList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.guId}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.companyId && errors.companyId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columnsMapping.map((column: any) => (
                                            <TableCell key={column.key} className="table-header-cell">
                                                <span>{column.text}</span>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.mappingList.map((row: AdminCompanyMappingModel, index) => (
                                        <TableRow>
                                            <TableCell>{row.adminName}</TableCell>
                                            <TableCell>{row.companyName}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AdminCompanyMappingComponent;
import React, { useState, useEffect } from "react";
import SecondarySkillMappingCreateComponent from "./SecondarySkillMappingCreateComponent";
import SecondarySkillMappingGridComponent from "./SecondarySkillMappingGridComponent";
import SecondarySkillTopicModel from "../../../../models/admin/skill/response/SecondarySkillTopicModel";
import modalUtils from "../../../../utils/modal-utils";
import SecondarySkillTopicRequestModel from "../../../../models/admin/skill/request/SecondarySkillTopicRequestModel";
import loaderUtils from "../../../../utils/loader-utils";
import adminSkillService from "../../../../services/admin/admin-skill-service";
import alertUtils from "../../../../utils/toaster-utils";

interface SecondarySkillTopicProps {
    secondarySkillId: number;
};

const SecondarySkillMappingComponent = (props: SecondarySkillTopicProps) => {
    const [refreshSecondarySkillMapping, setRefreshSecondarySKillMapping] = useState<number>(2);
    const [secondarySkillMappingList, setSecondarySkillMappingList] = useState<SecondarySkillTopicModel[]>([]);
    useEffect(() => {
        getSecondarySkillMappings();
    }, []);

    const getSecondarySkillMappings = () => {
        setRefreshSecondarySKillMapping(2);
        //modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminSkillService.getTopics(props.secondarySkillId).then((res) => {
            //modalUtils.closeModal();
            setSecondarySkillMappingList(res.items);
            setRefreshSecondarySKillMapping(refreshSecondarySkillMapping * 5);
        }).catch((error) => {
            //modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onSaveTopicHandler = (secondarySkillTopicModel: SecondarySkillTopicRequestModel) => {
        //modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminSkillService.saveTopic(secondarySkillTopicModel).then((res) => {
            //modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getSecondarySkillMappings();
        }).catch((error) => {
            //modalUtils.closeModal();
            alertUtils.showError(error);
        });
    }

    const onTopicDeleteActionHandler = (secondarySkillTopic: SecondarySkillTopicModel) => {
        if (!secondarySkillTopic) {
            return;
        }
        const topicId = secondarySkillTopic.topicId;
        //modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminSkillService.deleteTopic(topicId).then((res) => {
            //modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getSecondarySkillMappings();
        }).catch((error) => {
            //modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <SecondarySkillMappingCreateComponent
                    secondarySkillId={props.secondarySkillId}
                    onSecondarySkillTopicSave={onSaveTopicHandler} />

                {refreshSecondarySkillMapping > 2 &&
                    <SecondarySkillMappingGridComponent
                        mappingList={secondarySkillMappingList}
                        onTopicDelteAction={onTopicDeleteActionHandler}
                    />
                }
            </div>
        </div>
    )
};
export default SecondarySkillMappingComponent;
import { useState, MouseEvent } from "react";
import EditRatingModel from "../../../../models/admin/interviews/response/EditRatingModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface EditRatingGridProps {
    interviewList: EditRatingModel[];
    onRatingEditAction: (interviewId: string) => void;
}

const EditRatingGridComponent = (props: EditRatingGridProps) => {
    const ActionFormatter = (
        cell: any,
        row: EditRatingModel,
        rowIndex: number
    ) => {
        return (
            <div className="text-center-align">                
                <a onClick={() => { updateRating(row); }}>Update</a>
            </div>
        );
    };

    const updateRating = (row: EditRatingModel) => {
        props.onRatingEditAction(row.interviewId);
    };

    const columnsInterviews = [
        { key: "InterviewUniqueId", text: "Interview Id", sort: false, searchable: false },
        { key: "InterviewDate", text: "Interview Dt", sort: false, searchable: false },
        { key: "CandidateName", text: "Name", sort: false, searchable: false },
        { key: "EmailId", text: "Email Id", sort: false, searchable: false },
        { key: "MobileNumber", text: "Mobile No", sort: false, searchable: false },
        { key: "InterviewerName", text: "Interviewer", sort: false, searchable: false },
        { key: "PrimarySkillName", text: "Primary Skill", sort: false, searchable: false },
        { key: "TotalRating", text: "Rating", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false, formatter: ActionFormatter }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
            <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsInterviews.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.interviewList.map((row: EditRatingModel, index) => (
                                <TableRow>
                                    <TableCell>{row.interviewUniqueId}</TableCell>
                                    <TableCell>{row.interviewDate}</TableCell>
                                    <TableCell>{row.candidateName}</TableCell>
                                    <TableCell>{row.emailId}</TableCell>
                                    <TableCell>{row.mobileNumber}</TableCell>
                                    <TableCell>{row.interviewerName}</TableCell>
                                    <TableCell>{row.primarySkillName}</TableCell>
                                    <TableCell>{row.totalRating}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { updateRating(row); }} title="Update">
                                            <i className="far fa-star grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default EditRatingGridComponent;
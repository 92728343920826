import React, { useState, useEffect } from "react";
import InterviewerSchedulesModel from "../../../models/interviewer/response/InterviewerSchedulesModel";
import dateUtils from "../../../utils/date-utils";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import modalUtils from "../../../utils/modal-utils";
import CloneSchedulePopUp from "./CloneScheduleComponent";
import { DayofWeekEnum } from "../../../models/enums/DayofWeekEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface InterviewerProfileProps {
    interviewerSchedule: InterviewerSchedulesModel;
}

const InterviewerScheduleGridComponent = (props: InterviewerProfileProps) => {
    let weekdays: string[] = [DayofWeekEnum.Monday.toString(), DayofWeekEnum.Tuesday.toString(), DayofWeekEnum.Wednesday.toString(), DayofWeekEnum.Thursday.toString(), DayofWeekEnum.Friday.toString()];
    let weekEnds: string[] = [DayofWeekEnum.Sunday.toString(), DayofWeekEnum.Saturday.toString()];

    const columnsInterviewerSchedule = [
        { key: "Date", text: "Date", sort: false, searchable: false },
        { key: "TimeSlots", text: "Time Slots", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false }
    ];

    const cloneSchedulesPopUp = (row: InterviewerSchedulesModel) => {
        const config: GeneralPoupConfig = {
            title: "Clone Schedules",
            content: (
                <CloneSchedulePopUp
                    schedule={row} />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsInterviewerSchedule.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{dateUtils.getFormattedDateFromDate(props.interviewerSchedule.date) + "-" + dateUtils.getDayFromDate(props.interviewerSchedule.date)}</TableCell>
                                <TableCell>{props.interviewerSchedule.timeSlots}</TableCell>
                                <TableCell>
                                    {props.interviewerSchedule.timeSlots && weekdays.indexOf(dateUtils.getDayFromDate(props.interviewerSchedule.date)) > -1 &&
                                        <div>
                                            <a onClick={() => { cloneSchedulesPopUp(props.interviewerSchedule); }}>Clone For All Week Days</a>
                                        </div>
                                    }
                                    {props.interviewerSchedule.timeSlots && weekdays.indexOf(dateUtils.getDayFromDate(props.interviewerSchedule.date)) < 1 &&
                                        <div>
                                            <a onClick={() => { cloneSchedulesPopUp(props.interviewerSchedule); }}>Clone For All Weekends</a>
                                        </div>
                                    }                                   
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default InterviewerScheduleGridComponent;
import { useState, MouseEvent } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";

const AdminDashboardComponent = () => {

    return (
        <div></div>
    );
};
export default AdminDashboardComponent;
import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import AdminInterviewsModel from "../../../../models/admin/interviews/response/AdminInterviewsModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";
import AdminInterviewsSearchInputModel from "../../../../models/admin/interviews/request/AdminInterviewsSearchInputModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import adminInterviewsService from "../../../../services/admin/admin-interviews-service";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import modalUtils from "../../../../utils/modal-utils";
import alertUtils from "../../../../utils/toaster-utils";
import InterviewsGridComponent from "./InterviewsGridComponent";
import UpdateInterviewRequestModel from "../../../../models/admin/interviews/request/UpdateInterviewRequestModel";
import UpdateInterviewPopUp from "./UpdateInterviewComponent";
import adminDashboardService from "../../../../services/admin/admin-dashboard-service";
import RatingDetailsPopUp from "../../../candidate/dashboard/RatingDetailsComponent";
import loaderUtils from "../../../../utils/loader-utils";
import masterDataService from "../../../../services/master-data-service";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import CanidateStatusRequestModel from "../../../../models/admin/candidate/request/CanidateStatusRequestModel";
import TodayFollowupUpdateComponent from "../../candidate/today-followups/TodayFollowupUpdateComponent";
import adminCandidateService from "../../../../services/admin/admin-candidate-service";
import { GuidEnum } from "../../../../models/enums/GuidEnum";

interface InterviewsProps {
    primarySkillsList: IdNameModel[];
    timeSlotsList: IdNameModel[];
    interviewerList: IdNameModel[];
    cityList: IdNameModel[];
    statusList: IdNameModel[];
};

const InterviewsComponent = (props: InterviewsProps) => {
    const [candidatePagedList, setCandidatePagedList] = useState<PagedListModel<AdminInterviewsModel>>();
    const [candidateList, setCandidateList] = useState<AdminInterviewsModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(dateTimeUtils.addDays(new Date(), -1) as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [interviewerSearch, setInterviewerSearch] = useState<string>("");
    const [citySearch, setCitySearch] = useState<number>(0);
    const [registeredCandidatesSearch, setRegisteredCandidatesSearch] = useState<boolean>(false);
    const [ratedCandidatesSearch, setRatedCandidatesSearch] = useState<boolean>(false);
    const [totalRating, setTotalRating] = useState<string>("");
    const [nameFilter, setNameFilter] = useState<string>("");
    const [companyNameFilter, setCompanyFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        clearSearch();
        getCandidates(getSearchFilters(nameFilter, companyNameFilter, totalRating, page, pageSize, sortColumn, sortDirection))
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getSearchFilters = (nameFilter: string, companyNameFilter: string, totalRating: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: AdminInterviewsSearchInputModel = {
            startDate: dateTimeUtils.getFormattedDate(startDateSearch),
            endDate: dateTimeUtils.getFormattedDate(endDateSearch),
            primarySkillId: primarySKillSearch,
            interviewerId: interviewerSearch === "" ? null : interviewerSearch,
            cityId: citySearch,
            registeredCandidates: registeredCandidatesSearch,
            ratedCandidates: ratedCandidatesSearch,
            name: nameFilter,
            totalRating: totalRating === "" ? null : totalRating,
            companyName: companyNameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getCandidates = (inputData: AdminInterviewsSearchInputModel) => {
        setRefreshCandidate(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviews(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidatePagedList(res);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);
        }).catch((error) => {
            setRefreshCandidate(refreshCandidate * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const clearSearch = () => {
        setCandidateList([]);
        setStartDateSearch(dateTimeUtils.addDays(new Date(), -1));
        setEndDateSearch(new Date());
        setPrimarySkillSearch(0);
        setInterviewerSearch("");
        setCitySearch(0);
        setRefreshCandidate(2);
    };

    const onUpdateInterviewActionHandler = (objInterview: UpdateInterviewRequestModel, primarySkillId: number) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviewersByPrimarySkill(primarySkillId).then((res) => {
            modalUtils.closeModal();
            const config: GeneralPoupConfig = {
                title: "Add User",
                content: (
                    <UpdateInterviewPopUp
                        objInterview={objInterview}
                        interviewerList={res.items.length > 0 ? res.items : props.interviewerList}
                        timeSlotList={props.timeSlotsList}
                        onRescheduleInterviewAction={onRescheduleInterviewActionHandler}
                        onCloseUpdateInterview={onCloseUpdateInterviewActionHandler}
                    />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onCloseUpdateInterviewActionHandler = () => {
        modalUtils.closeModal();
    };

    const onRescheduleInterviewActionHandler = (objInterview: UpdateInterviewRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminInterviewsService.updateInterview(objInterview).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getCandidates(getSearchFilters(nameFilter, companyNameFilter, totalRating, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onViewRatingActionHandler = (interviewId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminDashboardService.interviewRatingDetails(interviewId).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                const config: GeneralPoupConfig = {
                    title: "Rating Details",
                    content: (
                        <RatingDetailsPopUp objRatingDetails={res.data} />
                    ),
                    size: ModalSize.XL,
                    className: "model-rating-details-badge"
                };
                modalUtils.showPopup(config);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Eror fetching Schedules");
        });
    };

    const handleRegisteredCandidatesSearch = (): void => {
        setRegisteredCandidatesSearch(!registeredCandidatesSearch);
    };

    const handleRatedCandidatesSearch = (): void => {
        setRatedCandidatesSearch(!ratedCandidatesSearch);
    };

    const onUpdateFollowupActionHandler = (candidateId: string) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminCandidateService.getCandidateStatus(candidateId).then((res) => {
            modalUtils.closeModal();
            let objCandidate: CanidateStatusRequestModel;
            if (res.data !== null) {
                objCandidate = res.data
            } else {
                objCandidate = {
                    candidateId: candidateId,
                    statusId: 0,
                    comments: "",
                    followUpDate: new Date,
                    profileId: null,
                    userId: GuidEnum.Empty
                };
            }
            const config: GeneralPoupConfig = {
                title: "update Status",
                content: (
                    <TodayFollowupUpdateComponent
                        objCandidate={objCandidate}
                        statusList={props.statusList}
                        onFollowupClose={onFollowupCloseHandler}
                        onFollowupUpdate={onFollowUpUpdateHandler}
                    />
                ),
                size: ModalSize.LG,
                className: "model-min-height-500"
            };
            modalUtils.showPopup(config);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onFollowupCloseHandler = () => {
        modalUtils.closeModal();
    };

    const onFollowUpUpdateHandler = (objCandidate: CanidateStatusRequestModel) => {
        setRefreshCandidate(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        adminCandidateService.saveCandidateStatus(objCandidate).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getCandidates(getSearchFilters(nameFilter, companyNameFilter, totalRating, page, pageSize, sortColumn, sortDirection))
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getCandidates(getSearchFilters(nameFilter, companyNameFilter, totalRating, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getCandidates(getSearchFilters(nameFilter, companyNameFilter, totalRating, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getCandidates(getSearchFilters(nameFilter, companyNameFilter, totalRating, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        if (filterColumn === "CandidateName") {
            setNameFilter(filterValue);
            getCandidates(getSearchFilters(filterValue, companyNameFilter, totalRating, 0, pageSize, sortColumn, sortDirection));
        } else {
            setCompanyFilter(filterValue);
            getCandidates(getSearchFilters(nameFilter, filterValue, totalRating, 0, pageSize, sortColumn, sortDirection));
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-2">
                        <label>Start Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <label>End Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillsList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Interviewer</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setInterviewerSearch(e.target.value)}>
                            <option value="">All</option>
                            {props.interviewerList.map((item, index) => {
                                return (
                                    <option key={index} value={item.guId}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Location</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setCitySearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.cityList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-success" type="submit" onClick={() => getCandidates(getSearchFilters(nameFilter, companyNameFilter, totalRating, page, pageSize, sortColumn, sortDirection))}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="quform-element form-group">
                            <div className="quform-input">
                                <input type="checkbox" checked={registeredCandidatesSearch} onChange={handleRegisteredCandidatesSearch} />&nbsp;&nbsp;
                                <label> Registered Candidates </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="quform-element form-group">
                            <div className="quform-input">
                                <input type="checkbox" checked={ratedCandidatesSearch} onChange={handleRatedCandidatesSearch} />&nbsp;&nbsp;
                                <label> Rated Candidates </label>
                            </div>
                        </div>
                    </div>
                </div>
                {candidateList.length > 0 && refreshCandidate > 2 &&
                    <InterviewsGridComponent
                        key={refreshCandidate}
                        candidateList={candidateList}
                        totalSize={candidatePagedList?.totalCount ? candidatePagedList?.totalCount : candidateList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        nameFilterValue={nameFilter}
                        companyNameFilterValue={companyNameFilter}
                        onUpdateInterviewAction={onUpdateInterviewActionHandler}
                        onViewRatingAction={onViewRatingActionHandler}
                        onUpdateFollowupAction={onUpdateFollowupActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler}
                    />
                }
            </div>
        </div>
    );
};
export default InterviewsComponent;
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import { useForm } from "react-hook-form";
import PrimarySkillRequestModel from "../../../../models/admin/skill/request/PrimarySkillRequestModel";
import { OBJECT_NAME_RULE, OBJECT_DESCRIPTION_RULE } from "../../../../utils/validation-utils";

interface PrimarySkillProps {
    objPrimarySkill: PrimarySkillRequestModel;
    actionType: string;    
    onClosePrimarySkill: () => void;
    onPrimarySkillSave: (objPrimarySkill: PrimarySkillRequestModel) => void;
}

const PrimarySkillCreateComponent = (props: PrimarySkillProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<PrimarySkillRequestModel>({
        mode: "onChange",
        defaultValues: props.objPrimarySkill
    });

    const onSubmit = (formData: PrimarySkillRequestModel) => {
        props.onPrimarySkillSave(formData);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Primary Skill Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("primarySkillName", OBJECT_NAME_RULE)} placeholder="Primary Skill Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.primarySkillName && errors.primarySkillName.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Description: </label>
                                    <textarea rows={3} className="form-control" {...register("description", OBJECT_DESCRIPTION_RULE)} placeholder="Description" autoComplete="off" />
                                    <span className="error">
                                        {errors.description && errors.description.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onClosePrimarySkill()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default PrimarySkillCreateComponent;
import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import RegisteredCandidatesModel from "../../../../models/admin/candidate/response/RegisteredCandidatesModel";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import alertUtils from "../../../../utils/toaster-utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface RegisteredCandidatesGridProps {
    candidateList: RegisteredCandidatesModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    nameFilterValue: string;
    emailIdFilterValue: string;
    onDownloadResumeAction: (candidateId: string, candidateName: string) => void;
    onScheduleAction: (candidateId: string) => void;
    onVerifyAction: (candidateId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const RegisteredCandidatesGridComponent = (props: RegisteredCandidatesGridProps) => {
    const [nameFilter, setNameFilter] = useState<string>(props.nameFilterValue);
    const [emailIdFilter, setEmailIdFilter] = useState<string>(props.emailIdFilterValue);

    const downloadResume = (candidateId: string, candidateName: string) => {
        props.onDownloadResumeAction(candidateId, candidateName);
    };

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const scheduleInterview = (candidateId: string, isVerified: boolean) => {
        if (isVerified) {
            props.onScheduleAction(candidateId);
        } else {
            alertUtils.showWarning("Candidate Is not verified.");
        }
    };

    const verifyUser = (candidateId: string) => {
        props.onVerifyAction(candidateId);
    };

    const columnsCandidates = [
        { key: "UniqueId", text: "UniqueId", sort: false, searchable: false },
        { key: "CandidateName", text: "Name", sort: true, searchable: true },
        { key: "EmailId", text: "Email Id", sort: true, searchable: true },
        { key: "MobileNumber", text: "Mobile No", sort: true, searchable: false },
        { key: "PrimarySkillName", text: "Primary Skill", sort: true, searchable: false },
        { key: "ExperienceName", text: "Experience", sort: true, searchable: false },
        { key: "RegisteredDate", text: "RegisteredDate", sort: true, searchable: false },
        { key: "IsVerified", text: "Verified", sort: false, searchable: false },
        { key: "InterviewDate", text: "Interview Date", sort: false, searchable: false },
        { key: "TotalRating", text: "Rating", sort: false, searchable: false },
        { key: "Actions", text: "", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        if (key === "CandidateName") {
            setNameFilter(value);
        } else {
            setEmailIdFilter(value);
        }
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsCandidates.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={column.key === "CandidateName" ? nameFilter : emailIdFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.candidateList.map((candidate: RegisteredCandidatesModel, index) => (
                                <TableRow>
                                    <TableCell>
                                        <span>{candidate.uniqueId}</span><a title="copy" onClick={() => { copyClipboard(candidate.uniqueId); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{candidate.candidateName}</TableCell>
                                    <TableCell>{candidate.emailId}</TableCell>
                                    <TableCell>
                                        <span>{candidate.mobileNumber}</span><a title="copy" onClick={() => { copyClipboard(candidate.mobileNumber.toString()); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{candidate.primarySkillName}</TableCell>
                                    <TableCell>{candidate.experienceName}</TableCell>
                                    <TableCell>{candidate.registeredDate}</TableCell>
                                    <TableCell>{candidate.isVerified}</TableCell>
                                    <TableCell>{candidate.interviewDate}</TableCell>
                                    <TableCell>{candidate.totalRating}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { downloadResume(candidate.candidateId, candidate.candidateName); }} title="Download Resume">
                                            <i className="fa fa-download grid-icon blue"></i>
                                        </a>
                                        {!candidate.isVerified &&
                                            <a onClick={() => { verifyUser(candidate.candidateId); }} title="Authorize">
                                                <i className="fa fa-check grid-icon blue"></i>
                                            </a>
                                        }
                                        {!candidate.isInterviewScheduled &&
                                            <a onClick={() => { scheduleInterview(candidate.candidateId, candidate.isVerified); }} title="Schedule">
                                                <i className="fa fa-calendar grid-icon blue"></i>
                                            </a>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default RegisteredCandidatesGridComponent;
import React, { useState, useEffect } from "react";
import CandidateStatusSearchInputModel from "../../../../models/admin/candidate/request/CandidateStatusSearchInputModel";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";
import alertUtils from "../../../../utils/toaster-utils";
import CandidateStatusModel from "../../../../models/admin/candidate/response/CandidateStatusModel";
import adminCandidateService from "../../../../services/admin/admin-candidate-service";
import PagedListModel from "../../../../models/common/PagedListModel";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination, CircularProgress } from "@mui/material";

interface CandidateHistoryProps {
    candidateId: string;
};

const CandidateHistoryComponent = (props: CandidateHistoryProps) => {
    const [candidateHistoryList, setCandidateHistoryList] = useState<CandidateStatusModel[]>([]);
    const [candidateHistoryPagedList, setCandidateHistoryPagedList] = useState<PagedListModel<CandidateStatusModel>>();
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("StatusName");
    const [sortDirection, setSortDirection] = useState<number>(SortDirection.Desc);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(page + 1);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadPageSizes();
        getCandidateHistory(getSearchFilters(page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        const inputData: CandidateStatusSearchInputModel = {
            startDate: "",
            endDate: "",
            candidateName: "",
            statusId: 0,
            candidateId: props.candidateId,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getCandidateHistory = (inputData: CandidateStatusSearchInputModel) => {
        setLoading(true);
        adminCandidateService.getCandidateHistory(inputData).then((res) => {
            setCandidateHistoryPagedList(res);
            setCandidateHistoryList(res.items);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            alertUtils.showError(error);
        });
    };

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
        getCandidateHistory(getSearchFilters(newPage, pageSize, sortColumn, sortDirection));
    };

    const handleChangeRowsPerPage = (event: any) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getCandidateHistory(getSearchFilters(PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string) => {
        const newSortDirection = (sortColumn === newSortColumn && sortDirection === SortDirection.Asc) ? SortDirection.Desc : SortDirection.Asc;
        setSortColumn(newSortColumn);
        setSortDirection(newSortDirection);
        getCandidateHistory(getSearchFilters(page, pageSize, newSortColumn, newSortDirection));
    };

    const columnsHistory = [
        { key: "CandidateName", text: "Candidate Name", sort: false },
        // { key: "EmailId", text: "Email Id", sort: false },
        { key: "StatusName", text: "Status Name", sort: true },
        { key: "FollowUpDate", text: "Follow Up Dt", sort: false },
        { key: "Comments", text: "Comments", sort: false },
        { key: "CreatedDate", text: "Created Date", sort: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit', marginTop: "100px" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columnsHistory.map((column) => (
                                        <TableCell key={column.key} className="table-header-cell">
                                            {column.sort && (
                                                <TableSortLabel
                                                    active={sortColumn === column.key}
                                                    direction={sortColumn === column.key ? (sortDirection === SortDirection.Asc ? "asc" : "desc") : "asc"}
                                                    onClick={() => onSortActionHandler(column.key)}
                                                >
                                                    {column.text}
                                                </TableSortLabel>
                                            )}
                                            {!column.sort &&
                                                <span>{column.text}</span>
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {candidateHistoryList.length > 0 && candidateHistoryList.map((candidate: CandidateStatusModel, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{candidate.candidateName}</TableCell>
                                        {/* <TableCell>{candidate.emailId}</TableCell> */}
                                        <TableCell>{candidate.statusName}</TableCell>
                                        <TableCell>{candidate.followUpDateString}</TableCell>
                                        <TableCell>{candidate.comments}</TableCell>
                                        <TableCell>{candidate.createdDate}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={pageSizesList}
                            component="div"
                            count={candidateHistoryPagedList?.totalCount ? candidateHistoryPagedList?.totalCount : candidateHistoryList.length}
                            rowsPerPage={pageSize}
                            page={currentPage - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                )}
            </div>
        </div>
    );
};
export default CandidateHistoryComponent;
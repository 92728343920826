import { useState, useEffect } from "react";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import AddProfilesGridModel from "../../../models/company/addProfiles/response/AddProfilesGridModel";
import AddProfileSearchInputModel from "../../../models/company/addProfiles/request/AddProfileSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import addProfilesService from "../../../services/company/add-profiles-service";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import IdNameModel from "../../../models/common/IdNameModel";
import AddProfilesGridComponent from "./AddProfilesGridComponent";
import AddProfilesRequestModel from "../../../models/company/addProfiles/request/AddProfilesRequestModel";
import AddCandidatePopUp from "./AddCandidateComponent";
import { FileUploadTypeEnum } from "../../../models/enums/FileUploadTypeEnum";
import fileUploadService from "../../../services/file-upload-service";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import AddProfilesStatusUpdateModel from "../../../models/company/addProfiles/request/AddProfilesStatusUpdateModel";
import StatusUpdatePopUp from "./StatusUpdateComponent";
import masterDataService from "../../../services/master-data-service";
import ScheduleInterviewCompanyRequestModel from "../../../models/company/addProfiles/request/ScheduleInterviewCompanyRequestModel";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import CandidateHistoryComponent from "../../admin/candidate/candidate-history/CandidateHistoryComponent";

interface AddProfilesProps {
    vendorList: IdNameModel[];
    statusList: IdNameModel[];    
    requirementId: string;
    profileId: string;
    userId: string;
    canScheduleInterviews: boolean;
};

const AddProfilesComponent = (props: AddProfilesProps) => {
    //let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [addProfilesPagedList, setAddProfilesPagedList] = useState<PagedListModel<AddProfilesGridModel>>();
    const [addProfilesList, setAddProfilesList] = useState<AddProfilesGridModel[]>([]);
    const [refreshAddProfiles, setRefreshAddProfiles] = useState<number>(2);
    const [timeSlots, setTimeSlots] = useState<IdNameModel[]>([]);
    const [candidateNameFilter, setCandidateNameFilter] = useState<string>("");
    const [statusIdFilter, setStatusIdFilter] = useState<number>(0);
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getTimeSlots();
        getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (candidateNameFilter: string, statusId: number, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: AddProfileSearchInputModel = {
            requirementId: props.requirementId,
            statusId: statusId,
            candidateName: candidateNameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        }
        return inputData;
    };

    const getAddProfiles = (inputData: AddProfileSearchInputModel) => {
        setRefreshAddProfiles(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        addProfilesService.getAddProfilesGrid(inputData).then((res) => {
            modalUtils.closeModal();
            setAddProfilesList([]);
            setAddProfilesPagedList(res);
            setAddProfilesList(res.items);
            setRefreshAddProfiles(refreshAddProfiles * 5);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const getTimeSlots = () => {
        masterDataService.loadTimeSlots().then((res) => {
            if (res.isSuccess) {
                setTimeSlots(res.items);
            } else {
                alertUtils.showError("Unable to load Primary skills");
            }
        }).catch((error) => {
            alertUtils.showError("Erorr fetching Primary skills");
        });
    };

    /* Start - Add Candidate Flow */
    const openAddProfileSaveModel = (addProfileRequestModel: AddProfilesRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Candidate",
            content: (
                <AddCandidatePopUp
                    objAddProfile={addProfileRequestModel}
                    vendorList={props.vendorList}
                    onCloseAddProfile={onCloseAddProfileHandler}
                    onAddProfileSave={onAddProfileSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseAddProfileHandler = () => {
        modalUtils.closeModal();
    };

    const onAddProfileSaveHandler = (objAddProfile: AddProfilesRequestModel, resume: any) => {
        setRefreshAddProfiles(2);
        objAddProfile.requirementId = props.requirementId;
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        addProfilesService.addRequirementProfile(objAddProfile).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            const data = new FormData();
            data.append("file", resume.target.files[0]);
            data.append("fileName", resume.target.files[0].name);
            data.append("userId", res.data.profileId);
            data.append("fileUploadType", FileUploadTypeEnum.CandidateResume.toString());
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            fileUploadService.uploadFile(data).then((res) => {
                modalUtils.closeModal();
                alertUtils.showSuccess(res.informationMessages.toString());
                getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, page, pageSize, sortColumn, sortDirection));
            }).catch((error) => {
                setRefreshAddProfiles(refreshAddProfiles * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }).catch((error) => {
            setRefreshAddProfiles(refreshAddProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onProfileAddActionHandler = (actionType: string) => {
        let addProfileRequestModel: AddProfilesRequestModel = {
            requirementId: props.requirementId,
            userId: GuidEnum.Empty,
            name: "",
            userName: "",
            emailId: "",
            mobileNumber: null,
            vendorId: ""
        };
        openAddProfileSaveModel(addProfileRequestModel, actionType);
    };

    /* End - Add Candidate Flow */

    /* Start - Status Update Flow */
    const openStatusUpdateModel = (statusUpdateRequestModel: AddProfilesStatusUpdateModel) => {
        const config: GeneralPoupConfig = {
            title: "Update Status",
            content: (
                <StatusUpdatePopUp
                    objStatusUpdate={statusUpdateRequestModel}
                    statusList={props.statusList}
                    onCloseStatusUpdate={onCloseStatusUpdateHandler}
                    onStatusUpdate={onStatusUpdateHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseStatusUpdateHandler = () => {
        modalUtils.closeModal();
    };

    const onStatusUpdateHandler = (objStatusUpdate: AddProfilesStatusUpdateModel) => {
        setRefreshAddProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        addProfilesService.updateProfileStatus(objStatusUpdate).then((res) => {
            modalUtils.closeModal();
            alertUtils.showSuccess(res.informationMessages.toString());
            getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            setRefreshAddProfiles(refreshAddProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onStatusUpdateActionHandler = (objProfile: AddProfilesGridModel) => {
        let statusUpdateRequestModel: AddProfilesStatusUpdateModel = {
            profileId: objProfile.profileId,
            userId: GuidEnum.Empty,
            statusId: objProfile.statusId,
            comments: objProfile.comments
        };
        openStatusUpdateModel(statusUpdateRequestModel);
    };
    /* End - Status Update Flow */

    /* Start - Schedule Interview Flow */
    const onScheduleInterviewActionHandler = (objScheduleInterview: ScheduleInterviewCompanyRequestModel) => {
        setRefreshAddProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        addProfilesService.scheduleInterview(objScheduleInterview).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, page, pageSize, sortColumn, sortDirection));
            modalUtils.closeModal();
        }).catch((error) => {
            setRefreshAddProfiles(refreshAddProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onRatingDetailsHandler = (interviewId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        addProfilesService.interviewRatingDetails(interviewId).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                const config: GeneralPoupConfig = {
                    title: "Rating Details",
                    content: (
                        <RatingDetailsPopUp objRatingDetails={res.data} />
                    ),
                    size: ModalSize.XL,
                    className: "model-rating-details-badge"
                };
                modalUtils.showPopup(config);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Schedules");
        });
    };
    /* End - Schedule Interview Flow */    

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        if (filterColumn === "CandidateName") {
            setCandidateNameFilter(filterValue);
            getAddProfiles(getSearchFilters(filterValue, statusIdFilter, 0, pageSize, sortColumn, sortDirection));
        } else {
            setStatusIdFilter(parseInt(filterValue));
            getAddProfiles(getSearchFilters(candidateNameFilter, parseInt(filterValue), 0, pageSize, sortColumn, sortDirection));
        }
    };

    const addCandidate = (actionType: string) => {
        onProfileAddActionHandler(actionType);
    };

    const onCandidateViewHistoryHandler = (candidateId: string) => {
        const config: GeneralPoupConfig = {
            title: "Candidate History",
            content: (
                <CandidateHistoryComponent
                    candidateId={candidateId} />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getAddProfiles(getSearchFilters(candidateNameFilter, statusIdFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={() => addCandidate(PageModeEnum.Create)}> + Add Candidate</a>
            </div>
            <div className="card-body">
                {refreshAddProfiles > 2 && timeSlots.length > 0 &&
                    <AddProfilesGridComponent
                        key={refreshAddProfiles}
                        addProfilesList={addProfilesList}
                        requirementId={props.requirementId}
                        timeSlotsList={timeSlots}
                        totalSize={addProfilesPagedList?.totalCount ? addProfilesPagedList?.totalCount : addProfilesList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        candidateNameFilterValue={candidateNameFilter}
                        statusIdFilterValue={statusIdFilter}
                        canScheduleInterviews={props.canScheduleInterviews}
                        onProfileAdd={onProfileAddActionHandler}
                        onStatusUpdate={onStatusUpdateActionHandler}
                        onScheduleInterview={onScheduleInterviewActionHandler}
                        onRatingDetails={onRatingDetailsHandler}
                        onCandidateViewHistory={onCandidateViewHistoryHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default AddProfilesComponent;
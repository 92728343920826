const AppLoading = () => {
    return (  
      <div className="container-fuild" >  
        <div className="appBGLoad loaderBg">  
          <h1 className="display-4 loaderPageFont" >Application being initialized...</h1>  
          <p className="lead loaderPageFont">  
           Please wait a moment while application is being initialized  
          </p>  
        </div>  
      </div>  
    );  
  };  
  export default AppLoading;
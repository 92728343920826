const SliderComponent = () => {
    return (
        <div className="section-clients bg-light">
            <div className="text-center section-heading">
                <h2 className="section-clients-header">Our Clients</h2>
                <p className="w-95 w-md-75 w-lg-55 section-clients-info">We will be delighted to have you as our client. Please mail us at info@iaminterviewed.com to know the list of our clients.</p>
            </div>            
        </div>
    );
};
export default SliderComponent;
import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import copyClickboardUtils from "../../../../utils/copy-clipboard";
import RegisteredInterviewerModel from "../../../../models/admin/interviewer/response/RegisteredInterviewerModel";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";

interface RegisteredInterviewersGridProps {
    interviewersList: RegisteredInterviewerModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    nameFilterValue: string;
    emailIdFilterValue: string;
    onApproveAction: (interviewerId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
};

const RegisteredInterviewersGridComponent = (props: RegisteredInterviewersGridProps) => {
    const [nameFilter, setNameFilter] = useState<string>(props.nameFilterValue);
    const [emailIdFilter, setEmailIdFilter] = useState<string>(props.emailIdFilterValue);

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const approveInterviewer = (interviewerId: string) => {
        props.onApproveAction(interviewerId);
    };

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        if (key === "Name") {
            setNameFilter(value);
        } else {
            setEmailIdFilter(value);
        }
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    const columnsInterviewers = [
        { Key: "Name", text: "Name", sort: true, searchable: true },
        { Key: "EmailId", text: "Email Id", sort: true, searchable: true },
        { Key: "MobileNumber", text: "Mobile No", sort: true, searchable: false },
        { Key: "PrimarySkillName", text: "Primary Skill", sort: true, searchable: false },
        { Key: "Location", text: "Location", sort: true, searchable: false },
        { Key: "RegisteredDate", text: "Registered Dt", sort: true, searchable: false },
        { Key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsInterviewers.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={column.key === "CandidateName" ? nameFilter : emailIdFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.interviewersList.map((interviewer: RegisteredInterviewerModel, index) => (
                                <TableRow>
                                    <TableCell>{interviewer.name}</TableCell>
                                    <TableCell>{interviewer.emailId}</TableCell>
                                    <TableCell>
                                        <span>{interviewer.mobileNumber}</span><a title="copy" onClick={() => { copyClipboard(interviewer.mobileNumber.toString()); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{interviewer.primarySkillName}</TableCell>
                                    <TableCell>{interviewer.location}</TableCell>
                                    <TableCell>{interviewer.registeredDate}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { approveInterviewer(interviewer.interviewerId); }} title="Approve Interviewer">
                                            <i className="fa fa-check grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default RegisteredInterviewersGridComponent;
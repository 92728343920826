import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import vendorService from "../../../services/company/vendor-service";
import VendorSearchInputModel from "../../../models/company/vendor/request/VendorSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import VendorPopUp from "./VendorCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import VendorRequestModel from "../../../models/company/vendor/request/VendorRequestModel";
import VendorGridComponent from "./VendorGridComponent";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";

const VendorComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [vendorPagedList, setVendorPagedList] = useState<PagedListModel<VendorRequestModel>>();
    const [vendorList, setVendorList] = useState<VendorRequestModel[]>([]);
    const [refreshVendor, setRefreshVendor] = useState<number>(2);
    const [vendorNameFilter, setVendorNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("VendorName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getVendors(getSearchFilters(vendorNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (vendorNameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: VendorSearchInputModel = {
            companyId: "",
            vendorName: vendorNameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getVendors = (inputData: VendorSearchInputModel) => {
        if (loggedInUser?.profileId) {
            setRefreshVendor(2);
            inputData.companyId = loggedInUser?.profileId;
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            vendorService.getVendors(inputData).then((res) => {
                modalUtils.closeModal();
                setVendorList([]);
                setVendorPagedList(res);
                setVendorList(res.items);
                setRefreshVendor(refreshVendor * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const openVendorSaveModel = (vendorRequestModel: VendorRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Vendor",
            content: (
                <VendorPopUp
                    objVendor={vendorRequestModel}
                    actionType={actionType}
                    onCloseVendor={onCloseVendorHandler}
                    onVendorSave={onVendorSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseVendorHandler = () => {
        modalUtils.closeModal();
    };

    const onVendorSaveHandler = (objVendor: VendorRequestModel) => {
        setRefreshVendor(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        vendorService.saveVendor(objVendor).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                getVendors(getSearchFilters(vendorNameFilter, page, pageSize, sortColumn, sortDirection));
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onVendorActionHandler = (obj: VendorRequestModel, actionType: string) => {
        openVendorSaveModel(obj, actionType);
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getVendors(getSearchFilters(vendorNameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getVendors(getSearchFilters(vendorNameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getVendors(getSearchFilters(vendorNameFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setVendorNameFilter(filterValue);
        setPage(0);
        getVendors(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    const addVendor = () => {
        if (loggedInUser !== null) {
            let vendorRequestModel: VendorRequestModel = {
                vendorId: GuidEnum.Empty,
                userId: "",
                companyId: loggedInUser?.profileId,
                vendorName: "",
                vendorEmailId: "",
                vendorUserName: "",
                vendorPhoneNumber: null,
                startDate: null,
                endDate: null
            }
            onVendorActionHandler(vendorRequestModel, PageModeEnum.Create);
        }
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getVendors(getSearchFilters(vendorNameFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addVendor}> + Add Vendor</a>
            </div>
            <div className="card-body">
                {refreshVendor > 2 &&
                    <VendorGridComponent
                        key={refreshVendor}
                        vendorList={vendorList}
                        totalSize={vendorPagedList?.totalCount ? vendorPagedList?.totalCount : vendorList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        vendorNameFilterValue={vendorNameFilter}
                        onVendorAction={onVendorActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler} />
                }
            </div>
        </div>
    );
};
export default VendorComponent;
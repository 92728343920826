import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import CandidateAdminModel from "../../../../models/admin/candidate/response/CandidateAdminModel";
import adminCandidateService from "../../../../services/admin/admin-candidate-service";
import alertUtils from "../../../../utils/toaster-utils";
import CandidatePasswordGridComponent from "./CandidatePasswordGridComponent";
import CandidatePasswordRequestModel from "../../../../models/admin/candidate/request/CandidatePasswordRequestModel";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import IdNameModel from "../../../../models/common/IdNameModel";
import { RoleEnum } from "../../../../models/enums/RoleEnum";

interface CandidatePasswordProps {
    roleList: IdNameModel[];
};

const CandidatePasswordComponent = (props: CandidatePasswordProps) => {
    const [candidateList, setCandidateList] = useState<CandidateAdminModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [nameSearch, setNameSearch] = useState<string>("");
    const [emailIdSearch, setEmailIdSearch] = useState<string>("");
    const [roleSearch, setRoleSearch] = useState<number>(RoleEnum.Candidate);

    useEffect(() => {
        clearSearch();
    }, []);

    const getCandidates = (name: string, emailId: string) => {
        setRefreshCandidate(2);
        let input: CandidatePasswordRequestModel = {
            name: name,
            emailId: emailId,
            roleId: roleSearch === 0 ? RoleEnum.Candidate : roleSearch
        };
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCandidateService.getCandidatePassword(input).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);
        }).catch((error) => {
            setRefreshCandidate(refreshCandidate * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const clearSearch = () => {
        setCandidateList([]);
        setNameSearch("");
        setEmailIdSearch("");
        setRefreshCandidate(2);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Role</label>
                        <select className="mb-3 form-control form-select" value={roleSearch} onChange={(e) => setRoleSearch(parseInt(e.target.value))}>
                            {props.roleList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Name Like</label>
                        <input type="text" className="form-control" onChange={(e) => setNameSearch(e.target.value)} placeholder="Name Like" autoComplete="off" />
                    </div>
                    <div className="col-md-3">
                        <label>Email Id</label>
                        <input type="text" className="form-control" onChange={(e) => setEmailIdSearch(e.target.value)} placeholder="Email Id" autoComplete="off" />
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getCandidates(nameSearch, emailIdSearch)}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {candidateList.length > 0 && refreshCandidate > 2 &&
                    <CandidatePasswordGridComponent
                        key={refreshCandidate}
                        candidateList={candidateList} />
                }
            </div>
        </div>
    );
};
export default CandidatePasswordComponent;
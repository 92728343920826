import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../context-store";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import modalUtils from "../../../../utils/modal-utils";
import alertUtils from "../../../../utils/toaster-utils";
import loaderUtils from "../../../../utils/loader-utils";
import adminCompanyService from "../../../../services/admin/admin-company-service";
import CompanyConfigurationModel from "../../../../models/company/CompanyConfigurationModel";
import JobPostingComponent from "../../../company/job-posting/JobPostingComponent";
import designationService from "../../../../services/company/designation-service";

interface AdminPostRequirementProps {
    companiesList: IdNameModel[];
    companyConfiguration: CompanyConfigurationModel;
    jobTypes: IdNameModel[];
    assessmentTypes: IdNameModel[];
    domains: IdNameModel[];
    primarySkills: IdNameModel[];
    experiences: IdNameModel[];
    cities: IdNameModel[];
    designations: IdNameModel[];
    salaries: IdNameModel[];
    escalationStatusList: IdNameModel[];
};

const AdminPostRequirementComponent = (props: AdminPostRequirementProps) => {
    const [recruiterList, setRecruiterList] = useState<IdNameModel[]>([]);
    const [companySearch, setCompanySearch] = useState<string>("");
    const [recruiterSearch, setRecruiterSearch] = useState<string>("");
    const [refreshRequirements, setRefreshRequirements] = useState<number>(2);
    const [designations, setDesignations] = useState<IdNameModel[]>([]);
    const [companyConfiguration, setCompanyConfiguration] = useState<CompanyConfigurationModel>(props.companyConfiguration);

    useEffect(() => {
        clearSearch();
    }, []);

    const clearSearch = () => {
        setCompanySearch("")
        setRecruiterSearch("");
        setRefreshRequirements(2);
    };

    const loadRecruiters = (companyId: string) => {
        setRefreshRequirements(2);
        setCompanySearch(companyId);
        let companyCofig: CompanyConfigurationModel = {
            companyId: companyId,
            countryId: 0
        };
        setCompanyConfiguration(companyCofig);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminCompanyService.getRecruiterByCompany(companyId).then((res) => {
            modalUtils.closeModal();
            setRecruiterSearch("");
            setRecruiterList(res.items);
            fillDesignations(companyId);
        }).catch((error) => {
            setRecruiterSearch("");
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const fillDesignations = (companyId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        designationService.fillDesignations(companyId).then((res) => {
            modalUtils.closeModal();
            setDesignations(res.items);
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const setRecruiter = (recruiterId: string) => {
        setRefreshRequirements(2);
        setRecruiterSearch(recruiterId);
    };

    const getRequirements = () => {
        if (companySearch === "" || recruiterSearch === "") {
            setRefreshRequirements(2);
        } else {
            setRefreshRequirements(refreshRequirements * 5);
        }
    };

    return (
        <div>
            <div className="card card-white">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label>Company</label>
                            <select className="mb-3 form-control form-select" onChange={(e) => loadRecruiters(e.target.value)}>
                                <option value="">Select Company</option>
                                {props.companiesList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.guId}> {item.name} </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label>Recruiter</label>
                            <select className="mb-3 form-control form-select" onChange={(e) => setRecruiter(e.target.value)}>
                                <option value="">Select Recruiter</option>
                                {recruiterList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.guId}> {item.name} </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-success" type="submit" onClick={() => getRequirements()} disabled={companySearch === "" || recruiterSearch === ""}>
                                <i className="icon-search">Search</i>
                            </button>
                            <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                                <i className="icon-search">Clear</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {refreshRequirements > 2 && designations.length > 0 &&
                <JobPostingComponent
                    key={refreshRequirements}
                    companyConfiguration={companyConfiguration}
                    jobTypes={props.jobTypes}
                    assessmentTypes={props.assessmentTypes}
                    domains={props.domains}
                    primarySkills={props.primarySkills}
                    experiences={props.experiences}
                    cities={props.cities}
                    designations={designations}
                    salaries={props.salaries}
                    escalationStatusList={props.escalationStatusList}
                    profileId={recruiterSearch}
                    userId={recruiterSearch} />
            }
        </div>
    );
};
export default AdminPostRequirementComponent;
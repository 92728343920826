import { useState, useEffect } from "react";
import CandidateRelatedJobRequirementsGridModel from "../../../models/candidate/response/CandidateRelatedJobRequirementsGridModel";
import TablePagination from "@mui/material/TablePagination";

interface CandidateJobGridProps {
    requirementList: CandidateRelatedJobRequirementsGridModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
};

const CandidateAppliedJobsGridComponent = (props: CandidateJobGridProps) => {

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    return (
        <div className="row">
            {props.requirementList.map((requirement: CandidateRelatedJobRequirementsGridModel) => {
                return (
                    <div className="card card-white">
                        <div className="card-heading clearfix ">
                            <h4 className="card-title">{requirement.jobCode}</h4>
                        </div>
                        <div className="card-body" style={{ padding: '0' }}>
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Exp </b>: {requirement.experience}</p>
                                    <p><b>Designation</b>: {requirement.designationName}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><b>Primary Skill</b>: {requirement.primarySkillName}</p>
                                    <p><b>Skills</b>: {requirement.secondarySkills}</p>
                                </div>
                                <div className="col-md-4">
                                    <p><b>Location</b>: {requirement.cityName}</p>
                                    <p><b>Job Type</b>: {requirement.jobTypeName}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            <TablePagination
                rowsPerPageOptions={props.pageSizesList}
                component="div"
                count={props.totalSize}
                rowsPerPage={props.pagesize}
                page={props.currentPage - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};
export default CandidateAppliedJobsGridComponent;
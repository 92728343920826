import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import DesignationService from '../../../services/company/designation-service';
import DesignationSearchInputModel from "../../../models/company/designation/request/DesignationSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import DesignationModel from "../../../models/company/designation/response/DesignationModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import DesignationPopUp from "./DesignationCreateComponent";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import DesignationRequestModel from "../../../models/company/designation/request/DesignationRequestModel";
import DesignationGridComponent from "./DesignationGridComponent";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";

const DesignationComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [designationPagedList, setDesignationPagedList] = useState<PagedListModel<DesignationModel>>();
    const [designationList, setDesignationList] = useState<DesignationModel[]>([]);
    const [refreshDesignation, setRefreshDesignation] = useState<number>(2);
    const [designationNameFilter, setDesignationNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("DesignationName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getDesignations(getSearchFilters(designationNameFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (designationNameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: DesignationSearchInputModel = {
            companyId: "",
            designationName: designationNameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getDesignations = (inputData: DesignationSearchInputModel) => {
        if (loggedInUser?.profileId) {
            setRefreshDesignation(2);
            inputData.companyId = loggedInUser?.profileId;
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            DesignationService.getDesignations(inputData).then((res) => {
                modalUtils.closeModal();
                setDesignationList([]);
                setDesignationPagedList(res);
                setDesignationList(res.items);
                setRefreshDesignation(refreshDesignation * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const openDesignationSaveModel = (designationRequestModel: DesignationRequestModel, actionType: string) => {
        const config: GeneralPoupConfig = {
            title: "Add Designation",
            content: (
                <DesignationPopUp
                    objDesignation={designationRequestModel}
                    actionType={actionType}
                    onCloseDesignation={onCloseDesignationHandler}
                    onDesignationSave={onDesignationSaveHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    }

    const onCloseDesignationHandler = () => {
        modalUtils.closeModal();
    }

    const onDesignationSaveHandler = (objDesignation: DesignationRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        DesignationService.saveDesignation(objDesignation).then((res) => {
            alertUtils.showSuccess(res.informationMessages.toString());
            modalUtils.closeModal();
            getDesignations(getSearchFilters(designationNameFilter, page, pageSize, sortColumn, sortDirection));

        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    }

    const onDesignationActionHandler = (obj: DesignationRequestModel, actionType: string) => {
        openDesignationSaveModel(obj, actionType);
    }

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        const searchFilters = getSearchFilters(designationNameFilter, newPage, pageSize, sortColumn, sortDirection);
        getDesignations(searchFilters);
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        const searchFilters = getSearchFilters(designationNameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection);
        getDesignations(searchFilters);
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        const newSortDirection = sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc;
        setSortDirection(newSortDirection);
        getDesignations(getSearchFilters(designationNameFilter, page, pageSize, newSortColumn, newSortDirection));

    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        setDesignationNameFilter(filterValue);
        getDesignations(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    const addDesignation = () => {
        if (loggedInUser !== null) {
            let designationRequestModel: DesignationRequestModel = {
                designationId: GuidEnum.Empty,
                designationName: "",
                description: "",
                companyId: loggedInUser?.profileId,
                userId: loggedInUser?.userId
            }
            onDesignationActionHandler(designationRequestModel, PageModeEnum.Create);
        }
    };

    return (
        <div className="card card-white">
            <div className="card-head card-head-text-right">
                <a className="btn-grid-add-action" title="Reload Data" onClick={() => getDesignations(getSearchFilters(designationNameFilter, page, pageSize, sortColumn, sortDirection))}>
                    <i className="fa fa-refresh"></i>
                </a>
                <a className="btn-grid-add-action" onClick={addDesignation}> + Add Designation</a>
            </div>
            <div className="card-body">
                {designationList.length > 0 && refreshDesignation > 2 &&
                    <DesignationGridComponent
                        key={refreshDesignation}
                        designationList={designationList}
                        totalSize={designationPagedList?.totalCount ? designationPagedList?.totalCount : designationList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        designationNameFilterValue={designationNameFilter}
                        onDesignationAction={onDesignationActionHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler}
                    />
                }
            </div>
        </div>
    );
};
export default DesignationComponent;
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import InterviewerSchedulesComponent from "../../../components/admin/interviewer/interviewer-schedules/InterviewerSchedulesComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import masterDataService from "../../../services/master-data-service";

const InterviewerSchedules = () => {
    const [primarySkillList, setPrimarySkillList] = useState<IdNameModel[]>([]);
    const [refreshSkills, setRefreshSkills] = useState<number>(2);
    const [timeSlots, setTimeSlots] = useState<IdNameModel[]>([]);
    const [refreshTimeSlots, setRefreshTimeSlots] = useState<number>(2);

    useEffect(() => {
        fillPrimarySkills();
        getTimeSlots();
    }, []);

    const fillPrimarySkills = () => {
        setRefreshSkills(2);
        masterDataService.loadPrimarySkills().then((res) => {
            setPrimarySkillList(res.items);
            setRefreshSkills(refreshSkills * 5);
        }).catch((error) => {
            setRefreshSkills(refreshSkills * 5);
            alertUtils.showError(error);
        });
    };

    const getTimeSlots = () => {
        setRefreshTimeSlots(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadTimeSlots().then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setTimeSlots(res.items);
            } else {
                alertUtils.showError("Unable to load Time Slots");
            }
            setRefreshTimeSlots(refreshTimeSlots * 5);
        }).catch((error) => {
            setRefreshTimeSlots(refreshTimeSlots * 5);
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Time Slots");
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Interviewer Schedule </h4>
            </div>
            {refreshSkills > 2 &&
                <InterviewerSchedulesComponent primarySkillsList={primarySkillList} timeSlotsList={timeSlots} />
            }
        </Layout>
    );
};
export default InterviewerSchedules;
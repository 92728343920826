import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import IdNameModel from "../../models/common/IdNameModel";
import BaseResponse from "../../models/common/BaseResponse";
import BaseResponseList from "../../models/common/BaseResponseList";
import PagedListModel from "../../models/common/PagedListModel";
import AdminInterviewsSearchInputModel from "../../models/admin/interviews/request/AdminInterviewsSearchInputModel";
import AdminInterviewsModel from "../../models/admin/interviews/response/AdminInterviewsModel";
import UpdateInterviewRequestModel from "../../models/admin/interviews/request/UpdateInterviewRequestModel";
import EditRatingSearchInputModel from "../../models/admin/interviews/request/EditRatingSearchInputModel";
import InterviewRatingModel from "../../models/interviewer/response/InterviewRatingModel";

class AdminInterviewsService {
    async getInterviews(inputParms: AdminInterviewsSearchInputModel) {
        let res: PagedListModel<AdminInterviewsModel> = await httpClient.post<PagedListModel<AdminInterviewsModel>>(AppConfig.admin.interviews.getInterviews, inputParms);
        return res;
    }

    async getInterviewersByPrimarySkill(primarySkillId: number) {
        let res: BaseResponseList<IdNameModel[]> = await httpClient.get<BaseResponseList<IdNameModel[]>>(AppConfig.admin.interviews.getInterviewersByPrimarySkill  + "/" + primarySkillId);
        return res;
    }

    async updateInterview(request: UpdateInterviewRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.interviews.updateInterview, request);
        return res;
    }

    async getInterviewsForRatingEdit(request: EditRatingSearchInputModel) {
        let res: BaseResponseList<AdminInterviewsModel[]> = await httpClient.post<BaseResponseList<AdminInterviewsModel[]>>(AppConfig.admin.interviews.getInterviewsForRatingEdit, request);
        return res;
    }

    async getInterviewRatingDetails(interviewId: string) {
        let res: BaseResponse<InterviewRatingModel> = await httpClient.get<BaseResponse<InterviewRatingModel>>(AppConfig.admin.interviews.getInterviewRatingDetails + "/" + interviewId);
        return res;
    }

    async updateInterviewRating(rating: InterviewRatingModel) {
        let res: BaseResponse<InterviewRatingModel> = await httpClient.post<BaseResponse<InterviewRatingModel>>(AppConfig.admin.interviews.updateInterviewRating, rating);
        return res;
    }
}
const adminInterviewsService = new AdminInterviewsService();
export default adminInterviewsService;
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import IdNameModel from "../../../models/common/IdNameModel";
import CandidateAccessToViewProfileGridModel from "../../../models/candidate/response/CandidateAccessToViewProfileGridModel";
import candidateService from "../../../services/candidate/candidate-service";
import alertUtils from "../../../utils/toaster-utils";
import CandidateAccessGridComponent from "./CandidateAccessGridComponent";
import CandidateAccessToViewProfileRequestModel from "../../../models/candidate/request/CandidateAccessToViewProfileRequestModel";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";

const CandidateAccessComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [accessList, setAccessList] = useState<CandidateAccessToViewProfileGridModel[]>([]);
    const [refreshAccess, setRefreshAccess] = useState<number>(2);

    useEffect(() => {
        getAccess();        
    }, []);

    const getAccess = () => {        
        if (loggedInUser?.profileId) {
            setRefreshAccess(2);
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            candidateService.getCandidateAccessToViewProfileGrid(loggedInUser.profileId).then((res) => {
                modalUtils.closeModal();
                setAccessList([]);
                setAccessList(res.items);
                setRefreshAccess(refreshAccess * 5);                
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);                
            });
        } else {
            setRefreshAccess(refreshAccess * 5);
        }
    };

    const onProvideAccessActionHandler = (objAccess: CandidateAccessToViewProfileRequestModel) => {
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        candidateService.updateCandidateAccessToViewProfile(objAccess).then((res) => {
            modalUtils.closeModal();
            if(res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
                getAccess();
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshAccess > 2 && 
                    <CandidateAccessGridComponent
                        key={refreshAccess}
                        accessList={accessList}
                        onProvideAccess={onProvideAccessActionHandler} />
                }
            </div>
        </div>
    );
};
export default CandidateAccessComponent;
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CanidateStatusRequestModel from "../../../../models/admin/candidate/request/CanidateStatusRequestModel";
import IdNameModel from "../../../../models/common/IdNameModel";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import alertUtils from "../../../../utils/toaster-utils";

interface FollowupUpdateProps {
    objCandidate: CanidateStatusRequestModel;
    statusList: IdNameModel[];
    onFollowupClose: () => void;
    onFollowupUpdate: (objCandidate: CanidateStatusRequestModel) => void;
}

const TodayFollowupUpdateComponent = (props: FollowupUpdateProps) => {
    const [followUpDate, setFollowUpDate] = useState(new Date(props.objCandidate.followUpDate) as any);

    const followUpDateHandler = (date: any) => {
        setFollowUpDate(date);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: props.objCandidate
    });

    const onSubmit = (formData: CanidateStatusRequestModel) => {
        if (formData.statusId !== 0 && followUpDate) {
            formData.followUpDate = followUpDate;
            props.onFollowupUpdate(formData);
        } else {
            alertUtils.showError("Follow Up Date & Status are Mandatory");
            return;
        }
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Status: <span className="required">*</span></label>
                                    <select className="form-control form-select" {...register("statusId", { required: "Status is required" })}>
                                        <option value="0">Select Status</option>
                                        {props.statusList.map((type, index) => {
                                            return (
                                                <option key={index} value={type.id}> {type.name} </option>
                                            );
                                        })}
                                    </select>
                                    <span className="error">
                                        {errors.statusId && errors.statusId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Next Follow-up Date: <span className="required">*</span></label>
                                    <DateTimePicker
                                        selectsStart
                                        onChange={followUpDateHandler}
                                        selected={followUpDate}
                                        timeInputLabel="Time:"
                                        dateFormat="yyyy-MMM-dd"
                                        className="form-control"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Comments:</label>
                                    <textarea rows={3} className="form-control" {...register("comments", { required: false })} placeholder="Add comments" autoComplete="off" />
                                    <span className="error">
                                        {errors.comments && errors.comments.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit">
                                <span>Update</span>
                            </button>
                            <button className="btn btn-danger btn-form" type="button" onClick={props.onFollowupClose}>
                                Close
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default TodayFollowupUpdateComponent;
import React, { useState, useEffect } from "react";
import InterviewerSchedulesModel from "../../../../models/interviewer/response/InterviewerSchedulesModel";
import dateUtils from "../../../../utils/date-utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

interface InterviewerProfileProps {
    interviewerSchedule: InterviewerSchedulesModel;
}

const InterviewerScheduleGridComponent = (props: InterviewerProfileProps) => {

    const columnsInterviewerSchedule = [
        { key: "Date", text: "Date", sort: false, searchable: false },
        { key: "TimeSlots", text: "Time Slots", sort: false, searchable: false }
    ];

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsInterviewerSchedule.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        <span>{column.text}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <span>{dateUtils.getFormattedDateFromDate(props.interviewerSchedule.date) + "-" + dateUtils.getDayFromDate(props.interviewerSchedule.date)}</span>
                                </TableCell>
                                <TableCell>{props.interviewerSchedule.timeSlots}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
export default InterviewerScheduleGridComponent;
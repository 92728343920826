import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import AdminUserComponent from "../../components/admin/iai-user/AdminUserComponent";
import IdNameModel from "../../models/common/IdNameModel";
import masterDataService from "../../services/master-data-service";
import alertUtils from "../../utils/toaster-utils";
import modalUtils from "../../utils/modal-utils";
import loaderUtils from "../../utils/loader-utils";
import { RoleEnum } from "../../models/enums/RoleEnum";
import { GuidEnum } from "../../models/enums/GuidEnum";

const AdminUser = () => {
    const [companyList, setCompaniesList] = useState<IdNameModel[]>([]);
    const [refreshCompany, setRefreshCompany] = useState<number>(2);
    const [roles, setRoles] = useState<IdNameModel[]>([]);

    useEffect(() => {
        fillUserTypes();
        fillCompanies();
    }, []);

    const fillCompanies = () => {
        setRefreshCompany(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadCompanies().then((res) => {
            modalUtils.closeModal();
            setCompaniesList(res.items);
            setRefreshCompany(refreshCompany * 5);
        }).catch((error) => {
            setRefreshCompany(refreshCompany * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const fillUserTypes = () => {
        let roles: IdNameModel[] = [];
        let roleRecruiter: IdNameModel = {
            id: RoleEnum.IAIRecruiter,
            name: "IAI Recruiter",
            guId: GuidEnum.Empty
        }
        roles.push(roleRecruiter);
        let rolePanel: IdNameModel = {
            id: RoleEnum.PanelManagement,
            name: "Panel Management",
            guId: GuidEnum.Empty
        }
        roles.push(rolePanel);
        let roleAccountManager: IdNameModel = {
            id: RoleEnum.IAIAccountManager,
            name: "Account Executives",
            guId: GuidEnum.Empty
        }
        roles.push(roleAccountManager);
        setRoles(roles);
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> IAI Users </h4>
            </div>
            {refreshCompany > 2 &&
                <AdminUserComponent
                    companiesList={companyList}
                    roles={roles} />
            }
        </Layout>
    );
};
export default AdminUser;
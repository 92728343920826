import { useState, MouseEvent } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import RegisteredCompaniesModel from "../../../../models/admin/company/response/RegisteredCompaniesModel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";

interface CompanyConfigurationGridProps {
    companyList: RegisteredCompaniesModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    companyNameFilterValue: string;
    emailIdFilterValue: string;
    onEditConfigurationAction: (companyId: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
}

const RegisteredCandidatesGridComponent = (props: CompanyConfigurationGridProps) => {
    const [companyNameFilter, setCompanyNameFilter] = useState<string>(props.companyNameFilterValue);
    const [emailIdFilter, setEmailIdFilter] = useState<string>(props.emailIdFilterValue);

    const editConfiguration = (companyId: string) => {
        props.onEditConfigurationAction(companyId);
    };

    const columnsCompany = [
        { key: "CompanyName", text: "Company Name", sort: true, searchable: true },
        { key: "EmailId", text: "Email Id", sort: true, searchable: true },
        { key: "NoOfInterviewsAllowed", text: "Interviews Allowed", sort: false, searchable: false },
        { key: "StartDate", text: "Start Dt", sort: false, searchable: false },
        { key: "EndDate", text: "End Dt", sort: false, searchable: false },
        { key: "IsAuthorize", text: "Authorized", sort: false, searchable: false },
        { key: "Actions", text: "Actions", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        if (key === "CompanyName") {
            setCompanyNameFilter(value);
        } else {
            setEmailIdFilter(value);
        }
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsCompany.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={column.key === "CompanyName" ? companyNameFilter : emailIdFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.companyList.map((company: RegisteredCompaniesModel, index) => (
                                <TableRow>
                                    <TableCell>{company.companyName}</TableCell>
                                    <TableCell>{company.emailId}</TableCell>
                                    <TableCell>{company.noOfInterviewsAllowed}</TableCell>
                                    <TableCell>{company.startDate}</TableCell>
                                    <TableCell>{company.endDate}</TableCell>
                                    <TableCell>{company.isAuthorize}</TableCell>
                                    <TableCell>
                                        <a onClick={() => { editConfiguration(company.companyId); }} title="Edit Configuration">
                                            <i className="fa fa-pencil-alt grid-icon blue"></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
export default RegisteredCandidatesGridComponent;
import { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import JobPostingRequestModel from "../../../models/company/jobPosting/request/JobPostingRequestModel";
import { OBJECT_VALUE_RULE, OBJECT_DESCRIPTION_RULE } from "../../../utils/validation-utils";
import { Accordion, Card, Button } from "react-bootstrap";
import IdNameModel from "../../../models/common/IdNameModel";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateUtils from "../../../utils/date-utils";
import alertUtils from "../../../utils/toaster-utils";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import JobPostingSkillRequestModel from "../../../models/company/jobPosting/request/JobPostingSkillRequestModel";
import masterDataService from "../../../services/master-data-service";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import { StatusEnum } from "../../../models/enums/StatusEnum";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";

interface JobPostingProps {
    objJobPosting: JobPostingRequestModel;
    actionType: string;
    jobTypes: IdNameModel[];
    assessmentTypes: IdNameModel[];
    domains: IdNameModel[];
    primarySkills: IdNameModel[];
    experiences: IdNameModel[];
    cities: IdNameModel[];
    designations: IdNameModel[];
    salaries: IdNameModel[];
    onCloseJobPosting: () => void;
    onJobPostingSave: (objJobPosting: JobPostingRequestModel) => void;
}

const JobPostingCreateComponent = (props: JobPostingProps) => {
    const [formData, setFormData] = useState<JobPostingRequestModel>(props.objJobPosting);
    const [startDate, setStartDate] = useState(props.actionType === PageModeEnum.Edit ? new Date(props.objJobPosting.startDate) : "" as any);
    const [endDate, setEndDate] = useState(props.actionType === PageModeEnum.Edit ? new Date(props.objJobPosting.endDate) : "" as any);
    const [secondarySkillList, setSecondarySkillList] = useState<IdNameModel[]>([]);
    const [secondarySkillListJobPosting, setSecondarySkillListJobPosting] = useState<JobPostingSkillRequestModel[]>(props.objJobPosting.jobPostingSkillRequestModel);

    useEffect(() => {
        setFormData(props.objJobPosting);
        console.log(formData);
    }, []);

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        trigger,
        formState: { errors, isValid },
    } = useForm<JobPostingRequestModel>({
        mode: "onChange",
        defaultValues: formData
    });

    const getSecondarySkills = (primarySkillId: number) => {
        if (primarySkillId !== 0) {
            //modalUtils.showLoader(loaderUtils.getLoadDataText());
            masterDataService.loadSecondarySkills(primarySkillId).then((res) => {
                //modalUtils.closeModal();
                if (res.isSuccess) {
                    setSecondarySkillList(res.items);
                } else {
                    alertUtils.showError("Unable to load Primary skills");
                }
            }).catch((error) => {
                //modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Primary skills");
            });
        } else {
            alertUtils.showError("Please Select Primary Skill");
        }
    };

    const onSubmit = (formData: JobPostingRequestModel) => {
        if (startDate && endDate) {
            if (startDate > endDate) {
                alertUtils.showError("End Date should be greater than Start Date are Mandatory");
            } else {
                formData.jobPostingSkillRequestModel.forEach(function (item, index) {
                    item.requirementSkillId = GuidEnum.Empty;
                    item.requirementId = GuidEnum.Empty;
                    item.secondarySkillId = Number(item.secondarySkillId);
                    item.secondarySkillNumber = index + 1;
                    item.requirementInterviewProcessId = GuidEnum.Empty;
                    item.recruiterId = GuidEnum.Empty;
                    item.userId = GuidEnum.Empty;
                });
                formData.startDate = startDate;
                formData.endDate = endDate;
                formData.userId = GuidEnum.Empty;
                formData.additionalSkills = formData.keyResponsibilities;
                formData.statusId = Number(StatusEnum.DefaultJobPostingStatus);
                //console.log(formData);
                props.onJobPostingSave(formData);
            }
        } else {
            alertUtils.showError("Start Date & End Date are Mandatory");
        }
    }

    const selectStartDateHanler = (date: any) => {
        setStartDate(date);
    };

    const selectEndDateHanler = (date: any) => {
        setEndDate(date);
    };

    const addNewSkill = () => {
        let skill: JobPostingSkillRequestModel = {
            requirementSkillId: GuidEnum.Empty,
            requirementId: GuidEnum.Empty,
            secondarySkillId: null,
            secondarySkillNumber: secondarySkillListJobPosting.length + 1,
            isCommentsRequired: false,
            requirementInterviewProcessId: GuidEnum.Empty,
            recruiterId: GuidEnum.Empty,
            userId: GuidEnum.Empty
        };
        const updatedList = [...secondarySkillListJobPosting]; // simply create a new array and spread the previous one
        updatedList.push(skill);
        setSecondarySkillListJobPosting(updatedList);
        const updatedFormData = { ...formData };
        updatedFormData.jobPostingSkillRequestModel = updatedList;
        setFormData(updatedFormData);
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <Accordion defaultActiveKey="0">
                        <Card className="card-border-bottom">
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Job Info
                                    <i className="fa fa-angle-down"></i>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Job Type: <span className="required">*</span> </label>
                                                    <select className="form-control form-select" {...register("jobTypeId", { required: true })} disabled={props.actionType === PageModeEnum.Edit}>
                                                        <option value="">Select Job Type</option>
                                                        {props.jobTypes.map((type, index) => {
                                                            return (
                                                                <option key={index} value={type.id}> {type.name} </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="error">
                                                        {errors.jobTypeId && errors.jobTypeId.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Job Code: </label>
                                                    <input type="text" className="form-control" {...register("jobCode", OBJECT_VALUE_RULE)} placeholder="Job Code" autoComplete="off" disabled={props.actionType === PageModeEnum.Edit} />
                                                    <span className="error">
                                                        {errors.jobCode && errors.jobCode.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Job Description: <span className="required">*</span> </label>
                                                    <textarea rows={3} className="form-control" {...register("jobDescription", OBJECT_DESCRIPTION_RULE)} autoComplete="off" />
                                                    <span className="error">
                                                        {errors.jobDescription && errors.jobDescription.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Key Responsibilities / Client Inputs: </label>
                                                    <textarea rows={3} className="form-control" {...register("keyResponsibilities", OBJECT_DESCRIPTION_RULE)} autoComplete="off" />
                                                    <span className="error">
                                                        {errors.keyResponsibilities && errors.keyResponsibilities.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Assessment Type: <span className="required">*</span> </label>
                                                    <br></br>
                                                    {props.assessmentTypes.map((type, index) => (
                                                        <span>
                                                            <input type="radio" {...register("interviewTypeId")} value={type.id.toString()} disabled={props.actionType === PageModeEnum.Edit} />
                                                            <label>{type.name}</label>
                                                            <br></br>
                                                        </span>
                                                    ))}
                                                    <span className="error">
                                                        {errors.interviewTypeId && errors.interviewTypeId.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <input type="checkbox" {...register("scheduleZoomInterview")} disabled={props.actionType === PageModeEnum.Edit} />&nbsp;&nbsp;
                                                    <label> Schedule Zoom Interview</label>
                                                    <br></br>
                                                    <input type="checkbox" {...register("addInterviewProcess")} disabled={props.actionType === PageModeEnum.Edit} />&nbsp;&nbsp;
                                                    <label> Add Interview Process Steps</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <input type="checkbox" {...register("isCalibrationCallCompleted")} />
                                                    <label> Calibration Call Completed</label>
                                                    <br></br>
                                                    <input type="checkbox" {...register("isClientApproved")} />
                                                    <label> Client Approved</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <input type="checkbox" {...register("panelsAvailable")} />
                                                    <label> Panels Available</label>                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {props.actionType === PageModeEnum.Create &&
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        Technology
                                        <i className="fa fa-angle-down"></i>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="quform-element form-group">
                                                    <div className="quform-input">
                                                        <label className="form-label">Domain: <span className="required">*</span> </label>
                                                        <select className="form-control form-select" {...register("domainId", { required: true })}>
                                                            <option value="">Select Domain</option>
                                                            {props.domains.map((type, index) => {
                                                                return (
                                                                    <option key={index} value={type.id}> {type.name} </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <span className="error">
                                                            {errors.domainId && errors.domainId.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="quform-element form-group">
                                                    <div className="quform-input">
                                                        <label className="form-label">Primary Skill: <span className="required">*</span> </label>
                                                        <select className="form-control form-select" {...register("primarySkillId", { required: true })}
                                                            onChange={(e) => getSecondarySkills(Number(e.target.value))}>
                                                            <option value="">Select Skill</option>
                                                            {props.primarySkills.map((type, index) => {
                                                                return (
                                                                    <option key={index} value={type.id}> {type.name} </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <span className="error">
                                                            {errors.primarySkillId && errors.primarySkillId.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formData.jobPostingSkillRequestModel.map((skill, index) => (
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="quform-element form-group">
                                                        <div className="quform-input">
                                                            <label className="form-label">Secondary Skill {index + 1}: <span className="required">*</span> </label>
                                                            <select className="form-control form-select" {...register(`jobPostingSkillRequestModel.${index}.secondarySkillId`, { required: true })}>
                                                                <option value="">Select Secondary Skill</option>
                                                                {secondarySkillList.map((type, index) => {
                                                                    return (
                                                                        <option key={index} value={type.id}> {type.name} </option>
                                                                    );
                                                                })}
                                                            </select>
                                                            <span className="error">
                                                                {errors.primarySkillId && errors.primarySkillId.message}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="quform-element form-group">
                                                        <div className="quform-input">
                                                            <input type="checkbox" className="checkbox-job-posting-skills" {...register(`jobPostingSkillRequestModel.${index}.isCommentsRequired`)}
                                                                disabled={(index + 1) < 4} />&nbsp;&nbsp;
                                                            <label> Need Comments</label>
                                                            <span className="error">
                                                                {errors.primarySkillId && errors.primarySkillId.message}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row">
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6">
                                                <button className="btn btn-default" onClick={addNewSkill}> + Add New Skill</button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        }
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    General Info
                                    <i className="fa fa-angle-down"></i>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Min Experience: <span className="required">*</span> </label>
                                                    <select className="form-control form-select" {...register("minExperienceId", { required: true })}>
                                                        <option value="">Select Min Exp</option>
                                                        {props.experiences.map((type, index) => {
                                                            return (
                                                                <option key={index} value={type.id}> {type.name} </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="error">
                                                        {errors.minExperienceId && errors.minExperienceId.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Max Experience: <span className="required">*</span> </label>
                                                    <select className="form-control form-select" {...register("maxExperienceId", { required: true })}>
                                                        <option value="">Select Max Exp</option>
                                                        {props.experiences.map((type, index) => {
                                                            return (
                                                                <option key={index} value={type.id}> {type.name} </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="error">
                                                        {errors.maxExperienceId && errors.maxExperienceId.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Posting Start Date: <span className="required">*</span> </label>
                                                    <DateTimePicker
                                                        selectsStart
                                                        onChange={selectStartDateHanler}
                                                        selected={startDate}
                                                        timeInputLabel="Time:"
                                                        dateFormat="yyyy-MMM-dd"
                                                        className="form-control"
                                                        minDate={dateUtils.addDays(new Date(), 1)}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Posting End Date: <span className="required">*</span> </label>
                                                    <DateTimePicker
                                                        selectsStart
                                                        onChange={selectEndDateHanler}
                                                        selected={endDate}
                                                        timeInputLabel="Time:"
                                                        dateFormat="yyyy-MMM-dd"
                                                        className="form-control"
                                                        minDate={dateUtils.addDays(new Date(), 1)}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Current Location: <span className="required">*</span> </label>
                                                    <select className="form-control form-select" {...register("cityId", { required: true })}>
                                                        <option value="">Select Location</option>
                                                        {props.cities.map((type, index) => {
                                                            return (
                                                                <option key={index} value={type.id}> {type.name} </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="error">
                                                        {errors.cityId && errors.cityId.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Designation: <span className="required">*</span> </label>
                                                    <select className="form-control form-select" {...register("designationId", { required: true })}>
                                                        <option value="">Select Designation</option>
                                                        {props.designations.map((type, index) => {
                                                            return (
                                                                <option key={index} value={type.guId}> {type.name} </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="error">
                                                        {errors.designationId && errors.designationId.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="quform-element form-group">
                                                <div className="quform-input">
                                                    <label className="form-label">Highest Pay: <span className="required">*</span> </label>
                                                    <select className="form-control form-select" {...register("highestPay", { required: true })}>
                                                        <option value="">Select Highest Pay</option>
                                                        {props.salaries.map((type, index) => {
                                                            return (
                                                                <option key={index} value={type.guId}> {type.name} </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="error">
                                                        {errors.highestPay && errors.highestPay.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseJobPosting()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default JobPostingCreateComponent;
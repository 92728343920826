import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import AdminPostRequirementComponent from "../../../components/admin/company/admin-post-job/AdminPostRequirementComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import alertUtils from "../../../utils/toaster-utils";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";
import { RoleEnum } from "../../../models/enums/RoleEnum";
import adminCompanyService from "../../../services/admin/admin-company-service";
import designationService from "../../../services/company/designation-service";
import CompanyConfigurationModel from "../../../models/company/CompanyConfigurationModel";
import jobPostingService from "../../../services/company/job-posting-service";
import { ScreenEnum } from "../../../models/enums/StatusEnum";

var companyConfigurationDefault: CompanyConfigurationModel = {
    companyId: "",
    countryId: 1
};

const AdminPostRequirement = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [companyList, setCompaniesList] = useState<IdNameModel[]>([]);
    const [refreshCompany, setRefreshCompany] = useState<number>(2);
    const [companyConfiguration, setCompanyConfiguration] = useState<CompanyConfigurationModel>(companyConfigurationDefault);
    const [jobTypes, setJobTypes] = useState<IdNameModel[]>([]);
    const [assessmentTypes, setAssessmentTypes] = useState<IdNameModel[]>([]);
    const [domains, setDomains] = useState<IdNameModel[]>([]);
    const [primarySkills, setPrimarySkills] = useState<IdNameModel[]>([]);
    const [experiences, setExperiences] = useState<IdNameModel[]>([]);
    const [cities, setCities] = useState<IdNameModel[]>([]);
    const [designations, setDesignations] = useState<IdNameModel[]>([]);
    const [salaries, setSalaries] = useState<IdNameModel[]>([]);
    const [escalationStatusList, setEscalationStatusList] = useState<IdNameModel[]>([]);
    const [refreshEscalationStatus, setRefreshEscalationStatus] = useState<number>(2);

    useEffect(() => {
        fillCompanies();
        fillJobTypes();
        fillAssessmentTypes();
        fillDomains();
        fillPrimarySkills();
        fillExperiences();
        fillSalaries();
        fillCities();
        getEscalationStatusList();
        //getCompanyConfiguration();
    }, []);

    const fillCompanies = () => {
        setRefreshCompany(2);
        if (loggedInUser && parseInt(loggedInUser.roleId) === parseInt(RoleEnum.Administrator.toString()) ||
            loggedInUser && parseInt(loggedInUser.roleId) === parseInt(RoleEnum.IAIAccountManager.toString())) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            masterDataService.loadCompanies().then((res) => {
                modalUtils.closeModal();
                setCompaniesList(res.items);
                setRefreshCompany(refreshCompany * 5);
            }).catch((error) => {
                setRefreshCompany(refreshCompany * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        } else {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            adminCompanyService.getCompaniesForIAIRecruiter(loggedInUser ? loggedInUser.profileId : "").then((res) => {
                modalUtils.closeModal();
                setCompaniesList(res.items);
                setRefreshCompany(refreshCompany * 5);
            }).catch((error) => {
                setRefreshCompany(refreshCompany * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    // const getCompanyConfiguration = () => {
    //     if (loggedInUser) {
    //         modalUtils.showLoader(loaderUtils.getLoadDataText());
    //         jobPostingService.getCompanyConfiguration(loggedInUser.userId).then((res) => {
    //             modalUtils.closeModal();
    //             setCompanyConfiguration(res.data);
    //             fillCities(res.data.countryId);
    //             fillDesignations(res.data.companyId);
    //         }).catch((error) => {
    //             modalUtils.closeModal();
    //             alertUtils.showError(error);
    //         });
    //     }
    // };

    const fillJobTypes = () => {
        masterDataService.loadJobTypes().then((res) => {
            setJobTypes(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillAssessmentTypes = () => {
        masterDataService.loadInterviewTypes().then((res) => {
            setAssessmentTypes(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillDomains = () => {
        masterDataService.loadDomains().then((res) => {
            setDomains(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillPrimarySkills = () => {
        masterDataService.loadPrimarySkills().then((res) => {
            setPrimarySkills(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillExperiences = () => {
        masterDataService.loadExperiences().then((res) => {
            setExperiences(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillCities = () => {
        masterDataService.loadAllCities().then((res) => {
            setCities(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillSalaries = () => {
        masterDataService.loadSalaries().then((res) => {
            setSalaries(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getEscalationStatusList = () => {
        masterDataService.loadStatusByScreen(ScreenEnum.EscalationsStatus).then((res) => {
            setEscalationStatusList(res.items);
            setRefreshEscalationStatus(refreshEscalationStatus * 5);
        }).catch((error) => {
            setRefreshEscalationStatus(refreshEscalationStatus * 5);
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Post Job </h4>
            </div>
            {refreshCompany > 2 && jobTypes.length > 0 && assessmentTypes.length > 0 && domains.length > 0 &&
                primarySkills.length > 0 && experiences.length > 0
                && cities.length > 0 //&& designations.length > 0
                && salaries.length > 0 &&
                <AdminPostRequirementComponent companiesList={companyList}
                    companyConfiguration={companyConfiguration}
                    jobTypes={jobTypes}
                    assessmentTypes={assessmentTypes}
                    domains={domains}
                    primarySkills={primarySkills}
                    experiences={experiences}
                    cities={cities}
                    designations={designations}
                    salaries={salaries}
                    escalationStatusList={escalationStatusList} />
            }
        </Layout>
    );
};
export default AdminPostRequirement;
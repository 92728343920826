import { useState, useEffect } from "react";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import AllProfilesGridModel from "../../../models/company/allProfiles/response/AllProfilesGridModel";
import dateTimeUtils from "../../../utils/date-utils";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AllProfilesSearchInputModel from "../../../models/company/allProfiles/request/AllProfilesSearchInputModel";
import companyDashboardService from "../../../services/company/dashboard-service";
import adminDashboardService from "../../../services/admin/admin-dashboard-service";
import AllProfilesGridComponent from "./AllProfilesGridComponent";
import CandidateHistoryPopup from "../../admin/candidate/candidate-history/CandidateHistoryComponent";
import ZoomRecordingsPopUp from "./ZoomRecordingsComponent";

interface AllProfilesProps {
    primarySkillList: IdNameModel[];
    profileId: string;
    userId: string | null;
};

const AllProfilesComponent = (props: AllProfilesProps) => {
    const [candidatePagedList, setCandidatePagedList] = useState<PagedListModel<AllProfilesGridModel>>();
    const [candidateList, setCandidateList] = useState<AllProfilesGridModel[]>([]);
    const [refreshCandidate, setRefreshCandidate] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(dateTimeUtils.addDays(new Date(), -15) as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [primarySKillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        clearSearch();
    }, []);

    const clearSearch = () => {
        setCandidateList([]);
        setStartDateSearch("");
        setEndDateSearch("");
        setPrimarySkillSearch(0);
        setNameFilter("");
        setRefreshCandidate(2);
    };

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getSearchFilters = (nameFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: AllProfilesSearchInputModel = {
            startDate: dateTimeUtils.getFormattedDate(startDateSearch),
            endDate: dateTimeUtils.getFormattedDate(endDateSearch),
            primarySkillId: primarySKillSearch,
            companyId: props.profileId,
            userId: props.userId,
            requirementIds: [],
            candidateName: nameFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getCandidates = (inputData: AllProfilesSearchInputModel) => {
        setRefreshCandidate(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        companyDashboardService.getAllProfilesGrid(inputData).then((res) => {
            modalUtils.closeModal();
            setCandidateList([]);
            setCandidatePagedList(res);
            setCandidateList(res.items);
            setRefreshCandidate(refreshCandidate * 5);
        }).catch((error) => {
            setRefreshCandidate(refreshCandidate * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onViewRatingActionHandler = (interviewId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminDashboardService.interviewRatingDetails(interviewId).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                const config: GeneralPoupConfig = {
                    title: "Rating Details",
                    content: (
                        <RatingDetailsPopUp objRatingDetails={res.data} />
                    ),
                    size: ModalSize.XL,
                    className: "model-rating-details-badge"
                };
                modalUtils.showPopup(config);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Eror fetching Schedules");
        });
    };

    const onViewRecordingsActionHandler = (zoomScheduleId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        companyDashboardService.getZoomRecordings(zoomScheduleId).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                const config: GeneralPoupConfig = {
                    title: "Zoom Recordings",
                    content: (
                        <ZoomRecordingsPopUp recordingsList={res.items} />
                    ),
                    size: ModalSize.LG,
                    className: "model-rating-details-badge"
                };
                modalUtils.showPopup(config);
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Eror fetching Schedules");
        });
    };

    const onCandidateViewHistoryHandler = (candidateId: string) => {
        const config: GeneralPoupConfig = {
            title: "Candidate History",
            content: (
                <CandidateHistoryPopup
                    candidateId={candidateId} />
            ),
            size: ModalSize.XL,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getCandidates(getSearchFilters(nameFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getCandidates(getSearchFilters(nameFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getCandidates(getSearchFilters(nameFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        setNameFilter(filterValue);
        getCandidates(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));

    };

    return (
        <div className="card card-white">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <label>Start Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={startDateHanler}
                            selected={startDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>End Date</label>
                        <DateTimePicker
                            selectsStart
                            onChange={endDateHanler}
                            selected={endDateSearch}
                            timeInputLabel="Time:"
                            dateFormat="yyyy-MMM-dd"
                            className="form-control"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>Primary Skill</label>
                        <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {props.primarySkillList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}> {item.name} </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-success" type="submit" onClick={() => getCandidates(getSearchFilters(nameFilter, page, pageSize, sortColumn, sortDirection))}>
                            <i className="icon-search">Search</i>
                        </button>
                        <button className="btn btn-warning" type="submit" onClick={() => clearSearch()}>
                            <i className="icon-search">Clear</i>
                        </button>
                    </div>
                </div>
                {candidateList.length > 0 && refreshCandidate > 2 &&
                    <AllProfilesGridComponent
                        key={refreshCandidate}
                        candidateList={candidateList}
                        totalSize={candidatePagedList?.totalCount ? candidatePagedList?.totalCount : candidateList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        nameFilterValue={nameFilter}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler}
                        onSortAction={onSortActionHandler}
                        onFilterAction={onFilterActionHandler}
                        onViewRatingAction={onViewRatingActionHandler}
                        onViewRecordingsAction={onViewRecordingsActionHandler}
                        onCandidateViewHistory={onCandidateViewHistoryHandler} />
                }
            </div>
        </div>
    );
};
export default AllProfilesComponent;
import { useState, useEffect } from "react";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import AppliedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/AppliedProfilesGridModel";
import AppliedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/AppliedProfilesRequestModel";
import companyClasses from "../../../landing-pages/company/company.module.css";
import TablePagination from "@mui/material/TablePagination";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

interface AppliedProfilesGridProps {
    appliedProfilesList: AppliedProfilesGridModel[];
    requirementId: string;
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    onShortlistProfile: (row: AppliedProfilesRequestModel) => void;
    onRatingDetails: (row: AppliedProfilesRequestModel, hasAccess: boolean) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
};

const AppliedProfilesGridComponent = (props: AppliedProfilesGridProps) => {

    const shortlistCandidate = (objProfile: AppliedProfilesGridModel) => {
        let updateModel: AppliedProfilesRequestModel = {
            applicationId: objProfile.applicationId,
            requirementId: objProfile.requirementId,
            candidateId: objProfile.candidateId,
            interviewId: objProfile.interviewId,
            userId: GuidEnum.Empty
        }
        props.onShortlistProfile(updateModel);
    };

    const viewRating = (objProfile: AppliedProfilesGridModel) => {
        let updateModel: AppliedProfilesRequestModel = {
            applicationId: objProfile.applicationId,
            requirementId: objProfile.requirementId,
            candidateId: objProfile.candidateId,
            interviewId: objProfile.interviewId,
            userId: GuidEnum.Empty
        }
        props.onRatingDetails(updateModel, objProfile.restrictEmployerToViewProfile);
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Candidate Name</TableCell>
                                <TableCell>Experience</TableCell>
                                <TableCell>Notice Period</TableCell>
                                <TableCell>Interview Date</TableCell>
                                <TableCell>Rating</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.appliedProfilesList.map((profile: AppliedProfilesGridModel, index) => (
                                <TableRow key={index}>
                                    <TableCell>{profile.candidateName}</TableCell>
                                    <TableCell>{profile.experience}</TableCell>
                                    <TableCell>{profile.noticePeriod}</TableCell>
                                    <TableCell>{profile.interviewDate}</TableCell>
                                    <TableCell>{profile.totalRating}</TableCell>
                                    <TableCell>
                                        <a onClick={() => viewRating(profile)} className={companyClasses.jobPostinglinks} >
                                            <i className="fas fa-star" title="view rating"></i>
                                        </a>
                                        <a onClick={() => shortlistCandidate(profile)} className="grid-icon orange" title="shortlist">
                                            <i className="fas fa-check" style={{ fontSize: '1.2em' }}></i>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
            </div>
        </div>
    );
};
export default AppliedProfilesGridComponent;
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import JobPostingParams from "../../models/common/PageParms";
import { useParams } from "react-router-dom";
import masterDataService from "../../services/master-data-service";
import IdNameModel from "../../models/common/IdNameModel";
import alertUtils from "../../utils/toaster-utils";
import companyClasses from "./company.module.css";
import jobPostingService from "../../services/company/job-posting-service";
import { useHistory } from "react-router";
import AppliedProfilesComponent from "../../components/company/applied-profiles/AppliedProfilesComponent";
import AppliedImage from "../../assets/InnerCSS/img/logos/candidate.png";
import RelatedImage from "../../assets/InnerCSS/img/logos/interviewer.png";
import ShortlistedImage from "../../assets/InnerCSS/img/logos/employer.png";
import { RoleEnum } from "../../models/enums/RoleEnum";
import modalUtils from "../../utils/modal-utils";
import loaderUtils from "../../utils/loader-utils";

const AppliedCandidates = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const history = useHistory();
    const requestParams: JobPostingParams = useParams();
    const [jobCode, setJobCode] = useState<string>("");
    const [ratingList, setRatingList] = useState<IdNameModel[]>([]);
    const [roleId, setRoleId] = useState<number>(loggedInUser !== null ? parseInt(loggedInUser.roleId) : 0);

    useEffect(() => {
        if (loggedInUser) {
            getJobPosting(requestParams.requirementId);
            getTotalRatings();
        }
    }, []);

    const getJobPosting = (requirementId: string) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        jobPostingService.getJobPostingById(requirementId).then((res) => {
            modalUtils.closeModal();
            setJobCode(res.data.jobCode);            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const getTotalRatings = () => {
        masterDataService.loadTotalRatings().then((res) => {
            setRatingList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <div className="row">
                    <div className="col-md-2">
                        <h4 className="breadcrumb-header"> Applied Candidates </h4>
                    </div>
                    <div className="col-md-2">
                        <h4 className="job-code-header" >{jobCode}</h4>
                    </div>
                    {roleId === parseInt(RoleEnum.ClientRecruiter.toString()) &&
                        <div className="col-md-8">
                            <a className="btn" onClick={() => history.push("/add-profiles/" + requestParams.requirementId)}><span> <img src={AppliedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Add Profiles</b></a>
                            <a className="btn" onClick={() => history.push("/related-profiles/" + requestParams.requirementId)}><span> <img src={RelatedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Related Candidate</b></a>
                            <a className="btn" onClick={() => history.push("/shortlisted-profiles/" + requestParams.requirementId)}><span> <img src={ShortlistedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Shortlisted Candidate</b></a>
                        </div>
                    }
                    {roleId === parseInt(RoleEnum.ClientAdmin.toString()) &&
                        <div className="col-md-8">
                            {/* <a className="btn" onClick={() => history.push("/add-profiles/" + requestParams.requirementId)}><span> <img src={AppliedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Add Profiles</b></a> */}
                            <a className="btn" onClick={() => history.push("/related-profiles/" + requestParams.requirementId)}><span> <img src={RelatedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Related Candidate</b></a>
                            <a className="btn" onClick={() => history.push("/shortlisted-profiles/" + requestParams.requirementId)}><span> <img src={ShortlistedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Shortlisted Candidate</b></a>
                        </div>
                    }
                    {roleId === parseInt(RoleEnum.VendorRecruiter.toString()) &&
                        <div className="col-md-8">
                            <a className="btn" onClick={() => history.push("/add-profiles/" + requestParams.requirementId)}><span> <img src={AppliedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Add Profiles</b></a>
                            <a className="btn" ><span> <img src={RelatedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Related Candidate</b></a>
                            <a className="btn" ><span> <img src={ShortlistedImage} alt="" className={companyClasses.jobPostingProfilesImageClass}></img></span> <b>Shortlisted Candidate</b></a>
                        </div>
                    }
                </div>
            </div>
            {jobCode !== "" && ratingList.length > 0 &&
                <AppliedProfilesComponent
                    ratingList={ratingList}
                    requirementId={requestParams.requirementId} />
            }
        </Layout>
    );
};
export default AppliedCandidates;
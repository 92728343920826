import { useForm } from "react-hook-form";
import DesignationRequestModel from "../../../models/company/designation/request/DesignationRequestModel";
import { OBJECT_NAME_RULE, OBJECT_DESCRIPTION_RULE } from "../../../utils/validation-utils";

interface DesignationProps {
    objDesignation: DesignationRequestModel;
    actionType: string;
    onCloseDesignation: () => void;
    onDesignationSave: (objDesignation: DesignationRequestModel) => void;
}

const DesignationCreateComponent = (props: DesignationProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<DesignationRequestModel>({
        mode: "onChange",
        defaultValues: props.objDesignation
    });

    const onSubmit = (formData: DesignationRequestModel) => {
        props.onDesignationSave(formData);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Designation Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("designationName", OBJECT_NAME_RULE)} placeholder="Designation Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.designationName && errors.designationName.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Description: </label>
                                    <textarea rows={3} className="form-control" {...register("description", OBJECT_DESCRIPTION_RULE)} placeholder="Description" autoComplete="off" />
                                    <span className="error">
                                        {errors.description && errors.description.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseDesignation()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default DesignationCreateComponent;
import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import JobPostingSearchInputModel from "../../models/company/jobPosting/request/JobPostingSearchInputModel";
import CompanyDashboardModel from "../../models/company/dashboard/response/CompanyDashboardModel";
import PagedListModel from "../../models/common/PagedListModel";
import AllProfilesSearchInputModel from "../../models/company/allProfiles/request/AllProfilesSearchInputModel";
import AllProfilesGridModel from "../../models/company/allProfiles/response/AllProfilesGridModel";
import BaseResponseList from "../../models/common/BaseResponseList";
import ZoomRecordingsModel from "../../models/company/allProfiles/response/ZoomRecordingsModel";

class CompanyDashboardService {
    async getJobPostingsDashboard(inputParms: JobPostingSearchInputModel) {
        let res: PagedListModel<CompanyDashboardModel> = await httpClient.post<PagedListModel<CompanyDashboardModel>>(AppConfig.company.dashboard.getJobPostingDashboard, inputParms);
        return res;
    };

    async getAllProfilesGrid(inputParms: AllProfilesSearchInputModel) {
        let res: PagedListModel<AllProfilesGridModel> = await httpClient.post<PagedListModel<AllProfilesGridModel>>(AppConfig.company.getAllProfiles, inputParms);
        return res;
    };

    async getZoomRecordings(zoomScheduleId: string) {
        let res: BaseResponseList<ZoomRecordingsModel[]> = await httpClient.get<BaseResponseList<ZoomRecordingsModel[]>>(AppConfig.company.getZoomRecordings  + "/" + zoomScheduleId);
        return res;
    };
}
const companyDashboardService = new CompanyDashboardService();
export default companyDashboardService;
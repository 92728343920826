import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../context-store";
import Layout from "../../../components/layout/Layout";
import "../../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../../assets/InnerCSS/css/styles.css";
import "../../../assets/InnerCSS/css/custom.css";
import InterviewsComponent from "../../../components/admin/interviews/interviews/InterviewsComponent";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import alertUtils from "../../../utils/toaster-utils";
import adminInterviewsService from "../../../services/admin/admin-interviews-service";
import modalUtils from "../../../utils/modal-utils";
import loaderUtils from "../../../utils/loader-utils";

const Interviews = () => {
    const [primarySkillList, setPrimarySkillList] = useState<IdNameModel[]>([]);
    const [timeSlots, setTimeSlots] = useState<IdNameModel[]>([]);
    const [interviewerList, setInterviewerList] = useState<IdNameModel[]>([]);
    const [cityList, setCityList] = useState<IdNameModel[]>([]);
    const [refreshSkills, setRefreshSkills] = useState<number>(2);
    const [refreshTimeSlots, setRefreshTimeSlots] = useState<number>(2);
    const [refreshInterviewer, setRefreshInterviewer] = useState<number>(2);
    const [refreshCity, setRefreshCity] = useState<number>(2);
    const [status, setStatus] = useState<IdNameModel[]>([]);
    const [refreshStatus, setRefreshStatus] = useState<number>(2);

    useEffect(() => {
        getTimeSlots();
        fillPrimarySkills();
        fillInterviewers();
        fillCity();
        getStatus();
    }, []);

    const fillPrimarySkills = () => {
        setRefreshSkills(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadPrimarySkills().then((res) => {
            modalUtils.closeModal();
            setPrimarySkillList(res.items);
            setRefreshSkills(refreshSkills * 5);            
        }).catch((error) => {
            setRefreshSkills(refreshSkills * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const getTimeSlots = () => {
        setRefreshTimeSlots(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadTimeSlots().then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setTimeSlots(res.items);
            } else {
                alertUtils.showError("Unable to load Time Slots");
            }
            setRefreshTimeSlots(refreshTimeSlots * 5);            
        }).catch((error) => {
            setRefreshTimeSlots(refreshTimeSlots * 5);
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Time Slots");            
        });
    };

    const fillInterviewers = () => {
        setRefreshInterviewer(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        adminInterviewsService.getInterviewersByPrimarySkill(0).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setInterviewerList(res.items);
            } else {
                alertUtils.showError("Unable to load Interviewers");
            }
            setRefreshInterviewer(refreshInterviewer * 5);            
        }).catch((error) => {
            setRefreshInterviewer(refreshInterviewer * 5);
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Interviewers");            
        });
    };

    const fillCity = () => {
        setRefreshCity(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadAllCities().then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setCityList(res.items);
            } else {
                alertUtils.showError("Unable to load Cities");
            }
            setRefreshCity(refreshCity * 5);            
        }).catch((error) => {
            setRefreshCity(refreshCity * 5);
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Cities");            
        });
    };

    const getStatus = () => {
        setRefreshStatus(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadStatus().then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setStatus(res.items);
            } else {
                alertUtils.showError("Unable to load Status");
            }
            setRefreshStatus(refreshStatus * 5);
        }).catch((error) => {
            setRefreshStatus(refreshStatus * 5);
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Status");
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> Interviews </h4>
            </div>
            {refreshSkills > 2 && refreshTimeSlots > 2 && refreshInterviewer > 2 && refreshCity > 2 &&
                <InterviewsComponent
                    primarySkillsList={primarySkillList}
                    timeSlotsList={timeSlots}
                    interviewerList={interviewerList}
                    cityList={cityList}
                    statusList={status} />
            }
        </Layout>
    );
};
export default Interviews;
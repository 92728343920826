import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import PagedListModel from "../../../models/common/PagedListModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import relatedShortlistedProfilesService from "../../../services/company/related-shortlisted-profiles-service";
import alertUtils from "../../../utils/toaster-utils";
import IdNameModel from "../../../models/common/IdNameModel";
import AppliedProfilesGridComponent from "./AppliedProfilesGridComponent";
import { GuidEnum } from "../../../models/enums/GuidEnum";
import AppliedProfilesGridModel from "../../../models/company/relatedAndShortlistedProfiles/response/AppliedProfilesGridModel";
import AppliedProfilesSearchInputModel from "../../../models/company/relatedAndShortlistedProfiles/request/AppliedProfilesSearchInputModel";
import AppliedProfilesRequestModel from "../../../models/company/relatedAndShortlistedProfiles/request/AppliedProfilesRequestModel";
import modalUtils from "../../../utils/modal-utils";
import addProfilesService from "../../../services/company/add-profiles-service";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import RatingDetailsPopUp from "../../candidate/dashboard/RatingDetailsComponent";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";
import masterDataService from "../../../services/master-data-service";

interface AppliedProfilesProps {
    ratingList: IdNameModel[];
    requirementId: string;
};

const AppliedProfilesComponent = (props: AppliedProfilesProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [appliedProfilesPagedList, setAppliedProfilesPagedList] = useState<PagedListModel<AppliedProfilesGridModel>>();
    const [appliedProfilesList, setAppliedProfilesList] = useState<AppliedProfilesGridModel[]>([]);
    const [refreshAppliedProfiles, setRefreshAppliedProfiles] = useState<number>(2);
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("CandidateName");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getAppliedProfiles(getSearchFilters(page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: AppliedProfilesSearchInputModel = {
            requirementId: props.requirementId,
            totalRating: null,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getAppliedProfiles = (inputData: AppliedProfilesSearchInputModel) => {
        setRefreshAppliedProfiles(2)
        if (loggedInUser?.profileId) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            relatedShortlistedProfilesService.getAppliedProfilesGrid(inputData).then((res) => {
                modalUtils.closeModal();
                setAppliedProfilesList([]);
                setAppliedProfilesPagedList(res);
                setAppliedProfilesList(res.items);
                setRefreshAppliedProfiles(refreshAppliedProfiles * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onShortlistProfileHandler = (objShortlistProfile: AppliedProfilesRequestModel) => {
        setRefreshAppliedProfiles(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        relatedShortlistedProfilesService.saveAppliedProfile(objShortlistProfile).then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                alertUtils.showSuccess(res.informationMessages.toString());
            } else {
                alertUtils.showError(res.errorMessages.toString());
            }
            getAppliedProfiles(getSearchFilters(page, pageSize, sortColumn, sortDirection));
            modalUtils.closeModal();
        }).catch((error) => {
            setRefreshAppliedProfiles(refreshAppliedProfiles * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onRatingDetailsHandler = (objShortlistProfile: AppliedProfilesRequestModel, hasAccess: boolean) => {
        if (hasAccess) {
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            addProfilesService.interviewRatingDetails(objShortlistProfile.interviewId).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    const config: GeneralPoupConfig = {
                        title: "Rating Details",
                        content: (
                            <RatingDetailsPopUp
                                objRatingDetails={res.data} />
                        ),
                        size: ModalSize.XL,
                        className: "model-rating-details-badge"
                    };
                    modalUtils.showPopup(config);
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError("Erorr fetching Schedules");
            });
        } else {
            setRefreshAppliedProfiles(2);
            modalUtils.showLoader(loaderUtils.getSaveDataText());
            relatedShortlistedProfilesService.saveCandidateAccessToViewProfile(objShortlistProfile).then((res) => {
                modalUtils.closeModal();
                if (res.isSuccess) {
                    alertUtils.showSuccess(res.informationMessages.toString());
                } else {
                    alertUtils.showError(res.errorMessages.toString());
                }
                getAppliedProfiles(getSearchFilters(page, pageSize, sortColumn, sortDirection));
                modalUtils.closeModal();
            }).catch((error) => {
                setRefreshAppliedProfiles(refreshAppliedProfiles * 5);
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getAppliedProfiles(getSearchFilters(newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getAppliedProfiles(getSearchFilters(PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    return (
        <div className="card card-white">
            <div className="card-body">
                {refreshAppliedProfiles > 2 &&
                    <AppliedProfilesGridComponent
                        key={refreshAppliedProfiles}
                        appliedProfilesList={appliedProfilesList}
                        requirementId={props.requirementId}
                        totalSize={appliedProfilesPagedList?.totalCount ? appliedProfilesPagedList?.totalCount : appliedProfilesList.length}
                        currentPage={page + 1}
                        pagesize={pageSize}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                        pageSizesList={pageSizesList}
                        onShortlistProfile={onShortlistProfileHandler}
                        onRatingDetails={onRatingDetailsHandler}
                        onPageChangeAction={onPageChangeActionHandler}
                        onPageSizeChangeAction={onPageSizeChangeActionHandler} />
                }
            </div>
        </div>
    );
};
export default AppliedProfilesComponent;
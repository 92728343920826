import React, { useState } from "react";
import { useAppSelector } from "../../../context-store";
import VendorRequestModel from "../../../models/company/vendor/request/VendorRequestModel";
import { PageModeEnum } from "../../../models/enums/PageModeEnum";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import copyClickboardUtils from "../../../utils/copy-clipboard";

interface VendorGridProps {
    vendorList: VendorRequestModel[];
    totalSize: number;
    currentPage: number;
    pagesize: number;
    sortColumn: string;
    sortDirection: "asc" | "desc";
    pageSizesList: number[];
    vendorNameFilterValue: string;
    onVendorAction: (row: VendorRequestModel, actionType: string) => void;
    onPageChangeAction: (newPage: number) => void;
    onPageSizeChangeAction: (newPageSize: number) => void;
    onSortAction: (newSortColumn: string, sortDir: string) => void;
    onFilterAction: (filterColumn: string, filterValue: string) => void;
};

const VendorGridComponent = (props: VendorGridProps) => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [vendorNameFilter, setVendorNameFilter] = useState<string>(props.vendorNameFilterValue);

    const editVendor = (row: VendorRequestModel) => {
        if (loggedInUser !== null) {
            let vendorRequestModel: VendorRequestModel = {
                vendorId: row.vendorId,
                userId: row.userId,
                companyId: loggedInUser?.profileId,
                vendorName: row.vendorName,
                vendorEmailId: row.vendorEmailId,
                vendorUserName: row.vendorUserName,
                vendorPhoneNumber: row.vendorPhoneNumber,
                startDate: row.startDate,
                endDate: row.endDate
            }
            props.onVendorAction(vendorRequestModel, PageModeEnum.Edit);
        }
    };

    const copyClipboard = (message: string) => {
        copyClickboardUtils.copy(message);
    };

    const columnsVendors = [
        { Key: "VendorName", text: "Vendor Name", sort: true, searchable: true },
        { Key: "VendorEmailId", text: "Vendor Email", sort: true, searchable: false },
        { Key: "VendorPhoneNumber", text: "Vendor Phone No", sort: true, searchable: false },
        { Key: "StartDate", text: "Start Date", sort: false, searchable: false },
        { Key: "EndDate", text: "End Date", sort: false, searchable: false }
    ];

    const createSortHandler = (property: string) => (event: React.MouseEvent) => {
        props.onSortAction(property, props.sortDirection === "asc" ? "desc" : "asc")
    };

    const handleChangePage = (event: any, newPage: number) => {
        props.onPageChangeAction(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        props.onPageSizeChangeAction(parseInt(event.target.value, 10));
    };

    const filterOnChange = (key: string, value: string) => {
        setVendorNameFilter(value);
        if ((value && value.length > 2) || value === "") {
            props.onFilterAction(key, value);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columnsVendors.map((column: any) => (
                                    <TableCell key={column.key} className="table-header-cell">
                                        {column.sort &&
                                            <TableSortLabel
                                                active={props.sortColumn === column.key}
                                                direction={props.sortColumn === column.key ? props.sortDirection : "asc"}
                                                onClick={createSortHandler(column.key)}>
                                                {column.text}
                                            </TableSortLabel>
                                        }
                                        {column.searchable &&
                                            <input type="text" placeholder="search" className="mat-header-filter-input" value={vendorNameFilter}
                                                onClick={(e) => e.stopPropagation()} onChange={(e) => filterOnChange(column.key, e.target.value)}></input>
                                        }
                                        {!column.sort &&
                                            <span>{column.text}</span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.vendorList.map((vendor: VendorRequestModel, index) => (
                                <TableRow>
                                    <TableCell>{vendor.vendorName}</TableCell>
                                    <TableCell>{vendor.vendorEmailId}</TableCell>
                                    <TableCell>
                                        <span>{vendor.vendorPhoneNumber}</span><a title="copy" onClick={() => { copyClipboard(vendor.vendorPhoneNumber!.toString()); }}><i className="fa fa-clipboard"></i></a>
                                    </TableCell>
                                    <TableCell>{vendor.startDate}</TableCell>
                                    <TableCell>{vendor.endDate}</TableCell>
                                    {/* <TableCell>
                                        <a onClick={() => { editVendor(vendor); }} title="Edit vendor">
                                            <i className="far fa-edit grid-icon blue"></i>
                                        </a>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={props.pageSizesList}
                    component="div"
                    count={props.totalSize}
                    rowsPerPage={props.pagesize}
                    page={props.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
            </div>
        </div>
    );
};
export default VendorGridComponent;
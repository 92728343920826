import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import IdNameModel from "../../models/common/IdNameModel";
import BaseResponse from "../../models/common/BaseResponse";
import BaseResponseList from "../../models/common/BaseResponseList";
import RegisteredCompaniesSeachInputModel from "../../models/admin/company/request/RegisteredCompaniesSeachInputModel";
import PagedListModel from "../../models/common/PagedListModel";
import RegisteredCompaniesModel from "../../models/admin/company/response/RegisteredCompaniesModel";
import RegisteredCompaniesRequestModel from "../../models/admin/company/request/RegisteredCompaniesRequestModel";
import CompanyProfilesDashboardSeachInputModel from "../../models/admin/company/request/CompanyProfilesDashboardSeachInputModel";
import CompanyProfilesDashboardModel from "../../models/admin/company/response/CompanyProfilesDashboardModel";
import ReleaseCandidatesSeachInputModel from "../../models/admin/company/request/ReleaseCandidatesSeachInputModel";
import ReleaseCandidatesModel from "../../models/admin/company/response/ReleaseCandidatesModel";
import ReleaseCandidatesRequestModel from "../../models/admin/company/request/ReleaseCandidatesRequestModel";

class AdminCompanyService {
    async getRegisteredCompanies(inputParms: RegisteredCompaniesSeachInputModel) {
        let res: PagedListModel<RegisteredCompaniesModel> = await httpClient.post<PagedListModel<RegisteredCompaniesModel>>(AppConfig.admin.company.getRegisteredCompanies, inputParms);
        return res;
    };

    async getCompanyConfigurationById(companyId: string) {
        let res: BaseResponse<RegisteredCompaniesRequestModel> = await httpClient.get<BaseResponse<RegisteredCompaniesRequestModel>>(AppConfig.admin.company.getCompanyConfigurationById + "/" + companyId);
        return res;
    };

    async updateCompanyCofiguration(inputParms: RegisteredCompaniesRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.company.updateCompanyCofiguration, inputParms);
        return res;
    };

    async getCompanyProfilesDashboard(inputParms: CompanyProfilesDashboardSeachInputModel) {
        let res: BaseResponseList<CompanyProfilesDashboardModel[]> = await httpClient.post<BaseResponseList<CompanyProfilesDashboardModel[]>>(AppConfig.admin.company.getCompanyProfilesDashboard, inputParms);
        return res;
    };

    async getCandidatesToBeReleased(inputParms: ReleaseCandidatesSeachInputModel) {
        let res: PagedListModel<ReleaseCandidatesModel> = await httpClient.post<PagedListModel<ReleaseCandidatesModel>>(AppConfig.admin.company.getCandidatesToBeReleased, inputParms);
        return res;
    };

    async releaseCandidate(inputParms: ReleaseCandidatesRequestModel) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.company.releaseCandidate, inputParms);
        return res;
    };

    async getCompaniesForIAIRecruiter(adminId: string) {
        let res: BaseResponseList<IdNameModel[]> = await httpClient.get<BaseResponseList<IdNameModel[]>>(AppConfig.admin.company.getCompaniesForIAIRecruiter + "/" + adminId);
        return res;
    };

    async getRecruiterByCompany(companyId: string) {
        let res: BaseResponseList<IdNameModel[]> = await httpClient.get<BaseResponseList<IdNameModel[]>>(AppConfig.admin.company.getRecruiterByCompany + "/" + companyId);
        return res;
    };

    async getJobCodesForRecruiter(recruiterId: string) {
        let res: BaseResponseList<IdNameModel[]> = await httpClient.get<BaseResponseList<IdNameModel[]>>(AppConfig.admin.company.getJobCodesForRecruiter + "/" + recruiterId);
        return res;
    };
}
const adminCompanyService = new AdminCompanyService();
export default adminCompanyService;
import { useForm } from "react-hook-form";
import VendorSubUserRequestModel from "../../../models/company/vendorUser/request/VendorSubUserRequestModel";
import { OBJECT_NAME_RULE, EMAIL_RULE, MOBILE_NUMBER_RULE } from "../../../utils/validation-utils";

interface VendorSubUserProps {
    objVendorSubUser: VendorSubUserRequestModel;
    actionType: string;
    onCloseVendorSubUser: () => void;
    onVendorSubUserSave: (objVendorSubUser: VendorSubUserRequestModel) => void;
}

const VendorSubUserCreateComponent = (props: VendorSubUserProps) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<VendorSubUserRequestModel>({
        mode: "onChange",
        defaultValues: props.objVendorSubUser
    });

    const onSubmit = (formData: VendorSubUserRequestModel) => {
        formData.vendorUserName = formData.vendorEmailId;
        props.onVendorSubUserSave(formData);
    }

    return (
        <div className="card card-white">
            <div className="card-body">
                <form className="contact quform" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Name: <span className="required">*</span> </label>
                                    <input type="text" className="form-control" {...register("vendorName", OBJECT_NAME_RULE)} placeholder="Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.vendorName && errors.vendorName.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Email: <span className="required">*</span> </label>
                                    <input type="email" className="form-control" {...register("vendorEmailId", EMAIL_RULE)} placeholder="Email/User Name" autoComplete="off" />
                                    <span className="error">
                                        {errors.vendorEmailId && errors.vendorEmailId.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="quform-element form-group">
                                <div className="quform-input">
                                    <label className="form-label">Mobile Number: <span className="required">*</span> </label>
                                    <input type="tel" className="form-control" {...register("vendorPhoneNumber", MOBILE_NUMBER_RULE)} placeholder="Mobile Number" autoComplete="off" />
                                    <span className="error">
                                        {errors.vendorPhoneNumber && errors.vendorPhoneNumber.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <button className="btn btn-success btn-form" type="submit"><span>Save</span></button>
                            <button className="btn btn-danger btn-form" type="button" onClick={() => props.onCloseVendorSubUser()}>Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default VendorSubUserCreateComponent;
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import ScheduleInterviewComponent from "../../components/candidate/interview/ScheduleInterviewComponent";
import IdNameModel from "../../models/common/IdNameModel";
import masterDataService from "../../services/master-data-service";
import alertUtils from "../../utils/toaster-utils";
import modalUtils from "../../utils/modal-utils";
import loaderUtils from "../../utils/loader-utils";

const ScheduleInterview = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [primarySkillList, setPrimarySkillList] = useState<IdNameModel[]>([]);
    const [refreshSkills, setRefreshSkills] = useState<number>(2);
    const [timeSlots, setTimeSlots] = useState<IdNameModel[]>([]);

    useEffect(() => {
        fillPrimarySkills();
        getTimeSlots();
    }, []);

    const fillPrimarySkills = () => {
        setRefreshSkills(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadPrimarySkills().then((res) => {
            modalUtils.closeModal();
            setPrimarySkillList(res.items);
            setRefreshSkills(refreshSkills * 5);            
        }).catch((error) => {
            setRefreshSkills(refreshSkills * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);            
        });
    };

    const getTimeSlots = () => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        masterDataService.loadTimeSlots().then((res) => {
            modalUtils.closeModal();
            if (res.isSuccess) {
                setTimeSlots(res.items);
            } else {
                alertUtils.showError("Unable to load Primary skills");
            }            
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError("Erorr fetching Primary skills");            
        });
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> SCHEDULE MY INTERVIEW</h4>
            </div>
            {refreshSkills > 2 && loggedInUser &&
                <ScheduleInterviewComponent 
                primarySkillsList={primarySkillList}
                timeSlotsList={timeSlots}
                candidateId={loggedInUser.profileId}  />
            }
        </Layout>
    );
};
export default ScheduleInterview;
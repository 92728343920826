import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../context-store";
import CompanyClasses from "../../../landing-pages/Company/company.module.css";
import companyDashboardService from "../../../services/company/dashboard-service";
import JobPostingSearchInputModel from "../../../models/company/jobPosting/request/JobPostingSearchInputModel";
import { SortDirection } from "../../../models/enums/SortDirectionEnum";
import PagedListModel from "../../../models/common/PagedListModel";
import modalUtils from "../../../utils/modal-utils";
import alertUtils from "../../../utils/toaster-utils";
import CompanyDashboardModel from "../../../models/company/dashboard/response/CompanyDashboardModel";
import IdNameModel from "../../../models/common/IdNameModel";
import masterDataService from "../../../services/master-data-service";
import { ScreenEnum } from "../../../models/enums/StatusEnum";
import JobPostingDashboardGridComponent from "./JobPostingDashboardGridComponent";
import JobPostingStatusUpdateModel from "../../../models/company/jobPosting/request/JobPostingStatusUpdateModel";
import { GeneralPoupConfig, ModalSize } from "../../../models/modal-types";
import JobPostingStatusUpdatePopUp from "./JobPostingStatusUpdateComponent";
import jobPostingService from "../../../services/company/job-posting-service";
import { Accordion, Card, Button } from "react-bootstrap";
import loaderUtils from "../../../utils/loader-utils";
import { PagingEnum } from "../../../models/enums/IAIEnum";

const JobPostingDashboardComponent = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [jobPostingPagedList, setJobPostingPagedList] = useState<PagedListModel<CompanyDashboardModel>>();
    const [jobPostingList, setJobPostingList] = useState<CompanyDashboardModel[]>([]);
    const [primarySkills, setPrimarySkills] = useState<IdNameModel[]>([]);
    const [status, setStatus] = useState<IdNameModel[]>([]);
    const [refreshJobPosting, setRefreshJobPosting] = useState<number>(2);
    const [primarySkillSearch, setPrimarySkillSearch] = useState<number>(0);
    const [jobCodeSearch, setJobCodeSearch] = useState<string>("");
    const [statusSearch, setStatusSearch] = useState<number>(0);
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("JobCode");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        fillPrimarySkills();
        fillStatus();
        getJobPostings(getSearchFilters(page, pageSize, sortColumn, sortDirection));
    }, []);

    const fillPrimarySkills = () => {
        masterDataService.loadPrimarySkills().then((res) => {
            setPrimarySkills(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillStatus = () => {
        masterDataService.loadStatusByScreen(ScreenEnum.JobPostingStatus).then((res) => {
            setStatus(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: JobPostingSearchInputModel = {
            userId: "",
            companyId: "",
            jobCode: jobCodeSearch,
            statusId: statusSearch === 0 ? null : statusSearch,
            primarySkillId: primarySkillSearch === 0 ? null : primarySkillSearch,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const getJobPostings = (inputData: JobPostingSearchInputModel) => {
        setRefreshJobPosting(2);
        if (loggedInUser?.profileId) {
            inputData.userId = loggedInUser?.profileId;
            inputData.companyId = loggedInUser?.profileId;
            modalUtils.showLoader(loaderUtils.getLoadDataText());
            companyDashboardService.getJobPostingsDashboard(inputData).then((res) => {
                modalUtils.closeModal();
                setJobPostingList([]);
                setJobPostingPagedList(res);
                setJobPostingList(res.items);
                setRefreshJobPosting(refreshJobPosting * 5);
            }).catch((error) => {
                modalUtils.closeModal();
                alertUtils.showError(error);
            });
        }
    };

    const onJobStatusChangeActionHandler = (jobPosting: JobPostingStatusUpdateModel) => {
        const config: GeneralPoupConfig = {
            title: "Add SubUser",
            content: (
                <JobPostingStatusUpdatePopUp
                    objStatusUpdate={jobPosting}
                    statusList={status}
                    onCloseStatusUpdate={onCloseStatusUpdateHandler}
                    onStatusUpdateSave={onStatusUpdateHandler}
                />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onCloseStatusUpdateHandler = () => {
        modalUtils.closeModal();
    };

    const onStatusUpdateHandler = (objJobPosting: JobPostingStatusUpdateModel) => {
        setRefreshJobPosting(2);
        modalUtils.showLoader(loaderUtils.getSaveDataText());
        jobPostingService.updateJobPostingStatus(objJobPosting).then((res) => {
            modalUtils.closeModal();
            if (res.data === false) {
                alertUtils.showError(res.errorMessages.toString());
            } else {
                alertUtils.showSuccess(res.informationMessages.toString());
                modalUtils.closeModal();
                getJobPostings(getSearchFilters(page, pageSize, sortColumn, sortDirection));
            }
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getJobPostings(getSearchFilters(newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getJobPostings(getSearchFilters(PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    return (
        <div id="main-wrapper">
            <Accordion className="filter-accordian">
                <Card className="card-border-bottom filter-card">
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Apply Filter
                            <i className="fa fa-angle-down"></i>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Primary Skill</label>
                                    <select className="mb-3 form-control form-select" onChange={(e) => setPrimarySkillSearch(parseInt(e.target.value))}>
                                        <option value="0">All</option>
                                        {primarySkills.map((status, index) => {
                                            return (
                                                <option key={index} value={status.id}> {status.name} </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label>Job Code</label>
                                    <select className="mb-3 form-control form-select" onChange={(e) => setJobCodeSearch(e.target.value)}>
                                        <option value="">All</option>
                                        {jobPostingList.map((status, index) => {
                                            return (
                                                <option key={index} value={status.jobCode}> {status.jobCode} </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label>Status</label>
                                    <select className="mb-3 form-control form-select" onChange={(e) => setStatusSearch(parseInt(e.target.value))}>
                                        <option value="0">All</option>
                                        {status.map((status, index) => {
                                            return (
                                                <option key={index} value={status.id}> {status.name} </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <button className="btn btn-warning" type="submit" onClick={() => getJobPostings(getSearchFilters(page, pageSize, sortColumn, sortDirection))}>
                                        <i className="icon-search">Search</i>
                                    </button>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            {refreshJobPosting > 2 && status.length > 0 &&
                <JobPostingDashboardGridComponent
                    key={refreshJobPosting}
                    jobPostingList={jobPostingList}
                    status={status}
                    totalSize={jobPostingPagedList?.totalCount ? jobPostingPagedList?.totalCount : jobPostingList.length}
                    currentPage={page + 1}
                    pagesize={pageSize}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                    pageSizesList={pageSizesList}
                    onJobStatusChangeAction={onJobStatusChangeActionHandler}
                    onPageChangeAction={onPageChangeActionHandler}
                    onPageSizeChangeAction={onPageSizeChangeActionHandler} />
            }
        </div>
    );
};
export default JobPostingDashboardComponent;
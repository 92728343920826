import React, { useState, useEffect } from "react";
import adminClasses from "../../../landing-pages/admin/admin.module.css";
import IdNameModel from "../../../../models/common/IdNameModel";
import modalUtils from "../../../../utils/modal-utils";
import loaderUtils from "../../../../utils/loader-utils";
import alertUtils from "../../../../utils/toaster-utils";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateTimeUtils from "../../../../utils/date-utils";
import { PagingEnum } from "../../../../models/enums/IAIEnum";
import masterDataService from "../../../../services/master-data-service";
import RequirementEscalationsSearchInputModel from "../../../../models/company/escalation/request/RequirementEscalationsSearchInputModel";
import PagedListModel from "../../../../models/common/PagedListModel";
import RequirementEscalationsGridModel from "../../../../models/company/escalation/response/RequirementEscalationsGridModel";
import clientEscalationService from "../../../../services/company/client-escalation-service";
import { SortDirection } from "../../../../models/enums/SortDirectionEnum";
import RequirementEscalationsRequestModel from "../../../../models/company/escalation/request/RequirementEscalationsRequestModel";
import EscalationsGridComponent from "./EscalationsGridComponent";
import { GeneralPoupConfig, ModalSize } from "../../../../models/modal-types";
import EscalationsUpdateComponent from "./EscalationsUpdateComponent";

interface EscalationsProps {
    statusList: IdNameModel[];
};

const EscalationsComponent = (props: EscalationsProps) => {
    const [escalationsPagedList, setEscalationsPagedList] = useState<PagedListModel<RequirementEscalationsGridModel>>();
    const [escalationsList, setEscalationsList] = useState<RequirementEscalationsGridModel[]>([]);
    const [refreshEscalations, setRefreshEscalations] = useState<number>(2);
    const [startDateSearch, setStartDateSearch] = useState(dateTimeUtils.addDays(new Date(), -7) as any);
    const [endDateSearch, setEndDateSearch] = useState(new Date() as any);
    const [jobcodeFilter, setJobcodeFilter] = useState<string>("");
    const [page, setPage] = useState<number>(PagingEnum.page);
    const [pageSize, setPageSize] = useState<number>(PagingEnum.pageSize);
    const [sortColumn, setSortColumn] = useState<string>("EscalationDate");
    const [sortDirection, setSortDirection] = useState<number>(PagingEnum.sortDirection);
    const [pageSizesList, setPageSizesList] = useState<number[]>([]);

    useEffect(() => {
        loadPageSizes();
        getEscalations(getSearchFilters(jobcodeFilter, page, pageSize, sortColumn, sortDirection));
    }, []);

    const loadPageSizes = () => {
        masterDataService.loadPageSizesForPaging().then((res) => {
            setPageSizesList(res);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const getSearchFilters = (jobcodeFilter: string, page: number, pageSize: number, sortColumn: string, sortDirection: number) => {
        var inputData: RequirementEscalationsSearchInputModel = {
            startDate: dateTimeUtils.getFormattedDate(startDateSearch),
            endDate: dateTimeUtils.getFormattedDate(endDateSearch),
            jobCode: jobcodeFilter,
            page: page,
            pageSize: pageSize,
            sort: sortColumn,
            sortDir: sortDirection
        };
        return inputData;
    };

    const startDateHanler = (date: any) => {
        setStartDateSearch(date);
    };

    const endDateHanler = (date: any) => {
        setEndDateSearch(date);
    };

    const getEscalations = (inputData: RequirementEscalationsSearchInputModel) => {
        setRefreshEscalations(2);
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        clientEscalationService.getEscalations(inputData).then((res) => {
            modalUtils.closeModal();
            setEscalationsList([]);
            setEscalationsPagedList(res);
            setEscalationsList(res.items);
            setRefreshEscalations(refreshEscalations * 5);
        }).catch((error) => {
            setRefreshEscalations(refreshEscalations * 5);
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onEscalationAddActionHandler = (objEscalation: RequirementEscalationsRequestModel) => {
        const config: GeneralPoupConfig = {
            title: "Add User",
            content: (
                <EscalationsUpdateComponent
                    objEscalation={objEscalation}
                    statusList={props.statusList}
                    onEscalationSaveAction={onEscalationSaveActionHandler}
                    onEscalationCloseAction={onEscalationCloseActionHandler} />
            ),
            size: ModalSize.LG,
            className: "model-min-height-500"
        };
        modalUtils.showPopup(config);
    };

    const onEscalationCloseActionHandler = () => {
        modalUtils.closeModal();
    };

    const onEscalationSaveActionHandler = (objEscalation: RequirementEscalationsRequestModel) => {
        modalUtils.showLoader(loaderUtils.getLoadDataText());
        clientEscalationService.saveEscalation(objEscalation).then((res) => {
            modalUtils.closeModal();
            getEscalations(getSearchFilters(jobcodeFilter, page, pageSize, sortColumn, sortDirection));
        }).catch((error) => {
            modalUtils.closeModal();
            alertUtils.showError(error);
        });
    };

    const onPageChangeActionHandler = (newPage: number) => {
        setPage(newPage);
        getEscalations(getSearchFilters(jobcodeFilter, newPage, pageSize, sortColumn, sortDirection));
    };

    const onPageSizeChangeActionHandler = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(PagingEnum.page);
        getEscalations(getSearchFilters(jobcodeFilter, PagingEnum.page, newPageSize, sortColumn, sortDirection));
    };

    const onSortActionHandler = (newSortColumn: string, sortDir: string) => {
        setSortColumn(newSortColumn);
        setSortDirection(sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc);
        getEscalations(getSearchFilters(jobcodeFilter, page, pageSize, newSortColumn, sortDir === "asc" ? SortDirection.Asc : SortDirection.Desc));
    };

    const onFilterActionHandler = (filterColumn: string, filterValue: string) => {
        setPage(0);
        setJobcodeFilter(filterValue);
        getEscalations(getSearchFilters(filterValue, 0, pageSize, sortColumn, sortDirection));
    };

    return (
        <div>
            <div className="card card-white">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <label>Start Date</label>
                            <DateTimePicker
                                selectsStart
                                onChange={startDateHanler}
                                selected={startDateSearch}
                                timeInputLabel="Time:"
                                dateFormat="yyyy-MMM-dd"
                                className="form-control"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                        <div className="col-md-3">
                            <label>End Date</label>
                            <DateTimePicker
                                selectsStart
                                onChange={endDateHanler}
                                selected={endDateSearch}
                                timeInputLabel="Time:"
                                dateFormat="yyyy-MMM-dd"
                                className="form-control"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-success" type="submit" onClick={() => getEscalations(getSearchFilters(jobcodeFilter, page, pageSize, sortColumn, sortDirection))}>
                                <i className="icon-search">Search</i>
                            </button>
                        </div>
                    </div>
                    {refreshEscalations > 2 &&
                        <EscalationsGridComponent
                            key={refreshEscalations}
                            escalationsList={escalationsList}
                            totalSize={escalationsPagedList?.totalCount ? escalationsPagedList?.totalCount : escalationsList.length}
                            currentPage={page + 1}
                            pagesize={pageSize}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection === SortDirection.Asc ? "asc" : "desc"}
                            pageSizesList={pageSizesList}
                            jobcodeFilterValue={jobcodeFilter}
                            disablePagingAndSorting={false}
                            onEscalationAddAction={onEscalationAddActionHandler}
                            onPageChangeAction={onPageChangeActionHandler}
                            onPageSizeChangeAction={onPageSizeChangeActionHandler}
                            onSortAction={onSortActionHandler}
                            onFilterAction={onFilterActionHandler} />
                    }
                </div>
            </div>
        </div>
    );
};
export default EscalationsComponent;
import AppConfig from "../../config";
import httpClient from "../../utils/http-client";
import PagedListModel from "../../models/common/PagedListModel";
import RegisteredInterviewerSearchInputModel from "../../models/admin/interviewer/request/RegisteredInterviewerSearchInputModel";
import RegisteredInterviewerModel from "../../models/admin/interviewer/response/RegisteredInterviewerModel";
import BaseResponse from "../../models/common/BaseResponse";

class AdminInterviewerService {
    async getRegisteredInterviewers(inputParms: RegisteredInterviewerSearchInputModel) {
        let res: PagedListModel<RegisteredInterviewerModel> = await httpClient.post<PagedListModel<RegisteredInterviewerModel>>(AppConfig.admin.interviewer.getRegisteredInterviewers, inputParms);
        return res;
    };

    async approveInterviewer(interviewerId: string) {
        let res: BaseResponse<boolean> = await httpClient.post<BaseResponse<boolean>>(AppConfig.admin.interviewer.approveInterviewer + "/" + interviewerId);
        return res;
    };
}
const adminInterviewerService = new AdminInterviewerService();
export default adminInterviewerService;
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../context-store";
import Layout from "../../components/layout/Layout";
import "../../assets/InnerCSS/plugins/bootstrap/css/bootstrap.min.css";
import "../../assets/InnerCSS/plugins/font-awesome/css/font-awesome.min.css";
import "../../assets/InnerCSS/plugins/icomoon/style.css";
import "../../assets/InnerCSS/plugins/uniform/css/default.css";
import "../../assets/InnerCSS/plugins/switchery/switchery.min.css";
import "../../assets/InnerCSS/plugins/nvd3/nv.d3.min.css";
import "../../assets/InnerCSS/css/styles.css";
import "../../assets/InnerCSS/css/custom.css";
import SubUserComponent from "../../components/company/sub-user/SubUserComponent";
import IdNameModel from "../../models/common/IdNameModel";
import { RoleEnum } from "../../models/enums/RoleEnum";
import { GuidEnum } from "../../models/enums/GuidEnum";
import departmentService from "../../services/company/department-service";
import alertUtils from "../../utils/toaster-utils";
import masterDataService from "../../services/master-data-service";
import subUserService from "../../services/company/sub-user-service";

const SubUser = () => {
    let loggedInUser = useAppSelector((state) => state.authState.loggedInUser);
    const [roles, setRoles] = useState<IdNameModel[]>([]);
    const [city, setCity] = useState<IdNameModel[]>([]);
    const [departments, setDepartments] = useState<IdNameModel[]>([]);
    const [clientLeads, setClientLeads] = useState<IdNameModel[]>([]);

    useEffect(() => {
        fillUserTypes();
        fillDepartments();
        fillCities(1);
        fillLeads();
    }, []);

    const fillUserTypes = () => {
        let roles: IdNameModel[] = [];
        let roleRecruiter: IdNameModel = {
            id: RoleEnum.ClientRecruiter,
            name: "Client Recruiter",
            guId: GuidEnum.Empty
        }
        roles.push(roleRecruiter);
        let rolePanel: IdNameModel = {
            id: RoleEnum.ClientLead,
            name: "Client Lead",
            guId: GuidEnum.Empty
        }
        roles.push(rolePanel);
        setRoles(roles);
    };

    const fillDepartments = () => {
        if (loggedInUser) {
            departmentService.fillDepartments(loggedInUser.profileId).then((res) => {
                setDepartments(res.items);
            }).catch((error) => {
                alertUtils.showError(error);
            });
        }
    };

    const fillCities = (countryId: number) => {
        masterDataService.loadCities(countryId).then((res) => {
            setCity(res.items);
        }).catch((error) => {
            alertUtils.showError(error);
        });
    };

    const fillLeads = () => {
        if (loggedInUser) {
            subUserService.fillSubUsers(loggedInUser.profileId, RoleEnum.ClientLead).then((res) => {
                setClientLeads(res.items);
            }).catch((error) => {
                alertUtils.showError(error);
            });
        }
    };

    return (
        <Layout>
            <div className="page-title">
                <h4 className="breadcrumb-header"> SUBUSER </h4>
            </div>
            {roles.length > 0 && departments.length > 0 && city.length > 0 &&
                <SubUserComponent
                    roles={roles}
                    departments={departments}
                    cities={city}
                    clientLeads={clientLeads} />
            }
        </Layout>
    );
};
export default SubUser;
import RouteConfig from "./models/RouteConfig";
import SignIn from "./landing-pages/SignIn";
import Index from "./landing-pages/Index";
import Register from "./landing-pages/Register";
import VerifyAccount from "./landing-pages/VerifyAccount";
import ChangePassword from "./landing-pages/ChangePassword";
import Home from "./landing-pages/home/Home";
import InterviewerDashboard from "./landing-pages/Interviewer/InterviewerDashboard";
import InterviewerProfile from "./landing-pages/Interviewer/InterviewerProfile";
import InterviewerSchedule from "./landing-pages/Interviewer/InterviewerSchedule";
import ReferFriend from "./landing-pages/Interviewer/ReferFriend";
import PickUpSchedule from "./landing-pages/Interviewer/PickUpSchedule";
import InterviewerPersonalInfo from "./landing-pages/Interviewer/InterviewerPersonalInfo";
import CandidateDashboard from "./landing-pages/candidate/CandidateDashboard";
import CandidateProfile from "./landing-pages/candidate/CandidateProfile";
import ScheduleInterview from "./landing-pages/candidate/ScheduleInterview";
import RateInterview from "./landing-pages/Interviewer/RateInterview";
import CandidateJobs from "./landing-pages/candidate/CandidateJobs";
import CandidateAccess from "./landing-pages/candidate/CandidateAccess";
import CompanyDashboard from "./landing-pages/company/CompanyDashboard";
import Department from "./landing-pages/company/Department";
import Designation from "./landing-pages/company/Designation";
import SubUser from "./landing-pages/company/SubUser";
import Vendor from "./landing-pages/company/Vendor";
import VendorSubUser from "./landing-pages/company/VendorSubUser";
import VendorDashboard from "./landing-pages/company/VendorDashboard";
import VendorSubUserDashboard from "./landing-pages/company/VendorSubUserDashboard";
import ClientRecruiterDashboard from "./landing-pages/company/ClientRecruiterDashboard";
import JobPosting from "./landing-pages/company/JobPosting";
import JobPostingInterviewProcess from "./landing-pages/company/JobPostingInterviewProcess";
import AddProfiles from "./landing-pages/company/AddProfiles";
import RelatedProfiles from "./landing-pages/company/RelatedProfiles";
import ShortlistedProfiles from "./landing-pages/company/ShortlistedProfiles";
import AppliedCandidates from "./landing-pages/company/AppliedCandidates";
import AdminDashboard from "./landing-pages/admin/AdminDashboard";
import AdminUser from "./landing-pages/admin/AdminUser";
import PrimarySkill from "./landing-pages/admin/skill/PrimarySkill";
import SecondarySkill from "./landing-pages/admin/skill/SecondarySkill";
import EditRating from "./landing-pages/admin/interviews/EditRating";
import Interviews from "./landing-pages/admin/interviews/Interviews";
import ByPassPaymentGateway from "./landing-pages/admin/candidate/ByPassPaymentGateway";
import CandidatePassword from "./landing-pages/admin/candidate/CandidatePassword";
import RegisteredCandidates from "./landing-pages/admin/candidate/RegisteredCandidates";
import InterviewerSchedules from "./landing-pages/admin/interviewer/InterviewerSchedules";
import RegisteredInterviewers from "./landing-pages/admin/interviewer/RegisteredInterviewers";
import AdminAddProfiles from "./landing-pages/admin/company/AdminAddProfiles";
import AdminAllProfiles from "./landing-pages/admin/company/AdminAllProfiles";
import AdminPostRequirement from "./landing-pages/admin/company/AdminPostRequirement";
import AdminViewRating from "./landing-pages/admin/company/AdminViewRating";
import CompanyOnBoard from "./landing-pages/admin/company/CompanyOnBoard";
import CompanyProfilesDashboard from "./landing-pages/admin/company/CompanyProfilesDashboard";
import ReleaseCandidates from "./landing-pages/admin/company/ReleaseCandidates";
import TodaysFollowups from "./landing-pages/admin/candidate/TodaysFollowups";
import AllProfiles from "./landing-pages/company/AllProfiles";
import Escalations from "./landing-pages/admin/company/Escalations";

const routes: RouteConfig[] = [
    {
        path: "/",
        component: Home,
        exact: true,
    },
    {
        path: "/index",
        component: Index,
        protected: false,
    },
    {
        path: "/login",
        component: SignIn,
        protected: false,
    },
    {
        path: "/register/:type",
        component: Register,
        protected: false,
    },
    {
        path: "/verifyaccount/:verificationCode",
        component: VerifyAccount,
        protected: false,
    },
    {
        path: "/changepassword",
        component: ChangePassword
    },
    {
        path: "/interviewer-dashboard",
        component: InterviewerDashboard
    },
    {
        path: "/interviewer-profile",
        component: InterviewerProfile
    },
    {
        path: "/interviewer-personal-info",
        component: InterviewerPersonalInfo
    },
    {
        path: "/interviewer-schedule",
        component: InterviewerSchedule
    },
    {
        path: "/refer-friend",
        component: ReferFriend
    },
    {
        path: "/pickup-schedule",
        component: PickUpSchedule
    },
    {
        path: "/candidate-dashboard",
        component: CandidateDashboard
    },
    {
        path: "/candidate-profile",
        component: CandidateProfile
    },
    {
        path: "/schedule-interview",
        component: ScheduleInterview
    },
    {
        path: "/rate-interview/:interviewId",
        component: RateInterview
    },
    {
        path: "/candidate-jobs",
        component: CandidateJobs
    },
    {
        path: "/candidate-access",
        component: CandidateAccess
    },
    {
        path: "/company-dashboard",
        component: CompanyDashboard
    },
    {
        path: "/department",
        component: Department
    },
    {
        path: "/designation",
        component: Designation
    },
    {
        path: "/sub-user",
        component: SubUser
    },
    {
        path: "/vendor",
        component: Vendor
    },
    {
        path: "/vendor-sub-user",
        component: VendorSubUser
    },
    {
        path: "/recruiter-dashboard",
        component: ClientRecruiterDashboard
    },
    {
        path: "/vendor-dashboard",
        component: VendorDashboard
    },
    {
        path: "/vendor-recruiter-dashboard",
        component: VendorSubUserDashboard
    },
    {
        path: "/job-posting",
        component: JobPosting
    },
    {
        path: "/interview-process/:requirementId",
        component: JobPostingInterviewProcess
    },
    {
        path: "/add-profiles/:requirementId",
        component: AddProfiles
    },
    {
        path: "/related-profiles/:requirementId",
        component: RelatedProfiles
    },
    {
        path: "/shortlisted-profiles/:requirementId",
        component: ShortlistedProfiles
    },
    {
        path: "/applied-candidates/:requirementId",
        component: AppliedCandidates
    },
    {
        path: "/admin-dashboard",
        component: AdminDashboard
    },
    {
        path: "/admin-user",
        component: AdminUser
    },
    {
        path: "/primary-skill",
        component: PrimarySkill
    },
    {
        path: "/secondary-skill",
        component: SecondarySkill
    },
    {
        path: "/edit-rating",
        component: EditRating
    },
    {
        path: "/interviews",
        component: Interviews
    },
    {
        path: "/by-pass-payment-gateway",
        component: ByPassPaymentGateway
    },
    {
        path: "/candidate-password",
        component: CandidatePassword
    },
    {
        path: "/registered-candidates",
        component: RegisteredCandidates
    },
    {
        path: "/interviewer-schedules",
        component: InterviewerSchedules
    },
    {
        path: "/registered-interviewers",
        component: RegisteredInterviewers
    },
    {
        path: "/admin-add-profiles",
        component: AdminAddProfiles
    },
    {
        path: "/admin-all-profiles",
        component: AdminAllProfiles
    },
    {
        path: "/admin=post-requirement",
        component: AdminPostRequirement
    },
    {
        path: "/admin-view-rating",
        component: AdminViewRating
    },
    {
        path: "/company-on-board",
        component: CompanyOnBoard
    },
    {
        path: "/company-profiles-dashboard",
        component: CompanyProfilesDashboard
    },
    {
        path: "/release-candidates",
        component: ReleaseCandidates
    },
    {
        path: "/todays-follow-ups",
        component: TodaysFollowups
    },
    {
        path: "/all-profiles",
        component: AllProfiles
    },
    {
        path: "/client-escalations",
        component: Escalations
    }
];
export default routes;